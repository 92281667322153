import { IconButton, Menu, MenuButton } from '@chakra-ui/react';
import React from 'react';
import {
  MessageContextValue,
  isUserMuted,
  useChatContext,
  useMessageContext,
} from 'stream-chat-react';
import {
  DefaultStreamChatGenerics,
  IconProps,
} from 'stream-chat-react/dist/types/types';

import { MoreHorizontalIcon } from '@/imports/ui/chakra/feather';

import { TradewingMessageActionsBox } from './TradewingMessageActionsBox';

type MessageContextPropsToPick =
  | 'getMessageActions'
  | 'handleDelete'
  | 'handleFlag'
  | 'handleMute'
  | 'handlePin'
  | 'message';

export type MessageActionsProps<
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
> = Partial<
  Pick<MessageContextValue<StreamChatGenerics>, MessageContextPropsToPick>
> & {
  /* Custom component rendering the icon used in message actions button. This button invokes the message actions menu. */
  ActionsIcon?: React.ComponentType<IconProps>;
  /* Custom CSS class to be added to the `div` wrapping the component */
  customWrapperClass?: string;
  /* If true, renders the wrapper component as a `span`, not a `div` */
  inline?: boolean;
  /* React mutable ref that can be placed on the message root `div` of MessageActions component */
  messageWrapperRef?: React.RefObject<HTMLDivElement>;
  /* Function that returns whether the message was sent by the connected user */
  mine?: () => boolean;
};

export const TradewingMessageActions = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(
  props: MessageActionsProps<StreamChatGenerics>
) => {
  const {
    getMessageActions: propGetMessageActions,
    handleDelete: propHandleDelete,
    handleFlag: propHandleFlag,
    handleMute: propHandleMute,
    handlePin: propHandlePin,
    message: propMessage,
    mine,
  } = props;

  const { mutes } = useChatContext<StreamChatGenerics>('MessageActions');
  const {
    customMessageActions,
    getMessageActions: contextGetMessageActions,
    handleDelete: contextHandleDelete,
    handleFlag: contextHandleFlag,
    handleMute: contextHandleMute,
    handlePin: contextHandlePin,
    isMyMessage,
    message: contextMessage,
    setEditingState,
  } = useMessageContext<StreamChatGenerics>('MessageActions');

  const getMessageActions = propGetMessageActions || contextGetMessageActions;
  const handleDelete = propHandleDelete || contextHandleDelete;
  const handleFlag = propHandleFlag || contextHandleFlag;
  const handleMute = propHandleMute || contextHandleMute;
  const handlePin = propHandlePin || contextHandlePin;
  const message = propMessage || contextMessage;

  const isMuted = React.useCallback(
    () => isUserMuted(message, mutes),
    [message, mutes]
  );

  const messageActions = getMessageActions();

  if (!messageActions.length && !customMessageActions) return null;

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            aria-label="Open Message Actions Menu"
            as={IconButton}
            size="xs"
            colorScheme="gray"
            variant="ghost"
            icon={<MoreHorizontalIcon />}
          />
          <TradewingMessageActionsBox
            getMessageActions={getMessageActions}
            handleDelete={handleDelete}
            handleEdit={setEditingState}
            handleFlag={handleFlag}
            handleMute={handleMute}
            handlePin={handlePin}
            isUserMuted={isMuted}
            mine={mine ? mine() : isMyMessage()}
            open={isOpen}
          />
        </>
      )}
    </Menu>
  );
};
