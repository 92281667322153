import { Box } from '@chakra-ui/react';
import * as React from 'react';

import { useVideoPreview } from '@/hooks/useVideoPreview';

export const EmbeddedVideo: React.FC<{ src: string }> = ({ src }) => {
  const previewData = useVideoPreview(src);

  if (!previewData || previewData.error_code || previewData.type !== 'video') {
    return null;
  }

  return (
    <Box position="relative">
      <Box position="relative" height="0" pb="56.25%" overflow="hidden">
        <div
          dangerouslySetInnerHTML={{
            __html: previewData.html,
          }}
        />
      </Box>
    </Box>
  );
};
