import { Box, HStack } from '@chakra-ui/react';
import * as React from 'react';

import { useActivationModalContext } from '../ActivationModalManager';

const StepProgress: React.FC = () => {
  const context = useActivationModalContext();

  if (!context?.onboardingFlowState?.currentFlowState) {
    return <></>;
  }

  const { validOnboardingFlowStates, currentStateIndex } =
    context.onboardingFlowState.currentFlowState;

  return (
    <HStack>
      {[...Array(validOnboardingFlowStates.length).keys()].map((step, idx) => (
        <Box
          key={idx}
          h={2}
          w={2}
          background={currentStateIndex >= step ? 'primary.500' : 'gray.200'}
          borderRadius="full"
        ></Box>
      ))}
    </HStack>
  );
};

export default StepProgress;
