import { accountsLink } from '@accounts/apollo-link';
import { AccountsClient } from '@accounts/client';
import { AccountsClientPassword } from '@accounts/client-password';
import GraphQLClient from '@accounts/graphql-client';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';

import { tokenStorage } from './tokenStorage';

let tenant_domain;

if (typeof window !== 'undefined') {
  tenant_domain = window.location.hostname;
}

const customFetch = (uri: string, options: any) => {
  const { operationName } = JSON.parse(options.body);
  return window.fetch(`/graphql2?opname=${operationName}`, options);
};

const link = createHttpLink({
  fetch: customFetch,
  headers: {
    tenant_domain,
  },
});

const graphqlClient = new GraphQLClient({
  graphQLClient: new ApolloClient({
    link,
    cache: new InMemoryCache(),
  }),
});

export const accountsClient = new AccountsClient(
  { tokenStorage },
  graphqlClient
);

export const accountsClientPassword = new AccountsClientPassword(
  accountsClient
);

export const authLink = accountsLink(() => accountsClient);
