import { Icon, IconProps } from '@chakra-ui/react';
import * as React from 'react';

export const AlertCircleIcon = React.forwardRef<SVGSVGElement, IconProps>(
  (props, ref) => (
    <Icon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      ref={ref}
      {...props}
    >
      <circle cx="12" cy="12" r="10"></circle>
      <line x1="12" y1="8" x2="12" y2="12"></line>
      <line x1="12" y1="16" x2="12.01" y2="16"></line>
    </Icon>
  )
);

AlertCircleIcon.displayName = 'AlertCircleIcon';
