import { Icon, IconProps } from '@chakra-ui/react';
import * as React from 'react';

export const UserIcon = React.forwardRef<SVGSVGElement, IconProps>(
  (props, ref) => (
    <Icon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
      ref={ref}
    >
      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
      <circle cx="12" cy="7" r="4"></circle>
    </Icon>
  )
);

UserIcon.displayName = 'UserIcon';
