import { ChannelListProps } from 'stream-chat-react';

export { MessagingAvatarGroup } from './components/MessagingAvatarGroup';
export { MessagingChannelHeader } from './components/MessagingChannelHeader';
export { MessagingChannelListHeader } from './components/MessagingChannelListHeader';
export { MessagingChannelPreview } from './components/MessagingChannelPreview';
export { MessagingChat } from './components/MessagingChat';
export { MessagingProvider, useMessages } from './components/MessagingProvider';
export { MessagingSidebar } from './components/MessagingSidebar';
export { MessagingThreadHeader } from './components/MessagingThreadHeader';
export { MessagingMessageListAvatar } from './components/MessagingMessageListAvatar';
export { TradewingMessageOptions } from './components/TradewingMessageOptions';
export { TotallyCustomReactionSelector } from './components/TotallyCustomReactionSelector';

export { useInitChatUsers } from './hooks/useInitChatUsers';

export const sx = {
  '.str-chat': {
    '--str-chat__channel-list-border-inline-end': 'none',
    '--str-chat__font-family': 'Source Sans Pro',
  },

  '.str-chat__channel-list': {
    px: 3,
    py: 0,
    height: 'auto',
  },

  '.str-chat__channel-list-messenger__main': {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },

  '.str-chat__message-input': {
    padding: 2,
  },

  '.str-chat__edit-message-form .str-chat__edit-message-form-options': {
    gap: 2,
  },

  '.str-chat__message-textarea-emoji-picker-container': {
    maxWidth: '100%',
    p: 2,
  },

  '.str-chat__channel .str-chat__container .str-chat__main-panel.str-chat__main-panel--hideOnThread':
    {
      display: 'none',
    },

  '.str-chat__message-actions-box:not(.str-chat__message-actions-box-angular).str-chat__message-actions-box--mine':
    {
      insetInlineEnd: '0',
      insetInlineStart: 'auto',
    },
};

export const getChannelListProps = (userId?: string): ChannelListProps => {
  return {
    filters: {
      type: 'messaging',
      members: { $in: [userId!] },
    },
    options: {
      state: true,
      watch: true,
      presence: true,
    },
    sort: {
      last_message_at: -1,
    },
  };
};
