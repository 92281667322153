import { DocumentNode, gql, useMutation } from '@apollo/client';
import { Box, Button, Stack, Text, Tooltip, useToast } from '@chakra-ui/react';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { BigCheckbox, BigCheckboxGroup } from '@/components/BigCheckbox';
import { ProfileImageIcon } from '@/components/groups/ProfileImageIcon';
import { logError } from '@/imports/logging/ClientLogger';

import { NewUserJoinGroups_AllGroupsFragment } from './graphql-types/NewUserJoinGroups_AllGroupsFragment';
import {
  NewUserJoinGroups_JoinGroups,
  NewUserJoinGroups_JoinGroupsVariables,
} from './graphql-types/NewUserJoinGroups_JoinGroups';
import { NewUserJoinGroups_JoinedGroupsFragment } from './graphql-types/NewUserJoinGroups_JoinedGroupsFragment';

import StepProgress from '../../../StepProgress';
import { ActivationModalBody } from '../ActivationModalBody';
import { ActivationModalFooter } from '../ActivationModalFooter';
import { ActivationModalHeader } from '../ActivationModalHeader';

const JOINED_GROUPS_FRAGMENT = gql`
  fragment NewUserJoinGroups_JoinedGroupsFragment on Group {
    _id
  }
`;

const ALL_GROUPS_FRAGMENT = gql`
  fragment NewUserJoinGroups_AllGroupsFragment on Group {
    _id
    name
    currentUserGroupPermission {
      canJoin
    }
    ...ProfileImageIcon_GroupFragment
  }
  ${ProfileImageIcon.groupFragment}
`;

const JOIN_GROUPS = gql`
  mutation NewUserJoinGroups_JoinGroups($input: JoinGroupsInput!) {
    joinGroups(input: $input) {
      user {
        _id
        onboardingFlowState {
          isSkippedOrCompleted
          currentFlowState {
            currentStateIndex
            validOnboardingFlowStates
          }
        }
        userOnboardingChecklistState {
          userHasJoinedAGroup
        }
        visibleGroupLinks {
          _id
          group {
            _id
            name
            profileImage {
              _id
              imageProxy(input: { w: 96, h: 96, fit: "fill", fill: "solid" })
            }
          }
        }
      }
    }
  }
`;

interface INewUserJoinGroupsProps {
  alreadyJoinedGroups: NewUserJoinGroups_JoinedGroupsFragment[];
  allGroups: NewUserJoinGroups_AllGroupsFragment[];
  isLastStep: boolean;
}

export const NewUserJoinGroups: React.FC<INewUserJoinGroupsProps> & {
  joinedGroupsFragment: DocumentNode;
  allGroupsFragment: DocumentNode;
} = (props) => {
  const [joinGroups, joinGroupsResult] = useMutation<
    NewUserJoinGroups_JoinGroups,
    NewUserJoinGroups_JoinGroupsVariables
  >(JOIN_GROUPS);

  const toast = useToast();

  const alreadyJoinedGroupIds = props.alreadyJoinedGroups.map(
    (group) => group._id
  );

  const [joinedGroupIds, setJoinedGroupids] = React.useState([
    ...alreadyJoinedGroupIds,
  ]);

  const { handleSubmit } = useForm();
  // send the joinGroups mutation
  const saveGroups = async (): Promise<void> => {
    try {
      await joinGroups({
        variables: {
          input: {
            data: {
              groupIds: joinedGroupIds,
            },
          },
        },
      });
    } catch (err) {
      toast({
        title: 'Join Groups failed',
        status: 'error',
      });
      logError(err);
    }
  };

  return (
    <Box
      as="form"
      display="flex"
      flexDirection="column"
      overflow="auto"
      flexGrow="1"
      noValidate
      onSubmit={handleSubmit(saveGroups)}
    >
      <ActivationModalHeader
        title="Now, join a few groups relevant to you!"
        subtitle="Groups help you access and participate in discussions relevant to your interests. It looks like you’ve been added to a few already."
      />
      <ActivationModalBody>
        <BigCheckboxGroup variant="spaced">
          {props.allGroups
            .filter((group) => group.currentUserGroupPermission?.canJoin)
            .map((group) => {
              const wasAlreadyJoined = alreadyJoinedGroupIds.includes(
                group._id
              );

              return (
                <BigCheckbox
                  key={group._id}
                  isChecked={joinedGroupIds.includes(group._id)}
                  isDisabled={wasAlreadyJoined}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setJoinedGroupids((previous) => [...previous, group._id]);
                    } else {
                      setJoinedGroupids((previous) =>
                        previous.filter((_id) => _id !== group._id)
                      );
                    }
                  }}
                >
                  <Tooltip
                    label="You were already added to this group"
                    isDisabled={!wasAlreadyJoined}
                  >
                    <Stack direction="row" alignItems="center" spacing="4">
                      <ProfileImageIcon group={group} />
                      <Text textStyle="bodyStrong">{group.name}</Text>
                    </Stack>
                  </Tooltip>
                </BigCheckbox>
              );
            })}
        </BigCheckboxGroup>
      </ActivationModalBody>
      <ActivationModalFooter>
        <StepProgress />
        <Button type="submit" isLoading={joinGroupsResult.loading}>
          {props.isLastStep ? 'Finish' : 'Next'}
        </Button>
      </ActivationModalFooter>
    </Box>
  );
};

NewUserJoinGroups.joinedGroupsFragment = JOINED_GROUPS_FRAGMENT;
NewUserJoinGroups.allGroupsFragment = ALL_GROUPS_FRAGMENT;
