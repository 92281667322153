import { createUploadLink } from 'apollo-upload-client';

const customFetch = (...args) => {
  const [_, options] = args;
  const { operationName } = JSON.parse(options.body);
  return window.fetch(`/graphql2?opname=${operationName}`, options);
};

const fileUploadLink = createUploadLink({
  fetch: customFetch,
});

export default fileUploadLink;
