import { DocumentNode, gql } from '@apollo/client';
import { Button, WrapItem } from '@chakra-ui/react';
import * as React from 'react';

import { TagsListItem_TagsFragment } from './graphql-types/TagsListItem_TagsFragment';

const TAGS_FRAGMENT = gql`
  fragment TagsListItem_TagsFragment on Tag {
    _id
    name
  }
`;

export const TagsListItem: React.FC<{
  selected: boolean;
  onChange: (isSelected: boolean) => void;
  tag: TagsListItem_TagsFragment;
}> & { tagsFragment: DocumentNode } = (props) => {
  const { tag, selected, onChange } = props;
  return (
    <WrapItem pt="1" position="relative">
      <Button
        {...(selected
          ? {
              bg: 'primary.500',
              color: 'white',
              _hover: { bg: 'primary.500', color: 'white' },
            }
          : {
              bg: 'gray.100',
              color: 'gray.600',
              _hover: { bg: 'gray.100', color: 'gray.600' },
            })}
        aria-label={selected ? `Following ${tag.name}` : `Follow ${tag.name}`}
        role="switch"
        aria-checked={selected}
        onClick={() => onChange(!selected)}
        paddingY={1}
        paddingX={2}
        height={8}
      >
        {tag.name}
      </Button>
    </WrapItem>
  );
};

TagsListItem.tagsFragment = TAGS_FRAGMENT;
