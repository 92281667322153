import { onError } from '@apollo/client/link/error';
import { datadogRum } from '@datadog/browser-rum';

import { logError } from '../../../../imports/logging/ClientLogger';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, path, ...rest }) => {
      logError(new Error(`GraphqlError at ${path}: ${message}`), {
        path,
        ...rest,
      });
    });
  if (networkError) {
    datadogRum.addError(networkError);
  }
});

export default errorLink;
