import { Divider, Image, Link, Text, chakra } from '@chakra-ui/react';
import { RawDraftContentState } from 'draft-js';
import NextLink from 'next/link';
import * as React from 'react';
// @ts-ignore
import redraft from 'redraft';

import { EmbeddedVideo } from '@/components/EmbeddedVideo';
import { RawDraftContentStateFragment } from '@/fragments/graphql-types/RawDraftContentStateFragment';

const renderers = (
  areLinksDisabled: boolean,
  getNextEntityKeySalt: () => string
) => {
  return {
    inline: {
      BOLD: (children: React.ReactNode, { key }: any) => (
        <strong key={key}>{children}</strong>
      ),
      ITALIC: (children: React.ReactNode, { key }: any) => (
        <em key={key}>{children}</em>
      ),
      UNDERLINE: (children: React.ReactNode, { key }: any) => (
        <u key={key}>{children}</u>
      ),
    },
    blocks: {
      unstyled: (children: React.ReactNode[], { keys, data }: any) =>
        children.map((child, index) => (
          <chakra.p
            key={keys[index]}
            textAlign={(data && data[index] && data[index].textAlign) || 'left'}
          >
            {child}
            <br />
          </chakra.p>
        )),
      'header-one': (children: React.ReactNode[], { keys, data }: any) =>
        children.map((child, index) => (
          <chakra.h1
            key={keys[index]}
            textAlign={(data && data[index] && data[index].textAlign) || 'left'}
          >
            {child}
            <br />
          </chakra.h1>
        )),
      'header-two': (children: React.ReactNode[], { keys, data }: any) =>
        children.map((child, index) => (
          <chakra.h2
            key={keys[index]}
            textAlign={(data && data[index] && data[index].textAlign) || 'left'}
          >
            {child}
            <br />
          </chakra.h2>
        )),
      'header-three': (children: React.ReactNode[], { keys, data }: any) =>
        children.map((child, index) => (
          <chakra.h3
            key={keys[index]}
            textAlign={(data && data[index] && data[index].textAlign) || 'left'}
          >
            {child}
            <br />
          </chakra.h3>
        )),
      blockquote: (children: React.ReactNode[], { keys }: any) =>
        children.map((child, index) => (
          <blockquote key={keys[index]}>
            {child}
            <br />
          </blockquote>
        )),
      'unordered-list-item': (children: React.ReactNode[], { keys }: any) => (
        <ul key={keys.join('|')}>
          {children.map((child, index) => (
            <li key={keys[index]}>{child}</li>
          ))}
        </ul>
      ),
      'ordered-list-item': (children: React.ReactNode[], { keys }: any) => (
        <ol key={keys.join('|')}>
          {children.map((child, index) => (
            <li key={keys[index]}>{child}</li>
          ))}
        </ol>
      ),
      atomic: (children: React.ReactNode[], { keys, data }: any) => {
        return children.map((child, index) => (
          <div key={keys[index]} {...(data ? data[index] : [])}>
            {child}
          </div>
        ));
      },
    },
    entities: {
      // key is the entity key value from raw
      LINK: (
        children: React.ReactNode,
        data: {
          url: string;
          targetOption: string;
          referrerPolicy: React.HTMLAttributeReferrerPolicy;
        },
        { key }: any
      ) => {
        return (
          <Link
            key={`${key}-${getNextEntityKeySalt()}`}
            href={data.url}
            target={data.targetOption}
            referrerPolicy={data.referrerPolicy}
          >
            {children}
          </Link>
        );
      },
      mention: (
        children: React.ReactNode,
        data: {
          mention: {
            userId: string;
            userCanView: boolean;
            user: { displayName: string } | null;
          };
        },
        { key }: any
      ) => {
        return areLinksDisabled ? (
          <Text fontWeight="semibold" key={key} as="span">
            {data.mention.user?.displayName || children}
          </Text>
        ) : (
          <Link
            as={NextLink}
            href={`/members/${data.mention.userId}`}
            key={`${key}-${getNextEntityKeySalt()}`}
            color={data.mention.userCanView ? undefined : 'gray.600'}
            fontWeight="semibold"
          >
            {data.mention.user?.displayName || children}
          </Link>
        );
      },
      IMAGE: (
        children: React.ReactNode,
        data: {
          src: string;
        },
        { key }: any
      ) => <Image key={key} src={data.src} alt="" />,
      'draft-js-video-plugin-video': (
        children: React.ReactNode,
        data: {
          src: string;
        }
      ) => <EmbeddedVideo src={data.src} />,
      divider: (children: React.ReactNode, data: {}, { key }: any) => (
        <Divider key={key} my="3" />
      ),
    },
  };
};

export const Redraft: React.FC<{
  rawDraftContentState?:
    | RawDraftContentState
    | RawDraftContentStateFragment
    | null;
  areLinksDisabled?: boolean;
}> = ({ rawDraftContentState, areLinksDisabled = false }) => {
  if (!rawDraftContentState) {
    return null;
  }

  // This is a pretty weird fix that addresses the fact that entities in content
  // blocks are passed the keys of the entity, but this could be duplicated if a
  // user copy and pastes an entity (a link or metion). Assuming that entities
  // are iterated through in a deterministic way, this provides a deterministic
  // way to get the "index" of the entity, that provides a stable key for the
  // React element that gets rendered.
  //
  // see https://github.com/lokiuz/redraft/issues/95 for more information
  let salt = 0;
  const getNextEntityKeySalt = () => `${salt++}`;

  return (
    <>
      {redraft(
        rawDraftContentState,
        renderers(areLinksDisabled, getNextEntityKeySalt)
      )}
    </>
  );
};
