import { Box, Stack, Text, chakra } from '@chakra-ui/react';
import dayjs from 'dayjs';
import * as React from 'react';
import {
  ChannelPreviewUIComponentProps,
  useChatContext,
} from 'stream-chat-react';

import { useChannelDetail } from '../hooks/useChannelDetail';
import { MessagingAvatarGroup } from './MessagingAvatarGroup';

const shortDate = (date: Date) => {
  const target = dayjs(date);
  const today = dayjs().startOf('day');

  if (target.isBefore(today)) {
    return target.format('MM/DD/YY');
  }

  return target.format('h:mm A');
};

export const MessagingChannelPreview: React.FC<
  ChannelPreviewUIComponentProps
> = (props) => {
  const ref = React.useRef<HTMLButtonElement>(null);
  const chatContext = useChatContext();
  const channelDetail = useChannelDetail(props.channel);

  const isActive = props.channel?.id === chatContext.channel?.id;

  React.useEffect(() => {
    if (isActive && ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [isActive]);

  if (channelDetail.isLoading) {
    return null;
  }

  return (
    <chakra.button
      aria-label={`select channel: ${channelDetail.name}`}
      role="option"
      aria-selected={isActive}
      ref={ref}
      textAlign="left"
      display="flex"
      flexDirection="row"
      gap="2"
      w="full"
      bg={isActive ? 'gray.100' : 'white'}
      borderRadius="xl"
      p="3"
      onClick={() => chatContext.setActiveChannel(props.channel)}
      alignItems="center"
      _hover={{
        bg: 'gray.200',
      }}
    >
      <Box aria-hidden>
        <MessagingAvatarGroup
          channel={props.channel}
          unread={props.unread}
          isLinkEnabled={false}
        />
      </Box>

      <Stack flex="1" spacing="0">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text textStyle="bodyStrong" noOfLines={1} wordBreak="break-all">
            {channelDetail.name}
          </Text>
          {props.channel.state.last_message_at && (
            <Text as="time" textStyle="detail" color="gray.600" flexShrink="0">
              {shortDate(props.channel.state.last_message_at)}
            </Text>
          )}
        </Stack>
        <Text
          textStyle="compact"
          color="gray.600"
          noOfLines={1}
          wordBreak="break-all"
        >
          {props.lastMessage?.text ?? 'Send a message'}
        </Text>
      </Stack>
    </chakra.button>
  );
};
