import {
  Button,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
} from '@chakra-ui/react';
import * as React from 'react';
import {
  ReactionSelectorProps,
  useEmojiContext,
  useMessageContext,
} from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';

// The original default selector is here: https://github.com/GetStream/stream-chat-react/blob/master/src/components/Reactions/ReactionSelector.tsx
// I used this roughly as a model to figure out how to make these totally custom ones work.
// In this case I'm just wrapping this thing in a popover to make sure it shows up correctly
// without being hidden underneath other elements or off the screen

const emojiSetDef = {
  imageUrl: '',
  sheetColumns: 2,
  sheetRows: 3,
  sheetSize: 64,
  spriteUrl: 'https://getstream.imgix.net/images/emoji-sprite.png',
};

export const TotallyCustomReactionSelector = React.forwardRef<
  HTMLDivElement,
  ReactionSelectorProps
>(() => {
  const { handleReaction, message } =
    useMessageContext<DefaultStreamChatGenerics>('ReactionSelector');

  const { Emoji, emojiConfig } = useEmojiContext('ReactionSelector');

  const { defaultMinimalEmojis, emojiData } = emojiConfig;

  const reactionOptions = defaultMinimalEmojis;

  const _emojiData = React.useMemo(
    () => ({ ...emojiData, emojis: {} }),
    [emojiData]
  );

  const ownReactions = message?.own_reactions || [];

  const iHaveReactedWithReaction = (reactionType: string) =>
    ownReactions.find((reaction) => reaction.type === reactionType);

  return (
    <Popover isOpen>
      <PopoverTrigger>
        <div></div>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverBody>
            <Stack direction="row" justifyContent="center">
              {reactionOptions.map((reactionOption) => {
                return (
                  <IconButton
                    key={reactionOption.id}
                    isRound
                    aria-label={`Select Reaction: ${reactionOption.name}`}
                    onClick={(event) => {
                      handleReaction(reactionOption.id, event);
                    }}
                    variant={
                      iHaveReactedWithReaction(reactionOption.id)
                        ? 'solid'
                        : 'ghost'
                    }
                    icon={
                      <React.Suspense fallback={null}>
                        <Emoji
                          data={_emojiData}
                          emoji={reactionOption}
                          size={20}
                          {...emojiSetDef}
                        />
                      </React.Suspense>
                    }
                  />
                );
              })}
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
});

TotallyCustomReactionSelector.displayName = 'TotallyCustomReactionSelector';
