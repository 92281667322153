import { DocumentNode, gql } from '@apollo/client';
import * as React from 'react';

import { OnboardingFlowState } from '@/graphql-types/globalTypes';

import { CurrentStep_AllGroupsFragment } from './graphql-types/CurrentStep_AllGroupsFragment';
import { CurrentStep_AllTagsFragment } from './graphql-types/CurrentStep_AllTagsFragment';
import { CurrentStep_CurrentUserFragment } from './graphql-types/CurrentStep_CurrentUserFragment';
import { CurrentStep_FollowedTagsFragment } from './graphql-types/CurrentStep_FollowedTagsFragment';
import { CurrentStep_SponsorsFragment } from './graphql-types/CurrentStep_SponsorsFragment';
import { CurrentStep_TenantFragment } from './graphql-types/CurrentStep_TenantFragment';

import { useActivationModalContext } from '../../ActivationModalManager';
import { CodeOfConductStep } from './components/CodeOfConductStep';
import { ConfirmDetails } from './components/ConfirmDetails';
import { DownloadApp } from './components/DownloadApp';
import { Groups } from './components/Groups';
import { Sponsors } from './components/Sponsors';
import { Tags } from './components/Tags';

const CURRENT_USER_FRAGMENT = gql`
  fragment CurrentStep_CurrentUserFragment on User {
    _id
    ...ConfirmDetails_CurrentUserFragment
    visibleGroupLinks {
      _id
      group {
        ...NewUserJoinGroups_JoinedGroupsFragment
      }
    }
  }
  ${ConfirmDetails.confirmDetailsFragment}
  ${Groups.joinedGroupsFragment}
`;

const ALL_GROUPS_FRAGMENT = gql`
  fragment CurrentStep_AllGroupsFragment on Group {
    _id
    ...NewUserJoinGroups_AllGroupsFragment
  }
  ${Groups.allGroupsFragment}
`;

const FOLLOWED_TAGS_FRAGMENT = gql`
  fragment CurrentStep_FollowedTagsFragment on Tag {
    _id
    ...NewUserFollowTags_FollowedTagsFragment
  }
  ${Tags.followedTagsFragment}
`;

const ALL_TAGS_FRAGMENT = gql`
  fragment CurrentStep_AllTagsFragment on Tag {
    _id
    ...NewUserFollowTags_AllTagsFragment
  }
  ${Tags.allTagsFragment}
`;

const TENANT_FRAGMENT = gql`
  fragment CurrentStep_TenantFragment on Tenant {
    _id
    ...CodeOfConductStep_TenantFragment
    ...DownloadApp_TenantFragment
  }
  ${CodeOfConductStep.tenantFragment}
  ${DownloadApp.tenantFragment}
`;

const SPONSORS_FRAGMENT = gql`
  fragment CurrentStep_SponsorsFragment on Organization {
    _id
    ...NewUserFollowSponsors_SponsorsFragment
  }
  ${Sponsors.allSponsorsFragment}
`;

export const CurrentStep: React.FC<{
  currentUser: CurrentStep_CurrentUserFragment;
  allGroups: CurrentStep_AllGroupsFragment[];
  followedTags: CurrentStep_FollowedTagsFragment[];
  allTags: CurrentStep_AllTagsFragment[];
  tenant: CurrentStep_TenantFragment;
  allSponsors: CurrentStep_SponsorsFragment[];
}> & {
  currentUserFragment: DocumentNode;
  allGroupsFragment: DocumentNode;
  followedTagsFragment: DocumentNode;
  allTagsFragment: DocumentNode;
  tenantFragment: DocumentNode;
  allSponsorsFragment: DocumentNode;
} = ({
  currentUser,
  allGroups,
  followedTags,
  allTags,
  tenant,
  allSponsors,
}) => {
  const context = useActivationModalContext();

  if (!context?.onboardingFlowState?.currentFlowState) {
    return null;
  }

  const { validOnboardingFlowStates, currentStateIndex } =
    context.onboardingFlowState.currentFlowState;
  const isLastStep = currentStateIndex === validOnboardingFlowStates.length - 1;

  // if (context.isInactiveUser) {
  //   if (refFromSignUpEmail) {
  //     return <CreatePassword tenant={tenant} />;
  //   } else {
  //     return <ActivateAccount tenant={tenant} currentUser={currentUser} />;
  //   }
  // }

  switch (validOnboardingFlowStates[currentStateIndex]) {
    case OnboardingFlowState.ACCEPT_CODE_OF_CONDUCT:
      return <CodeOfConductStep tenant={tenant} />;
    case OnboardingFlowState.CONFIRM_DETAILS:
      return (
        <ConfirmDetails currentUser={currentUser} isLastStep={isLastStep} />
      );
    case OnboardingFlowState.JOIN_GROUP:
      return (
        <Groups
          alreadyJoinedGroups={
            currentUser.visibleGroupLinks.map((link) => link.group) || []
          }
          allGroups={allGroups}
          isLastStep={isLastStep}
        />
      );
    case OnboardingFlowState.JOIN_TAGS:
      return (
        <Tags
          followedTags={followedTags}
          allTags={allTags}
          isLastStep={isLastStep}
        />
      );
    case OnboardingFlowState.FOLLOW_SPONSORS:
      return <Sponsors sponsors={allSponsors} isLastStep={isLastStep} />;
    case OnboardingFlowState.DOWNLOAD_APP:
      return <DownloadApp tenant={tenant} isLastStep={isLastStep} />;
    default:
      return <></>;
  }
};

CurrentStep.currentUserFragment = CURRENT_USER_FRAGMENT;
CurrentStep.allGroupsFragment = ALL_GROUPS_FRAGMENT;
CurrentStep.followedTagsFragment = FOLLOWED_TAGS_FRAGMENT;
CurrentStep.allTagsFragment = ALL_TAGS_FRAGMENT;
CurrentStep.tenantFragment = TENANT_FRAGMENT;
CurrentStep.allSponsorsFragment = SPONSORS_FRAGMENT;
