import { Icon, IconProps } from '@chakra-ui/react';
import * as React from 'react';

export const AwardIcon = React.forwardRef<SVGSVGElement, IconProps>(
  (props, ref) => (
    <Icon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
      ref={ref}
    >
      <circle cx="12" cy="8" r="7"></circle>
      <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline>
    </Icon>
  )
);

AwardIcon.displayName = 'AwardIcon';
