import { gql } from '@apollo/client';

// TODO: figure out how to SSR the header
export const TENANT = gql`
  query Tenant {
    tenant {
      result {
        _id
        acronym
        displayName
        logoIcon {
          _id
          shortcutIcon: imageProxy(
            input: { h: 32, w: 32, fit: "fill", fill: "solid" }
          )
          appleTouchIcon: imageProxy(
            input: { h: 114, w: 114, fit: "fill", fill: "blur" }
          )
        }
        primaryColor
      }
    }
  }
`;

export const PAGE_CONTENT_FRAGMENT = gql`
  fragment PageContentFragment on PageContent {
    ... on Hero {
      name
      variant
      options {
        linkBehavior
        isLinkEnabled
      }
      imageId
      imageProxy(input: { w: 2000 })
      title
      subtitle
      body
      linkText
      linkHref
      linkPage {
        _id
        href
        name
        restriction
      }
      linkIsExternal
      linkSource
    }
    ... on BlockGroup {
      name
      variant
      options {
        columns
        backgroundColor
        blockAlignment
        blockLinkBehavior
        blockImageAspectRatio
        isBlockImageEnabled
        isBlockSubtitleEnabled
        isBlockTitleEnabled
        isBlockBodyTextEnabled
      }
      blocks {
        name
        imageId
        imageProxy(input: { w: 1000 })
        title
        subtitle
        body
        linkText
        linkHref
        linkPage {
          _id
          href
          name
          restriction
        }
        linkIsExternal
        linkSource
      }
    }
    ... on Carousel {
      name
      variant
      options {
        carouselItemLinkBehavior
        isCarouselItemSubtitleEnabled
        isCarouselItemTitleEnabled
        isCarouselItemBodyTextEnabled
        isCarouselAutoChangeEnabled
        carouselAutoChangeSpeed
      }
      carouselItems {
        name
        imageId
        imageProxy(input: { w: 1000 })
        title
        subtitle
        body
        linkText
        linkHref
        linkPage {
          _id
          href
          name
          restriction
        }
        linkIsExternal
        linkSource
      }
    }
    ... on ImageGroup {
      name
      variant
      options {
        backgroundColor
        imageHeight
        imageAlignment
      }
      images {
        name
        imageId
        imageProxy(input: {})
        linkHref
        linkPage {
          _id
          href
          name
          restriction
        }
        linkIsExternal
        linkSource
      }
    }
    ... on Heading {
      name
      variant
      title
      subtitle
      options {
        textAlign
        backgroundColor
      }
    }
    ... on RichText {
      name
      rawDraftContentState
      options {
        backgroundColor
      }
    }
  }
`;

export const PAGE_FRAGMENT = gql`
  fragment PageFragment on Page {
    _id
    name
    href
    slug
    content {
      ...PageContentFragment
    }
    metadata {
      version
      publishedVersion
    }
  }
  ${PAGE_CONTENT_FRAGMENT}
`;

export const TENANT_FRAGMENT = gql`
  fragment TenantFragment on Tenant {
    _id
    displayName
    logo {
      _id
      imageProxy(input: {})
    }
    contactInfo {
      phoneNumber
      email
      addressLine0
      addressLocality
      addressAdministrativeArea
      addressRegionCode
      addressPostalCode
      linkedin
      youtube
      twitter
      instagram
      facebook
    }
    privacyPolicyLink {
      url
      text
    }
    termsAndConditionsLink {
      url
      text
    }
    websiteCopyrightDate
  }
`;

export const HOME_PAGE_AND_TENANT = gql`
  query HomePageAndTenant {
    homePage {
      _id
      ...PageFragment
    }
    tenant {
      result {
        _id
        ...TenantFragment
      }
    }
  }
  ${PAGE_FRAGMENT}
  ${TENANT_FRAGMENT}
`;

export const PAGE_AND_TENANT = gql`
  query PageAndTenant($input: PageInput!) {
    page(input: $input) {
      _id
      ...PageFragment
    }
    tenant {
      result {
        _id
        ...TenantFragment
      }
    }
  }
  ${PAGE_FRAGMENT}
  ${TENANT_FRAGMENT}
`;

export const CURRENT_USER_ROOT_URL = gql`
  query CurrentUserRootUrl {
    tenant {
      result {
        currentUserLandingPageUrl
      }
    }
  }
`;

export const ORGANIZATION = gql`
  query Organization($input: SingleOrganizationInput!) {
    organization(input: $input) {
      result {
        _id
        isMember
        isSponsor
        membershipData {
          membershipStatus
        }
      }
    }
  }
`;

export const PAGES_FOR_SITEMAP = gql`
  query PagesForSitemap($input: PagesInput!) {
    pages(input: $input) {
      edges {
        node {
          _id
          absoluteUrl
        }
      }
    }
  }
`;

export const TENANT_FOR_ROBOTS = gql`
  query TenantForRobots {
    tenant {
      result {
        absoluteUrl
      }
    }
  }
`;
