import { Box, Heading, ModalHeader, Text } from '@chakra-ui/react';
import * as React from 'react';

// Todo (Flo): Replace all uses of this component with default chakra modal header
// Replace ActivationModal all together as it's an annoyingly complex component as well
export const ActivationModalHeader: React.FC<{
  title: React.ReactNode;
  subtitle: string;
}> = (props) => (
  <Box color="gray.700" px="8" pt="10" pb="6">
    <ModalHeader as={Heading} size="md" py="0" px="0">
      {props.title}
    </ModalHeader>
    <Text color="gray.600" mt="1">
      {props.subtitle}
    </Text>
  </Box>
);
