import { Box, Stack, Text } from '@chakra-ui/react';
import QRCodeReact from 'qrcode.react';
import * as React from 'react';

export const QRCode: React.FC<{ value?: string; loading?: boolean }> = ({
  value,
  loading,
}) => {
  if (!value || loading) {
    return null;
  }
  return (
    <Stack
      backgroundColor="white"
      p="4"
      borderRadius="md"
      borderWidth={1}
      borderColor="gray.200"
      spacing="4"
    >
      <QRCodeReact value={value} size={124} />
      <Text textStyle="detail" color="gray.600" lineHeight="1">
        Scan with phone camera
      </Text>
    </Stack>
  );
};
