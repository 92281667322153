import { Flex, FlexProps } from '@chakra-ui/react';
import * as React from 'react';

export const ActivationModalFooter: React.FC<FlexProps> = ({
  children,
  ...props
}) => (
  <Flex
    borderTop="solid"
    borderTopWidth="1px"
    borderTopColor="gray.200"
    justify="space-between"
    px="8"
    py="6"
    {...props}
  >
    {children}
  </Flex>
);
