import { ApolloLink } from '@apollo/client';

import { API_VERSION_HEADER_NAME } from '../../../legacy-shared-modules/api/api-version-header';

const forceReloadLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const currentApiVersion =
      localStorage && localStorage.getItem(API_VERSION_HEADER_NAME);
    const context = operation.getContext();
    const apiVersionOnHeader =
      context?.response?.headers &&
      context.response.headers.get(API_VERSION_HEADER_NAME);

    if (!!apiVersionOnHeader) {
      if (currentApiVersion === null) {
        localStorage.setItem(API_VERSION_HEADER_NAME, apiVersionOnHeader);
      } else if (currentApiVersion !== apiVersionOnHeader) {
        console.warn(
          `Client detected new api version (current: ${currentApiVersion} new: ${apiVersionOnHeader}) - force reloading.`
        );
        localStorage.setItem(API_VERSION_HEADER_NAME, apiVersionOnHeader);
        window.location.reload();
      }
    }
    return response;
  });
});

export default forceReloadLink;
