import { gql, useQuery } from '@apollo/client';
import { Box, Heading, Text } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import * as React from 'react';

import { OrderStatus } from '@/graphql-types/globalTypes';
import { Emoji } from '@/imports/ui/chakra/components/Emoji';

import {
  Title_MembershipOrder,
  Title_MembershipOrderVariables,
} from './graphql-types/Title_MembershipOrder';

const MEMBERSHIP_ORDER = gql`
  query Title_MembershipOrder($input: MembershipOrderInput!) {
    membershipOrder(input: $input) {
      membershipOrder {
        _id
        orderStatus
        options {
          membershipType {
            _id
            name
          }
        }
      }
    }
  }
`;

export const Title: React.FC<{
  userName: string;
  tenantName: string;
}> = ({ userName, tenantName }) => {
  const router = useRouter();

  const successfulMembershipOrderId = router.query.successfulMembershipOrderId
    ? router.query.successfulMembershipOrderId.toString()
    : undefined;

  const query = useQuery<Title_MembershipOrder, Title_MembershipOrderVariables>(
    MEMBERSHIP_ORDER,
    {
      variables: {
        input: { membershipOrderId: successfulMembershipOrderId ?? '' },
      },
      skip: !successfulMembershipOrderId,
    }
  );

  const membershipOrder = query.data?.membershipOrder.membershipOrder;

  if (query.loading) {
    return null;
  }

  return (
    <>
      <Heading color="white" size="md" py="0" px="0">
        <Box>
          <Emoji label="wave" symbol="👋" />
        </Box>

        {membershipOrder &&
        membershipOrder.orderStatus === OrderStatus.COMPLETE ? (
          <>
            <Box>Hi {userName},</Box>
            <Box>
              Your {membershipOrder.options.membershipType.name} is confirmed!
            </Box>
          </>
        ) : (
          <Box>
            Hi {userName}, welcome to the {tenantName} online community!
          </Box>
        )}
      </Heading>

      {membershipOrder &&
        membershipOrder.orderStatus === OrderStatus.COMPLETE && (
          <Text color="white" pt="4">
            Thank you for becoming a {tenantName} member
          </Text>
        )}
    </>
  );
};
