import { DocumentNode, gql, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Image,
  Stack,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { BigCheckbox, BigCheckboxGroup } from '@/components/BigCheckbox';
import { logError } from '@/imports/logging/ClientLogger';
import { ImageFallback } from '@/imports/ui/chakra/components/ImageFallback';

import { NewUserFollowSponsors_SponsorsFragment } from './graphql-types/NewUserFollowSponsors_SponsorsFragment';

import StepProgress from '../../../StepProgress';
import { ActivationModalBody } from '../ActivationModalBody';
import { ActivationModalFooter } from '../ActivationModalFooter';
import { ActivationModalHeader } from '../ActivationModalHeader';

const SPONSORS_FRAGMENT = gql`
  fragment NewUserFollowSponsors_SponsorsFragment on Organization {
    _id
    name
    profilePicture {
      _id
      imageProxy(input: { w: 96, h: 96, fit: "fill", fill: "solid" })
    }
    userOrganizationLink {
      _id
    }
  }
`;

const FOLLOW_SPONSORS = gql`
  mutation NewUserFollowSponsors_FollowSponsors($input: FollowSponsorsInput!) {
    followSponsors(input: $input) {
      user {
        _id
        onboardingFlowState {
          isSkippedOrCompleted
          currentFlowState {
            currentStateIndex
            validOnboardingFlowStates
          }
        }
      }
      sponsors {
        _id
        userOrganizationLink {
          _id
        }
      }
    }
  }
`;

interface INewUserFollowSponsorsProps {
  sponsors: NewUserFollowSponsors_SponsorsFragment[];
  isLastStep: boolean;
}

export const NewUserFollowSponsors: React.FC<INewUserFollowSponsorsProps> & {
  allSponsorsFragment: DocumentNode;
} = (props) => {
  const [followSponsors, followSponsorsResult] = useMutation(FOLLOW_SPONSORS);
  const { handleSubmit } = useForm();
  const toast = useToast();

  const alreadyFollowedSponsorIds = props.sponsors
    .filter((sponsor) => !!sponsor.userOrganizationLink?._id)
    .map((sponsor) => sponsor._id);

  const [followedSponsorIds, setFollowedSponsorIds] = React.useState<string[]>(
    []
  );

  const saveSponsors = async (): Promise<void> => {
    try {
      await followSponsors({
        variables: {
          input: {
            data: {
              sponsorIds: followedSponsorIds,
            },
          },
        },
      });
    } catch (err) {
      toast({
        title: 'Follow Sponsors failed',
        status: 'error',
      });
      logError(err);
    }
  };

  return (
    <Box
      as="form"
      display="flex"
      flexDirection="column"
      overflow="auto"
      flexGrow="1"
      noValidate
      onSubmit={handleSubmit(saveSponsors)}
    >
      <ActivationModalHeader
        title="Follow sponsors to get updates from"
        subtitle="Following sponsors allows you to get first access to sponsor posts, promotions and events."
      />
      <ActivationModalBody>
        <BigCheckboxGroup variant="spaced">
          {props.sponsors.map((sponsor) => {
            const wasAlreadyFollowed = alreadyFollowedSponsorIds.includes(
              sponsor._id
            );

            return (
              <BigCheckbox
                key={sponsor._id}
                isChecked={
                  wasAlreadyFollowed || followedSponsorIds.includes(sponsor._id)
                }
                isDisabled={wasAlreadyFollowed}
                onChange={(event) => {
                  if (event.target.checked) {
                    setFollowedSponsorIds((previous) => [
                      ...previous,
                      sponsor._id,
                    ]);
                  } else {
                    setFollowedSponsorIds((previous) =>
                      previous.filter((_id) => _id !== sponsor._id)
                    );
                  }
                }}
              >
                <Tooltip
                  label="You already follow this sponsor"
                  isDisabled={!wasAlreadyFollowed}
                >
                  <Stack direction="row" alignItems="center" spacing="4">
                    <Box h={12} w={12}>
                      <Image
                        src={sponsor.profilePicture?.imageProxy ?? undefined}
                        alt="group profile image"
                        fallback={
                          <ImageFallback h={12} w={12} borderRadius="lg" />
                        }
                        borderRadius="lg"
                      />
                    </Box>
                    <Text textStyle="bodyStrong">{sponsor.name}</Text>
                  </Stack>
                </Tooltip>
              </BigCheckbox>
            );
          })}
        </BigCheckboxGroup>
      </ActivationModalBody>
      <ActivationModalFooter>
        <StepProgress />
        <Button type="submit" isLoading={followSponsorsResult.loading}>
          {props.isLastStep ? 'Finish' : 'Next'}
        </Button>
      </ActivationModalFooter>
    </Box>
  );
};

NewUserFollowSponsors.allSponsorsFragment = SPONSORS_FRAGMENT;
