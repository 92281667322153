import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Dinero, isPositive, isZero } from 'dinero.js';

import { EUserGroupRole } from '@/client/legacy-shared-modules/user-group-link/user-group-role';
import {
  EDigestPreference,
  EGroupFindPermission,
  EGroupSelfJoinPermission,
  EGroupViewContentPermission,
  ENavigationItemRestriction,
  EPageRestriction,
  EUserRole,
  MembershipStatus,
  MembershipTermPaymentStatus,
} from '@/graphql-types/globalTypes';

export { asyncDebounce } from './async-debounce';

dayjs.extend(utc);
dayjs.extend(isSameOrBefore);
dayjs.extend(timezone);
dayjs.extend(LocalizedFormat);

export const isWithinThreeHours =
  (reference: Date | null) =>
  (date: Date): boolean => {
    const start = dayjs(reference);
    const startPlusThree = dayjs(reference).add(3, 'hours');
    const current = dayjs(date);
    return current.isAfter(start) && current.isSameOrBefore(startPlusThree);
  };

export const isSameDayWithinThreeHours =
  (reference: Date | null) =>
  (date: Date): boolean => {
    const start = dayjs(reference);
    const startPlusThree = dayjs(reference).add(3, 'hours');
    const current = dayjs(date);
    return (
      current.isSame(start, 'day') || current.isSame(startPlusThree, 'day')
    );
  };

export enum MEMBERSHIP_STATUS {
  MEMBER = 'member',
  NONMEMBER = 'nonmember',
}

export const EMAIL_PLACEHOLDER = 'name@domain.com';
export const PASSWORD_PLACEHOLDER = '*********';

export const getCompanyJobTitle = (
  company: string | null,
  jobTitle: string | null
): string => {
  return !!company && !!jobTitle
    ? `${jobTitle} at ${company}`
    : !!company
    ? company
    : !!jobTitle
    ? jobTitle
    : '';
};

export const getTimeAgoShorthand = (date: Date): string => {
  const now = dayjs();
  const then = dayjs.utc(date);
  if (then.add(10, 'minutes').isAfter(now)) {
    return 'Just now';
  } else if (then.add(1, 'hour').isAfter(now)) {
    const numMinutes = Math.round(now.diff(then, 'minutes', true));
    return `${numMinutes}m`;
  } else if (then.add(1, 'day').isAfter(now)) {
    const numHours = Math.round(now.diff(then, 'hours', true));
    return `${numHours}h`;
  } else if (then.add(1, 'week').isAfter(now)) {
    const numWeeks = Math.round(now.diff(then, 'days', true));
    return `${numWeeks}d`;
  } else if (then.add(1, 'month').isAfter(now)) {
    const numWeeks = Math.round(now.diff(then, 'weeks', true));
    return `${numWeeks}w`;
  } else if (then.year() === now.year()) {
    return `${then.format('MMM D')}`;
  }
  return `${then.format('MMM D, YYYY')}`;
};

export const getFullTimestamp = (date: Date): string => {
  return `${dayjs(date).format('dddd, MMMM D, YYYY [at] LT')}`;
};

export const round = (numHits: number): number => {
  if (typeof numHits !== 'number') return 0;

  return parseFloat(numHits.toPrecision(1));
};

export const getProfessionalDetail = (
  title?: string | null,
  company?: string | null
): string | null => {
  return title && company
    ? `${title} at ${company}`
    : title
    ? title
    : company
    ? company
    : null;
};

export const formatPhoneNumber = (phoneNumberString: string): string => {
  const cleaned = phoneNumberString.replace(/\D/g, '');
  const match = cleaned.match(/(.+?)?([0-9]{3})([0-9]{3})([0-9]{4})$/);

  if (match) {
    const [, country, area, central, line] = match;
    const intlCode = country ? `${country} ` : '';

    return `${intlCode}(${area}) ${central}-${line}`;
  }

  return '';
};

export const getDigestLabel = (option: EDigestPreference): string | null => {
  const digestMap = new Map<EDigestPreference, string>([
    [EDigestPreference.OFF, 'Off'],
    [EDigestPreference.DAILY, 'Daily'],
    [EDigestPreference.WEEKLY, 'Weekly'],
    [EDigestPreference.REALTIME, 'Realtime'],
  ]);
  return digestMap.get(option) ?? null;
};

export const getPermissionLabel = (options: {
  find?: EGroupFindPermission;
  viewContent?: EGroupViewContentPermission;
  selfJoin?: EGroupSelfJoinPermission;
}): string | null => {
  const findMap = new Map<EGroupFindPermission, string>([
    [EGroupFindPermission.PUBLIC, 'Public'],
    [EGroupFindPermission.LOGGED_IN, 'Logged In Users'],
    [EGroupFindPermission.MEMBER_STATUS_USERS, 'Users with Member Status'],
    [EGroupFindPermission.ADMINS, 'Admins'],
    [EGroupFindPermission.GROUP_MEMBERS_ONLY, 'Only Current Group Members'],
  ]);
  if (options.find) {
    return findMap.get(options.find) || null;
  }

  const selfJoinMap = new Map<EGroupSelfJoinPermission, string>([
    [EGroupSelfJoinPermission.LOGGED_IN, 'Logged In Users'],
    [EGroupSelfJoinPermission.MEMBER_STATUS_USERS, 'Users with Member Status'],
    [EGroupSelfJoinPermission.ADMINS, 'Admins'],
    [EGroupSelfJoinPermission.NOBODY, 'Nobody'],
  ]);
  if (options.selfJoin) {
    return selfJoinMap.get(options.selfJoin) || null;
  }

  const viewContentMap = new Map<EGroupViewContentPermission, string>([
    [
      EGroupViewContentPermission.SAME_AS_FIND,
      'Anybody that can find this group',
    ],
    [
      EGroupViewContentPermission.SAME_AS_SELF_JOIN_PLUS_GROUP_MEMBERS,
      'Current group members or users that can join this group',
    ],
  ]);
  if (options.viewContent) {
    return viewContentMap.get(options.viewContent) || null;
  }

  return null;
};

export const getStringParamOrFirstStringParam = (
  param: string | string[]
): string => {
  return typeof param === 'string' ? param : param[0];
};

export enum RouteRestrictions {
  AUTHENTICATION = 'AUTHENTICATION',
  ADMIN_ROLE = 'ADMIN_ROLE',
  MORGOTH_ROLE = 'MORGOTH_ROLE',
  COMMUNITY_ACCESS = 'COMMUNITY_ACCESS',
  COMMUNITY_READ_ONLY_ACCESS = 'COMMUNITY_READ_ONLY_ACCESS',
  SPONSOR_DASHBOARD_ACCESS = 'SPONSOR_DASHBOARD_ACCESS',
  ORGANIZATION_DASHBOARD_ACCESS = 'ORGANIZATION_DASHBOARD_ACCESS',
  ORGANIZATION_MEMBERSHIP_ACCESS = 'ORGANIZATION_MEMBERSHIP_ACCESS',
  DM_PAGE_ACCESS = 'CAN_ACCESS_DMS',
  FEATURE_FLAG_CMS = 'FEATURE_FLAG_CMS',
  FEATURE_FLAG_CONFERENCES = 'FEATURE_FLAG_CONFERENCES',
  SPONSOR_DIRECTORY_ACCESS = 'SPONSOR_DIRECTORY_ACCESS',
  MEMBER_DIRECTORY_ACCESS = 'MEMBER_DIRECTORY_ACCESS',
  EVENTS_FEED_ACCESS = 'EVENTS_FEED_ACCESS',
}

export const READABLE_USER_ROLE: Record<EUserRole, string> = {
  [EUserRole.REGULAR_USER]: 'Regular User',
  [EUserRole.ADMIN]: 'Admin',
  [EUserRole.ORGANIZATION_ACCOUNT_OWNER]: 'Company Account Owner',
  [EUserRole.ORGANIZATION_PAGE_ADMIN]: 'Company Page Admin',
  [EUserRole.MORGOTH]: 'Morgoth',
};

export const isGreaterThanZero = (dineroObject: Dinero<number>): boolean => {
  return isPositive(dineroObject) && !isZero(dineroObject);
};

export const READABLE_USER_ROLE_FOR_ORGANIZATION: Record<EUserRole, string> = {
  [EUserRole.REGULAR_USER]: 'User',
  [EUserRole.ADMIN]: 'Admin',
  [EUserRole.ORGANIZATION_ACCOUNT_OWNER]: 'Account Owner',
  [EUserRole.ORGANIZATION_PAGE_ADMIN]: 'Page Admin',
  [EUserRole.MORGOTH]: 'Morgoth',
};

export const READABLE_USER_ROLE_FOR_GROUP: Record<EUserGroupRole, string> = {
  [EUserGroupRole.GroupAdmin]: 'Group Admin',
  [EUserGroupRole.GroupModerator]: 'Group Moderator',
  [EUserGroupRole.GroupMember]: 'Member',
};

export const READABLE_MEMBERSHIP_STATUS = {
  [MembershipStatus.MEMBER]: 'Active Member',
  [MembershipStatus.LAPSED]: 'Lapsed',
  [MembershipStatus.NOT_A_MEMBER]: 'Not a Member',
};

export const READABLE_PAYMENT_STATUS = {
  [MembershipTermPaymentStatus.NOT_PAID]: 'Not Paid',
  [MembershipTermPaymentStatus.PAID]: 'Paid',
  [MembershipTermPaymentStatus.PARTIALLY_PAID]: 'Partially Paid',
  [MembershipTermPaymentStatus.MONTHLY_PAYMENTS_REMAINING]:
    'Monthly Payments Remaining',
};

export const READABLE_PAGE_RESTRICTION = {
  [EPageRestriction.ANYONE]: 'Public',
  [EPageRestriction.LOGGED_IN]: 'Logged in Users',
  [EPageRestriction.MEMBERS_ONLY]: 'Members Only',
};

export const READABLE_NAVIGATION_ITEM_RESTRICTION = {
  [ENavigationItemRestriction.ANYONE]: 'Public',
  [ENavigationItemRestriction.LOGGED_IN]: 'Logged in Users',
  [ENavigationItemRestriction.MEMBERS_ONLY]: 'Members Only',
  [ENavigationItemRestriction.NOBODY]: 'Hidden',
};

export const PAGE_RESTRICTION_TO_NAVIGATION_ITEM_RESTRICTION = {
  [EPageRestriction.LOGGED_IN]: ENavigationItemRestriction.LOGGED_IN,
  [EPageRestriction.MEMBERS_ONLY]: ENavigationItemRestriction.MEMBERS_ONLY,
  [EPageRestriction.ANYONE]: ENavigationItemRestriction.ANYONE,
};

export enum ATTRIBUTE_FIELD_TYPE {
  FREE_TEXT = 'Free Text',
  SINGLE_SELECT = 'Single-select',
  MULTI_SELECT = 'Multi-select',
}

export const REG_EXP = {
  EmailWithTLD:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  Phone:
    /^[0-9０-９٠-٩۰-۹]{2}$|^[+＋]*(?:[-x‐-―−ー－-／  ­​⁠　()（）［］.\[\]/~⁓∼～*]*[0-9０-９٠-٩۰-۹]){3,}[-x‐-―−ー－-／  ­​⁠　()（）［］.\[\]/~⁓∼～0-9０-９٠-٩۰-۹]*(?:;ext=([0-9０-９٠-٩۰-۹]{1,7})|[  \t,]*(?:e?xt(?:ensi(?:ó?|ó))?n?|ｅ?ｘｔｎ?|[,xｘ#＃~～]|int|anexo|ｉｎｔ)[:\.．]?[  \t,-]*([0-9０-９٠-٩۰-۹]{1,7})#?|[- ]+([0-9０-９٠-٩۰-۹]{1,5})#)?$/i, // eslint-disable-line no-irregular-whitespace
};

export const isValidHttpURL = (urlText: string): boolean => {
  try {
    const url = new URL(urlText);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (err) {
    return false;
  }
};

export const TIMEZONE_GUESS = dayjs.tz.guess();

export const TOKBOX_DEPRECATION_DATE = '2024-07-12';
export const TOKBOX_SESSION_WARNING =
  'Support for Live Stream on Tradewing events ended on July 12th 2024. You must select another option for your event.';

/**
 * We're storing external urls for embedding videos from other platforms in video file ids.
 * It's hacky but it works.
 */
export const isVideoFileALink = (videoFile: { _id: string }): boolean => {
  return isValidHttpURL(videoFile._id)
};
