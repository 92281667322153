import {
  InstantSearch,
  InstantSearchProps,
  connectStateResults,
} from 'react-instantsearch-dom';

import { logError } from '@/imports/logging/ClientLogger';

const ErrorLogger = connectStateResults(({ error }) => {
  if (!!error) {
    logError(error);
  }

  return null;
});

export const InstantSearchWithErrorLogging: React.FC<InstantSearchProps> = ({
  children,
  ...instantSearchProps
}) => {
  return (
    <InstantSearch {...instantSearchProps}>
      <ErrorLogger />
      {children}
    </InstantSearch>
  );
};
