import { Box, BoxProps } from '@chakra-ui/react';
import * as React from 'react';

type EmojiProps = {
  label?: string;
  symbol: string;
};

export const Emoji: React.FC<BoxProps & EmojiProps> = ({
  label,
  symbol,
  ...rest
}) => {
  return (
    <Box
      as="span"
      display="inline"
      role="img"
      aria-label={label ? label : ''}
      aria-hidden={label ? 'false' : 'true'}
      {...rest}
    >
      {symbol}
    </Box>
  );
};
