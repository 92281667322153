import { ApolloClient, ApolloLink, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';

import { authLink } from '@/modules/accounts/accounts-client';

import cache from './cache';
import clearAuthLink from './links/clear-auth-link';
import errorLink from './links/error';
import fileUploadLink from './links/file-upload-link';
import forceReloadLink from './links/force-reload';
import magicTicketLinkLink from './links/magicTicketLink';
import meteorAccountsLink from './links/meteor';
import retryLink from './links/retry-link';
import stripTypenameLink from './links/stripTypenameLink';
import tenantHeaderLink from './links/tenant-header-link';
import userActivationLink from './links/userActivation';
import wsLink from './links/ws';

const forceReloadLinkSplit = split(
  // Omit ForceReloadLink from the chain for subscriptions: https://github.com/apollographql/apollo-client/issues/9061
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation !== 'subscription'
    );
  },
  forceReloadLink
);

const nonTerminatingLinks =
  typeof window !== 'undefined'
    ? [
        errorLink,
        clearAuthLink,
        meteorAccountsLink,
        userActivationLink,
        magicTicketLinkLink,
        retryLink,
        authLink,
        forceReloadLinkSplit,
        tenantHeaderLink,
        stripTypenameLink,
      ]
    : [];

// A terminating link that chooses between websockets and http/fileUpload
const splitLink = !!wsLink
  ? split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      fileUploadLink
    )
  : fileUploadLink;

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([...nonTerminatingLinks, splitLink]),
  cache,
  ssrMode: typeof window === 'undefined',
  connectToDevTools: process.env.NODE_ENV === 'production' ? false : true,
});
