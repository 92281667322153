import { ApolloError, NetworkStatus } from '@apollo/client';

import {
  ESponsorDirectoryAccessConfig,
  EUserRole,
} from '@/graphql-types/globalTypes';
import { MEMBERSHIP_STATUS } from '@/utilities';

import { useCurrent } from './useCurrent';

export const useAnalyticsUserProperties = (): {
  properties: {
    currentUserId: string | null;
    associationCode: string | undefined;
    vendorDirectory: boolean | undefined;
    communityAccess: boolean | undefined;
    userType: string | undefined;
    email: string | undefined;
    tier: string | undefined;
    role: string | undefined;
    membershipStatus: string | undefined;
  };
} => {
  const current = useCurrent();
  const currentUserId = current.user?._id || null;
  const associationCode = current.tenant?.associationCode;
  const vendorDirectory = !!current.tenant?.sponsorDirectoryAccessConfig
    ? current.tenant.sponsorDirectoryAccessConfig !==
      ESponsorDirectoryAccessConfig.HIDDEN
    : undefined;
  // doesn't count as community access if the user is an organization account owner or organization page admin
  const communityAccess =
    current.user?.role === EUserRole.ORGANIZATION_ACCOUNT_OWNER ||
    current.user?.role === EUserRole.ORGANIZATION_PAGE_ADMIN
      ? false
      : current.user?.canAccessCommunity;
  const userType = current.user?.analyticsUserType;
  const email = current.user?.email || undefined;
  const tier =
    current.user?.rosterOrganization?.sponsorship?.currentTier
      .analyticsTierType;
  const role = current.user?.analyticsRoleType;
  const membershipStatus = !!current.user?.integrationIsMember
    ? MEMBERSHIP_STATUS.MEMBER
    : MEMBERSHIP_STATUS.NONMEMBER;

  return {
    properties: {
      currentUserId,
      associationCode,
      vendorDirectory,
      communityAccess,
      userType,
      email,
      tier,
      role,
      membershipStatus,
    },
  };
};
