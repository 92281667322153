import { DocumentNode, gql, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
  useToast,
} from '@chakra-ui/react';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { logError } from '@/imports/logging/ClientLogger';

import {
  ConfirmDetails_ConfirmUserDetails,
  ConfirmDetails_ConfirmUserDetailsVariables,
} from './graphql-types/ConfirmDetails_ConfirmUserDetails';
import { ConfirmDetails_CurrentUserFragment } from './graphql-types/ConfirmDetails_CurrentUserFragment';

import StepProgress from '../../../StepProgress';
import { ActivationModalBody } from '../ActivationModalBody';
import { ActivationModalFooter } from '../ActivationModalFooter';
import { ActivationModalHeader } from '../ActivationModalHeader';

const CONFIRM_DETAILS_FRAGMENT = gql`
  fragment ConfirmDetails_CurrentUserFragment on User {
    _id
    firstName
    lastName
    jobTitle
    company
  }
`;

const UPDATE_USER_DETAILS = gql`
  mutation ConfirmDetails_ConfirmUserDetails($input: ConfirmUserDetailsInput!) {
    confirmUserDetails(input: $input) {
      user {
        _id
        firstName
        lastName
        jobTitle
        company
        onboardingFlowState {
          isSkippedOrCompleted
          currentFlowState {
            currentStateIndex
            validOnboardingFlowStates
          }
        }
      }
    }
  }
`;

type IFormData = {
  firstName: string;
  lastName: string;
  jobTitle: string;
  company: string;
};

export const ConfirmDetails: React.FC<{
  currentUser: ConfirmDetails_CurrentUserFragment;
  isLastStep: boolean;
}> & {
  confirmDetailsFragment: DocumentNode;
} = (props) => {
  const toast = useToast();

  const [confirmUserDetailsFunc, { loading: loadingConfirmingUserDetails }] =
    useMutation<
      ConfirmDetails_ConfirmUserDetails,
      ConfirmDetails_ConfirmUserDetailsVariables
    >(UPDATE_USER_DETAILS);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    formState,
  } = useForm<IFormData>();

  React.useEffect(() => {
    reset({
      firstName: props.currentUser.firstName ?? '',
      lastName: props.currentUser.lastName ?? '',
      jobTitle: props.currentUser.jobTitle ?? '',
      company: props.currentUser.company ?? '',
    });
  }, [props.currentUser, reset]);

  /**
   * API Methods
   */

  const updateUser = async (form: IFormData): Promise<void> => {
    try {
      const { data } = await confirmUserDetailsFunc({
        variables: {
          input: {
            data: {
              firstName: form.firstName,
              lastName: form.lastName,
              jobTitle: form.jobTitle,
              company: form.company,
            },
          },
        },
      });

      if (!data?.confirmUserDetails?.user) {
        throw Error;
      }
    } catch (err) {
      toast({
        title: 'Failed to confirm details.',
        status: 'error',
      });
      logError(err);
    }
  };

  return (
    <Box
      as="form"
      display="flex"
      flexDirection="column"
      overflow="auto"
      flexGrow="1"
      noValidate
      onSubmit={handleSubmit(updateUser)}
    >
      <ActivationModalHeader
        title="Let's confirm your details!"
        subtitle="Please confirm your name, last name and job title."
      />

      <ActivationModalBody>
        <VStack spacing="4">
          <Flex w="full" flexDirection={['column', null, 'row']}>
            <FormControl
              mr={[0, null, 4]}
              mb={[4, null, 0]}
              isRequired
              isInvalid={!!errors?.firstName?.message}
            >
              <FormLabel requiredIndicator={<></>}>First Name</FormLabel>
              <Input
                autoFocus
                placeholder="First Name"
                {...register('firstName', {
                  required: 'First name is required',
                })}
              />
              <FormErrorMessage>{errors?.firstName?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={!!errors?.lastName?.message}>
              <FormLabel requiredIndicator={<></>}>Last name</FormLabel>
              <Input
                placeholder="Last Name"
                {...register('lastName', { required: 'Last name is required' })}
              />
              <FormErrorMessage>{errors?.lastName?.message}</FormErrorMessage>
            </FormControl>
          </Flex>

          <FormControl>
            <FormLabel>Job Title</FormLabel>
            <Input placeholder="Job Title" {...register('jobTitle')} />
          </FormControl>

          <FormControl>
            <FormLabel>Company</FormLabel>
            <Input placeholder="Company name" {...register('company')} />
          </FormControl>
        </VStack>
      </ActivationModalBody>

      <ActivationModalFooter>
        <StepProgress />
        <Button
          type="submit"
          isLoading={loadingConfirmingUserDetails}
          isDisabled={formState.isSubmitted && !formState.isValid}
        >
          {props.isLastStep ? 'Finish' : 'Next'}
        </Button>
      </ActivationModalFooter>
    </Box>
  );
};

ConfirmDetails.confirmDetailsFragment = CONFIRM_DETAILS_FRAGMENT;
