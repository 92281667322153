import { StreamClient, StreamFeed, connect } from 'getstream';
import * as React from 'react';

import {
  ITradewingActivity,
  ITradewingChildReactionType,
  ITradewingCollectionType,
  ITradewingPersonalizationType,
  ITradewingReactionType,
  ITradewingUserType,
} from './activityTypes';

type IStreamFeedClient = StreamClient<
  ITradewingUserType,
  ITradewingActivity,
  ITradewingCollectionType,
  ITradewingReactionType,
  ITradewingChildReactionType,
  ITradewingPersonalizationType
>;

export type IStreamNotificationFeed = StreamFeed<
  ITradewingUserType,
  ITradewingActivity,
  ITradewingCollectionType,
  ITradewingReactionType,
  ITradewingChildReactionType
>;

type IStreamFeedContextState = {
  notificationFeed: IStreamNotificationFeed | null; // TODO validate that notificationFeed.subscribe called multiple times will only set up one websocket.
};

const StreamFeedContext = React.createContext<
  IStreamFeedContextState | undefined
>(undefined);

export const useStreamFeedContext = (): IStreamFeedContextState | undefined =>
  React.useContext(StreamFeedContext);

export const StreamFeedProvider: React.FC<{
  config:
    | {
        notificationReadToken: string;
        notificationFeedGroupName: string;
        notificationFeedName: string;
        notificationFeedApiKey: string;
        notificationFeedAppId: string;
        notificationFeedStartTime: string;
      }
    | null
    | undefined;
}> = ({ config, children }) => {
  const [streamFeedState, setStreamFeedState] = React.useState<{
    feedClient: IStreamFeedClient;
    notificationFeed: IStreamNotificationFeed;
  } | null>(null);

  React.useEffect(() => {
    if (config) {
      setStreamFeedState((oldState) => {
        if (oldState !== null) {
          return oldState;
        }
        const client = connect<
          ITradewingUserType,
          ITradewingActivity,
          ITradewingCollectionType,
          ITradewingReactionType,
          ITradewingChildReactionType,
          ITradewingPersonalizationType
        >(config.notificationFeedApiKey, null, config.notificationFeedAppId);
        return {
          feedClient: client,
          notificationFeed: client.feed(
            config.notificationFeedGroupName,
            config.notificationFeedName,
            config.notificationReadToken
          ),
        };
      });
    }
  }, [config]);

  return (
    <StreamFeedContext.Provider
      value={{
        notificationFeed: streamFeedState?.notificationFeed || null,
      }}
    >
      {children}
    </StreamFeedContext.Provider>
  );
};
