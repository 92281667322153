import { gql, useQuery } from '@apollo/client';
import type amplitude_Type from 'amplitude-js';
import * as React from 'react';
import yn from 'yn';

import { useAmplitudeClient_getAmplitudeApiKey } from './graphql-types/useAmplitudeClient_getAmplitudeApiKey';

const NEXT_PUBLIC_AMPLITUDE_ENABLED = yn(
  process.env.NEXT_PUBLIC_AMPLITUDE_ENABLED
);

const GET_AMPLITUDE_API_KEY = gql`
  query useAmplitudeClient_getAmplitudeApiKey {
    getAmplitudeApiKey {
      apiKey
    }
  }
`;

export const useAmplitudeClient = (): {
  isLoading: Boolean;
  hasError: Boolean;
  amplitudeClient: amplitude_Type.AmplitudeClient | null;
} => {
  const [amplitudeClient, setAmplitudeClient] =
    React.useState<amplitude_Type.AmplitudeClient | null>(null);

  const query = useQuery<useAmplitudeClient_getAmplitudeApiKey>(
    GET_AMPLITUDE_API_KEY,
    {
      skip: !NEXT_PUBLIC_AMPLITUDE_ENABLED,
    }
  );

  const apiKey = query.data?.getAmplitudeApiKey.apiKey;

  React.useEffect(() => {
    (async function () {
      if (apiKey) {
        const amplitude = await import('amplitude-js');
        const instance = amplitude.getInstance();
        instance.init(apiKey);
        setAmplitudeClient(instance);
      }
    })();
  }, [apiKey]);

  return {
    isLoading: query.loading,
    hasError: !!query.error,
    amplitudeClient,
  };
};
