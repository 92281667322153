import { DocumentNode, gql } from '@apollo/client';
import { Box, BoxProps, Center, Heading, Image } from '@chakra-ui/react';
import * as React from 'react';

import { ProfileImageIcon_GroupFragment } from './graphql-types/ProfileImageIcon_GroupFragment';

export const ProfileImageIcon: React.FC<
  {
    group: ProfileImageIcon_GroupFragment;
  } & BoxProps
> & {
  groupFragment: DocumentNode;
} = ({ group, ...boxProps }) => {
  return (
    <Box h={12} w={12} {...boxProps}>
      <Image
        src={group?.profileImage?.imageProxy || undefined}
        alt="group profile image"
        fallback={
          <Center h={12} w={12} borderRadius="lg" bg="gray.200" {...boxProps}>
            <Heading size="lg">{group.name[0]}</Heading>
          </Center>
        }
        borderRadius="lg"
      />
    </Box>
  );
};

ProfileImageIcon.groupFragment = gql`
  fragment ProfileImageIcon_GroupFragment on Group {
    _id
    name
    profileImage {
      _id
      imageProxy(input: { w: 96, h: 96, fit: "fill", fill: "solid" })
    }
  }
`;
