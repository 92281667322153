import { Icon, IconProps } from '@chakra-ui/react';
import * as React from 'react';

export const NavigationIcon: React.FC<IconProps> = (props) => (
  <Icon
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <polygon points="3 11 22 2 13 21 11 13 3 11"></polygon>
  </Icon>
);
