import { Icon, IconProps } from '@chakra-ui/react';
import * as React from 'react';

export const ArrowUpLeftIcon: React.FC<IconProps> = (props) => (
  <Icon
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <line x1="17" y1="17" x2="7" y2="7"></line>
    <polyline points="7 17 7 7 17 7"></polyline>
  </Icon>
);
