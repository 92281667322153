import { Box, BoxProps } from '@chakra-ui/react';
import * as React from 'react';

export const ActivationModalBody: React.FC<BoxProps> = ({
  children,
  ...boxProps
}) => (
  <Box overflow="auto" px="8" pb="6" flexGrow="1" {...boxProps}>
    {children}
  </Box>
);
