import {
  Checkbox,
  CheckboxProps,
  Stack,
  StackDivider,
  StackProps,
} from '@chakra-ui/react';
import * as React from 'react';

const BigCheckboxContext = React.createContext<{
  variant: 'spaced' | 'attached';
} | null>(null);

const useBigCheckbox = () => {
  const context = React.useContext(BigCheckboxContext);

  if (!context) {
    throw new Error(
      '<BigCheckbox/> must be used inside of a <BigCheckboxGroup/>'
    );
  }

  return context;
};

export const BigCheckboxGroup: React.FC<
  StackProps & { variant?: 'spaced' | 'attached' }
> = ({ children, variant = 'attached', ...props }) => {
  const style = {
    attached: {
      borderWidth: '1px',
      borderColor: 'gray.200',
      divider: <StackDivider />,
      spacing: '0',
      overflow: 'hidden',
    },
    spaced: {},
  }[variant];

  return (
    <BigCheckboxContext.Provider value={{ variant }}>
      <Stack {...style} variant={variant} borderRadius="xl" {...props}>
        {children}
      </Stack>
    </BigCheckboxContext.Provider>
  );
};

export const BigCheckbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ children, ...props }, ref) => {
    const { variant } = useBigCheckbox();

    return (
      <Checkbox variant={variant} {...props} ref={ref}>
        {children}
      </Checkbox>
    );
  }
);

BigCheckbox.displayName = 'BigCheckbox';
