import { gql } from '@apollo/client';
import algoliasearch, { SearchClient } from 'algoliasearch';
import { useRouter } from 'next/router';
import * as React from 'react';

import { logError } from '@/imports/logging/ClientLogger';
import { useQueryWithPastDataDeprecated } from '@/imports/ui/useQueryWithPastData';

import { getSearchKeys } from './graphql-types/getSearchKeys';

type ISearchContext = {
  client: ISearchClientProxy | null;
  indexData?: {
    postIndexName: string;
    orgIndexName: string;
    eventIndexName: string;
    fileIndexName: string;
    memberIndexName: string;
    memberIndexSearchableField: string;
    memberIndexViewableField: string;
  };
  indexFilterData?: {
    memberIndexFilter: string;
    orgIndexFilter?: string | null;
  };
};

type ISearchClientProxy = Pick<SearchClient, 'search' | 'clearCache'>;

const SearchContext = React.createContext<ISearchContext>({
  client: null,
});

export const useGlobalSearchContext = (): ISearchContext =>
  React.useContext(SearchContext);

export const getSearchKeysAST = gql`
  query getSearchKeys {
    getSearchKeys {
      applicationId
      searchApiKey
      indexData {
        postIndexName
        orgIndexName
        eventIndexName
        fileIndexName
        memberIndexName
        memberIndexSearchableField
        memberIndexViewableField
      }
      indexFilterData {
        memberIndexFilter
        orgIndexFilter
      }
    }
  }
`;

export const SearchProvider: React.FC<{ isAuthenticated: boolean }> = (
  props
) => {
  const { error, data } = useQueryWithPastDataDeprecated<getSearchKeys>(
    getSearchKeysAST,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      skip: !props.isAuthenticated,
    }
  );
  const router = useRouter();
  const [searchClient, setSearchClient] =
    React.useState<ISearchClientProxy | null>(null);

  React.useEffect(() => {
    if (data) {
      const client = algoliasearch(
        data.getSearchKeys.applicationId,
        data.getSearchKeys.searchApiKey
      );

      //https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/js/
      const searchClientProxy: ISearchClientProxy = {
        search(queries, requestOptions) {
          if (
            queries.every(
              ({ params }) =>
                !params?.query || (params?.query && params?.query?.length <= 2)
            )
          ) {
            return Promise.resolve({
              results: queries.map(() => ({
                hits: [],
                nbHits: 0,
                nbPages: 0,
                page: 0,
                processingTimeMS: 0,
                hitsPerPage: 0,
                exhaustiveNbHits: false,
                query: '',
                params: '',
              })),
            });
          }

          return client.search(queries, requestOptions);
        },
        async clearCache() {
          return client.clearCache();
        },
      };
      setSearchClient(searchClientProxy);
    }
  }, [data, setSearchClient]);

  // TODO check this?
  React.useEffect(() => {
    if (searchClient) {
      searchClient.clearCache().catch((err) => logError(err));
    }
  }, [router.pathname, router.query, searchClient]);

  if (error) {
    logError(error);
  }

  return (
    <SearchContext.Provider
      value={{
        client: searchClient,
        indexData: data?.getSearchKeys.indexData,
        indexFilterData: data?.getSearchKeys.indexFilterData,
      }}
    >
      {props.children}
    </SearchContext.Provider>
  );
};
