import {
  Stack,
  StackProps,
  Text,
  TextProps,
  forwardRef,
} from '@chakra-ui/react';
import * as React from 'react';

export const ReadOnly: React.FC<StackProps> = ({ children, ...props }) => {
  return (
    <Stack spacing="0" {...props}>
      {children}
    </Stack>
  );
};

export const ReadOnlyLabel: React.FC<TextProps> = ({ children, ...props }) => {
  return (
    <Text textStyle="compactStrong" {...props}>
      {children}
    </Text>
  );
};

export const ReadOnlyValue = forwardRef<TextProps, 'p'>(
  ({ children, ...props }, ref) => {
    return (
      <Text color="gray.600" {...props} ref={ref}>
        {children}
      </Text>
    );
  }
);

export const ReadOnlyNoValue: React.FC<TextProps> = ({
  children,
  ...props
}) => {
  return (
    <Text color="gray.500" {...props}>
      {children}
    </Text>
  );
};
