import { Box } from '@chakra-ui/react';
import { ChannelList, ChannelListProps } from 'stream-chat-react';

import { MessagingChannelListHeader } from './MessagingChannelListHeader';
import { MessagingChannelPreview } from './MessagingChannelPreview';

export const MessagingSidebar: React.FC<
  ChannelListProps & { onClose: () => void; isOpen: boolean }
> = ({ onClose, isOpen, ...props }) => {
  return (
    <Box
      as="nav"
      bg="white"
      overflow="auto"
      borderRightWidth="1px"
      borderRightColor="gray.200"
      flexShrink="0"
      onClick={onClose}
      w={{
        base: 'full',
        md: 'xs',
      }}
      display={{
        base: isOpen ? 'block' : 'none',
        md: 'block',
      }}
    >
      <MessagingChannelListHeader />
      <ChannelList {...props} Preview={MessagingChannelPreview} />
    </Box>
  );
};
