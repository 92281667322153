import { AlertProps, Button, Link, Stack, Text } from '@chakra-ui/react';
import NextLink from 'next/link';
import * as React from 'react';

import { AlertCircleIcon } from '@/imports/ui/chakra/feather';

import { useImpersonation } from './';

export const ImpersonationAlert: React.FC<AlertProps> & {
  IMPERSONATION_ALERT_HEIGHT: number;
} = (props) => {
  const { impersonatedUser, impersonationActive, stopImpersonation, loading } =
    useImpersonation();

  return impersonationActive ? (
    <Stack
      direction="row"
      alignItems="center"
      shadow="base"
      bg="white"
      p="4"
      {...props}
    >
      <AlertCircleIcon color="white" fill="red.500" width="6" height="6" />

      <Text flex="1">
        {`You're currently impersonating `}
        {impersonatedUser?._id && (
          <Link as={NextLink} href={`/members/${impersonatedUser._id}`}>
            {impersonatedUser?.displayName}
          </Link>
        )}
        {`'s account`}
      </Text>

      <Button
        colorScheme="red"
        onClick={() => stopImpersonation()}
        isLoading={loading}
      >
        Stop Impersonating
      </Button>
    </Stack>
  ) : null;
};

ImpersonationAlert.IMPERSONATION_ALERT_HEIGHT = 66;
