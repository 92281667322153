import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  WatchQueryFetchPolicy,
  useQuery,
} from '@apollo/client';
import isEmpty from 'lodash/isEmpty';

export const useQueryWithPastDataDeprecated = <
  TData = any,
  TVariables = OperationVariables
>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> => {
  const nextFetchPolicy: WatchQueryFetchPolicy = 'cache-first';
  const newOptions =
    options?.fetchPolicy === 'network-only'
      ? { nextFetchPolicy, ...options }
      : options;
  const queryResult = useQuery<TData, TVariables>(query, newOptions);
  return queryResult.loading
    ? {
        ...queryResult,
        data: isEmpty(queryResult.previousData)
          ? undefined
          : queryResult.previousData,
      }
    : queryResult;
};
