import { gql } from '@apollo/client';
import { useRouter } from 'next/router';
import * as React from 'react';

import { EUserAccountStatus } from '@/client/legacy-shared-modules/user/userEnums';

import { UseSetPasswordRedirect_User } from './graphql-types/UseSetPasswordRedirect_User';

/**
 * DO NOT REPLICATE THIS CODE
 * This is to fix an issue with activation because the notion of an
 * activated user is tied to that user having a password set (or
 * valid SSO). This should not be a client responsibility, but this
 * hack is being made to fix a current production bug.
 *
 * There is a ticket to fix this properly here:
 * https://www.notion.so/tradewing/Activation-of-users-should-be-controlled-by-the-server-and-potentially-not-tied-to-whether-or-not-a-12faed5a5fed461d9a887f69f8de5c26
 */

export const useSetPasswordRedirect = (
  currentUser: UseSetPasswordRedirect_User | null | undefined
) => {
  const router = useRouter();

  const shouldRedirect =
    !!currentUser?.accountStatus &&
    currentUser.accountStatus !== EUserAccountStatus.ACTIVE &&
    router.pathname !== '/activate' &&
    router.pathname !== '/v1/sso';

  React.useEffect(() => {
    if (shouldRedirect) {
      router.push({
        pathname: '/activate',
        query: { ...router.query, redirect: `${router.asPath}` },
      });
    }
  }, [shouldRedirect, router]);
};

useSetPasswordRedirect.userFragment = gql`
  fragment UseSetPasswordRedirect_User on User {
    _id
    accountStatus
  }
`;
