import { NetworkStatus, gql, useQuery } from '@apollo/client';
import type amplitude_Type from 'amplitude-js';
import { useRouter } from 'next/router';
import * as React from 'react';

import { useAnalyticsUserProperties } from '@/hooks/useAnalyticsUserProperties';
import { logError } from '@/imports/logging/ClientLogger';
import { AnalyticsLogger } from '@/imports/ui/amplitude/AnalyticsLogger';

import {
  organizationLinksForAmplitude,
  organizationLinksForAmplitudeVariables,
} from './graphql-types/organizationLinksForAmplitude';

import { useAnalytics } from './contexts/AnalyticsContext';

const organizationLinksForAmplitudeAST = gql`
  query organizationLinksForAmplitude($input: MultiUserOrganizationLinkInput) {
    userOrganizationLinks(input: $input) {
      totalCount
    }
  }
`;

type UserInfo = {
  userId: string | null;
  communityAccess?: boolean;
  userType?: string;
  vendorDirectory?: boolean;
  tenant?: string;
  email?: string;
  tier?: string;
  role?: string;
  membershipStatus?: string;
};

type AmplitudeState = {
  pathBeforeReady: { pathname: string; search?: string } | undefined;
  ready: boolean;
  userBeforeReady: UserInfo | undefined;
  tenant: string | null;
  analyticsContext:
    | {
        amplitudeClient: amplitude_Type.AmplitudeClient | null;
        currentUserId: string | null;
        referral: string | string[] | null | undefined;
      } & Record<string, any>;
};

type AmplitudeAction =
  | { event: 'set_number_of_sponsor_follows'; count: number }
  | {
      event: 'new_path';
      path: { pathname: string; search?: string };
    }
  | ({
      event: 'current_user_changed';
    } & UserInfo)
  | {
      event: 'timeout';
    }
  | {
      event: 'error';
      error: Error | undefined;
    }
  | {
      event: 'initialized';
    }
  | {
      event: 'tenantReady';
      tenant: string;
    }
  | {
      event: 'context_change';
      context:
        | {
            amplitudeClient: amplitude_Type.AmplitudeClient | null;
            currentUserId: string | null;
            referral: string | string[] | null | undefined;
          } & Record<string, any>;
    };

let analyticsLogger = AnalyticsLogger(
  null,
  { amplitudeClient: null, currentUserId: null, referral: null },
  null,
  {}
);

const reducer: React.Reducer<AmplitudeState, AmplitudeAction> = (
  prevState,
  action
) => {
  if (action.event === 'initialized') {
    if (!!prevState.userBeforeReady) {
      analyticsLogger = AnalyticsLogger(
        prevState.pathBeforeReady || null,
        prevState.analyticsContext,
        prevState.tenant,
        {
          vendorDirectory: prevState.userBeforeReady.vendorDirectory,
          communityAccess: prevState.userBeforeReady.communityAccess,
          userType: prevState.userBeforeReady.userType,
          email: prevState.userBeforeReady.email,
          tier: prevState.userBeforeReady.tier,
          role: prevState.userBeforeReady.role,
          membershipStatus: prevState.userBeforeReady.membershipStatus,
        }
      );
      analyticsLogger.setUserId(prevState.userBeforeReady.userId);
      analyticsLogger.setUserProperties({
        'community access': prevState.userBeforeReady.communityAccess,
        'user type': prevState.userBeforeReady.userType,
        'vendor directory': prevState.userBeforeReady.vendorDirectory,
        'tenant name': prevState.userBeforeReady.tenant,
        email: prevState.userBeforeReady.email,
        tier: prevState.userBeforeReady.tier,
        role: prevState.userBeforeReady.role,
        membershipStatus: prevState.userBeforeReady.membershipStatus,
      });
    }
    if (!!prevState.pathBeforeReady) {
      analyticsLogger = AnalyticsLogger(
        prevState.pathBeforeReady,
        prevState.analyticsContext,
        prevState.tenant,
        {
          vendorDirectory: prevState.userBeforeReady?.vendorDirectory,
          communityAccess: prevState.userBeforeReady?.communityAccess,
          userType: prevState.userBeforeReady?.userType,
          email: prevState.userBeforeReady?.email,
          tier: prevState.userBeforeReady?.tier,
          role: prevState.userBeforeReady?.role,
          membershipStatus: prevState.userBeforeReady?.membershipStatus,
        }
      );
      analyticsLogger.logEvent_pageload();
    }
    return {
      pathBeforeReady: undefined,
      ready: true,
      userBeforeReady: undefined,
      tenant: prevState.tenant,
      analyticsContext: prevState.analyticsContext,
    };
  }

  if (action.event === 'new_path') {
    if (prevState.ready) {
      analyticsLogger = AnalyticsLogger(
        action.path,
        prevState.analyticsContext,
        prevState.tenant,
        {
          vendorDirectory: prevState.userBeforeReady?.vendorDirectory,
          communityAccess: prevState.userBeforeReady?.communityAccess,
          userType: prevState.userBeforeReady?.userType,
          email: prevState.userBeforeReady?.email,
          tier: prevState.userBeforeReady?.tier,
          role: prevState.userBeforeReady?.role,
          membershipStatus: prevState.userBeforeReady?.membershipStatus,
        }
      );
      analyticsLogger.logEvent_pageload();
      return {
        pathBeforeReady: action.path,
        ready: prevState.ready,
        userBeforeReady: prevState.userBeforeReady,
        tenant: prevState.tenant,
        analyticsContext: prevState.analyticsContext,
      };
    }
    return {
      pathBeforeReady: action.path,
      ready: prevState.ready,
      userBeforeReady: prevState.userBeforeReady,
      tenant: prevState.tenant,
      analyticsContext: prevState.analyticsContext,
    };
  }

  if (action.event === 'timeout') {
    return {
      pathBeforeReady: undefined,
      ready: true,
      userBeforeReady: undefined,
      tenant: prevState.tenant,
      analyticsContext: prevState.analyticsContext,
    };
  }

  if (action.event === 'error') {
    !!action.error && logError(action.error);
    return {
      pathBeforeReady: undefined,
      ready: true,
      userBeforeReady: undefined,
      tenant: prevState.tenant,
      analyticsContext: prevState.analyticsContext,
    };
  }

  if (action.event === 'tenantReady') {
    return {
      pathBeforeReady: prevState.pathBeforeReady,
      ready: prevState.ready,
      userBeforeReady: prevState.userBeforeReady,
      tenant: action.tenant,
      analyticsContext: prevState.analyticsContext,
    };
  }

  if (action.event === 'context_change') {
    return {
      pathBeforeReady: prevState.pathBeforeReady,
      ready: prevState.ready,
      userBeforeReady: prevState.userBeforeReady,
      tenant: prevState.tenant,
      analyticsContext: action.context,
    };
  }

  if (action.event === 'set_number_of_sponsor_follows') {
    if (!!prevState.analyticsContext.analyticsClient) {
      prevState.analyticsContext.analyticsClient.setUserProperties({
        'number of sponsor follows': action.count,
      });
    }
    return prevState;
  }

  // 'current_user_changed'
  if (prevState.ready) {
    analyticsLogger = AnalyticsLogger(
      prevState.pathBeforeReady || null,
      prevState.analyticsContext,
      prevState.tenant,
      {
        vendorDirectory: action.vendorDirectory,
        communityAccess: action.communityAccess,
        userType: action.userType,
        email: action.email,
        tier: action.tier,
        role: action.role,
        membershipStatus: action.membershipStatus,
      }
    );
    analyticsLogger.setUserId(action.userId);
    analyticsLogger.setUserProperties({
      'community access': action.communityAccess,
      'user type': action.userType,
      'vendor directory': action.vendorDirectory,
      'tenant name': action.tenant,
      email: action.email,
      tier: action.tier,
      role: action.role,
      membershipStatus: action.membershipStatus,
    });
    return {
      pathBeforeReady: prevState.pathBeforeReady,
      ready: prevState.ready,
      userBeforeReady: undefined,
      tenant: prevState.tenant,
      analyticsContext: prevState.analyticsContext,
    };
  }
  return {
    pathBeforeReady: prevState.pathBeforeReady,
    ready: prevState.ready,
    userBeforeReady: {
      userId: action.userId,
      communityAccess: action.communityAccess,
      userType: action.userType,
      vendorDirectory: action.vendorDirectory,
      tenant: action.tenant,
      email: action.email,
      tier: action.tier,
      role: action.role,
      membershipStatus: action.membershipStatus,
    },
    tenant: prevState.tenant,
    analyticsContext: prevState.analyticsContext,
  };
};

export const AnalyticsSetup: React.FC = ({ children }) => {
  const router = useRouter();
  const [location, setLocation] = React.useState<
    | {
        pathname: string;
        search?: string;
      }
    | undefined
  >(undefined);

  React.useEffect(() => {
    if (router.isReady && location?.pathname !== router.asPath) {
      setLocation({ pathname: router.asPath });
    }
  }, [router, location]);

  const analyticsContext = useAnalytics();

  const { properties } = useAnalyticsUserProperties();
  const {
    currentUserId,
    associationCode,
    vendorDirectory,
    communityAccess,
    userType,
    email,
    tier,
    role,
    membershipStatus,
  } = properties;

  const organizationLinks = useQuery<
    organizationLinksForAmplitude,
    organizationLinksForAmplitudeVariables
  >(organizationLinksForAmplitudeAST, {
    skip: !currentUserId,
    variables: {
      input: {
        offset: 0,
        limit: 1,
      },
    },
  });

  const { amplitudeClient, hasError } = useAnalytics();

  const [state, dispatch] = React.useReducer(reducer, {
    pathBeforeReady: undefined,
    ready: false,
    userBeforeReady: undefined,
    tenant: associationCode || null,
    analyticsContext: analyticsContext,
  });

  React.useEffect(() => {
    dispatch({
      event: 'context_change',
      context: analyticsContext,
    });
  }, [analyticsContext]);

  React.useEffect(() => {
    // TODO: check is loading?
    if (hasError) {
      dispatch({
        event: 'error',
        error: new Error(
          'Cannot initialize Amplitude without Amplitude API key'
        ),
      });
    }
  }, [hasError]);

  React.useEffect(() => {
    // TODO: check is loading?
    if (amplitudeClient) {
      dispatch({ event: 'initialized' });
    }
  }, [amplitudeClient]);

  const numberOfSponsorFollows =
    organizationLinks.data?.userOrganizationLinks?.totalCount;

  React.useEffect(() => {
    if (
      numberOfSponsorFollows !== null &&
      numberOfSponsorFollows !== undefined
    ) {
      dispatch({
        event: 'set_number_of_sponsor_follows',
        count: numberOfSponsorFollows,
      });
    }
  }, [numberOfSponsorFollows]);

  React.useEffect(() => {
    if (!!associationCode) {
      dispatch({ event: 'tenantReady', tenant: associationCode });
    }
  }, [associationCode]);

  // Send page load upon path change.
  React.useEffect(() => {
    if (!!location) {
      dispatch({
        event: 'new_path',
        path: location,
      });
    }
  }, [location]);

  // // Current user query loaded or error
  // React.useEffect(() => {
  //   if (currentUserNetworkStatus === NetworkStatus.ready && !!currentUserId) {
  //     dispatch({
  //       event: 'current_user_changed',
  //       userId: currentUserId,
  //       communityAccess: communityAccess,
  //       userType: userType,
  //       vendorDirectory: vendorDirectory,
  //       tenant: associationCode,
  //       email: email || undefined,
  //       tier: tier,
  //       role,
  //       membershipStatus,
  //     });
  //   } else if (currentUserNetworkStatus === NetworkStatus.error) {
  //     dispatch({
  //       event: 'error',
  //       error: currentUserError,
  //     });
  //   }
  // }, [
  //   currentUserNetworkStatus,
  //   currentUserId,
  //   currentUserError,
  //   communityAccess,
  //   userType,
  //   vendorDirectory,
  //   associationCode,
  //   email,
  //   tier,
  //   role,
  //   membershipStatus,
  // ]);

  // timeout so rest of app is not held up by initializing GA if it is slow
  React.useEffect(() => {
    if (!state.ready) {
      const timeout = setTimeout(() => {
        dispatch({ event: 'timeout' });
      }, 2000);
      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [state.ready]);

  return <>{children}</>;
};
