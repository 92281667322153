import {
  Avatar,
  Box,
  FormControl,
  FormLabel,
  Input,
  List,
  ListItem,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Wrap,
} from '@chakra-ui/react';
import { useCombobox, useMultipleSelection } from 'downshift';
import unionWith from 'lodash/unionWith';
import * as React from 'react';
import { AutocompleteProvided } from 'react-instantsearch-core';
import { Configure, connectAutoComplete } from 'react-instantsearch-dom';

import { IMemberSearch } from '@/client/legacy-shared-modules/search/search-types';
import { usePermissions } from '@/hooks/usePermissions';
import { InstantSearchWithErrorLogging } from '@/imports/ui/components/search/InstantSearchWithErrorLogging';
import { useCommunityUsers } from '@/imports/ui/hooks/useCommunityUsers';
import { useDebouncedValue } from '@/imports/ui/hooks/useDebouncedValue';

type SearchableUserSelectProps = {
  placeholder?: string;
  value: string[];
  onChange: (value: string[]) => void;
};

const SearchContext = React.createContext<{
  setSearchText: (value: string) => void;
  users: { id: string; name?: string; image?: string }[];
} | null>(null);

const useSearchContext = () => {
  const searchContext = React.useContext(SearchContext);
  if (!searchContext) {
    throw new Error('Missing Context');
  }

  return searchContext;
};

const _SearchProvider: React.FC<AutocompleteProvided<IMemberSearch>> = ({
  refine,
  hits,
  children,
}) => {
  const [searchText, setSearchText] = React.useState('');

  const debouncedValue = useDebouncedValue(searchText, 500);

  React.useEffect(() => {
    if (debouncedValue) {
      refine(debouncedValue);
    }
  }, [refine, debouncedValue]);

  const users = hits.map((hit) => {
    return {
      id: hit.userId,
      name: hit.displayName,
      image: hit.profilePictureUrl ?? undefined,
    };
  });

  return (
    <SearchContext.Provider value={{ setSearchText, users }}>
      {children}
    </SearchContext.Provider>
  );
};

const SearchProvider = connectAutoComplete(_SearchProvider);

const _SearchableUserSelect: React.FC<SearchableUserSelectProps> = ({
  placeholder,
  onChange,
  value,
}) => {
  const { setSearchText, users } = useSearchContext();

  const [userCache, setUserCache] = React.useState<
    { id: string; name?: string; image?: string }[]
  >([]);

  React.useEffect(() => {
    setUserCache((previousValue) =>
      unionWith(previousValue, users, (a, b) => a.id === b.id)
    );
  }, [users]);

  const { getSelectedItemProps, getDropdownProps, removeSelectedItem } =
    useMultipleSelection({
      selectedItems: value,
      onStateChange({ selectedItems: newSelectedItems, type }) {
        switch (type) {
          case useMultipleSelection.stateChangeTypes
            .SelectedItemKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
          case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
            onChange(newSelectedItems ? newSelectedItems.map((id) => id) : []);
            break;
          default:
            break;
        }
      },
    });
  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    closeMenu,
  } = useCombobox({
    items: users.map((user) => user.id),
    itemToString: () => '',
    defaultHighlightedIndex: 0,
    selectedItem: null,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true,
            highlightedIndex: 0,
          };
        default:
          return changes;
      }
    },
    onStateChange({ inputValue, type, selectedItem }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            onChange([...value, selectedItem]);
          }
          break;
        case useCombobox.stateChangeTypes.InputChange:
          setSearchText(inputValue ?? '');
          break;
        default:
          break;
      }
    },
  });

  return (
    <Box>
      <Popover
        isOpen={isOpen}
        onOpen={openMenu}
        onClose={closeMenu}
        autoFocus={false}
        matchWidth
      >
        <FormControl>
          <FormLabel {...getLabelProps()}>Add Users to a New Channel</FormLabel>
          <Stack>
            <Wrap shouldWrapChildren>
              {value.map((userId, index) => (
                <Tag
                  key={`selected-item-${index}`}
                  {...getSelectedItemProps({
                    selectedItem: userId,
                    index,
                  })}
                >
                  <TagLabel>
                    {userCache.find((user) => user.id === userId)?.name ??
                      userId}
                  </TagLabel>
                  <TagCloseButton
                    onClick={(e) => {
                      e.stopPropagation();
                      removeSelectedItem(userId);
                    }}
                  />
                </Tag>
              ))}
            </Wrap>
            <PopoverAnchor>
              <Input
                placeholder={placeholder}
                {...getInputProps(
                  getDropdownProps({ preventKeyAction: isOpen })
                )}
              />
            </PopoverAnchor>
          </Stack>
          <PopoverContent display={users.length ? undefined : 'none'}>
            <PopoverBody w="full">
              <List {...getMenuProps()}>
                {users.map((user, index) => (
                  <ListItem
                    key={user.id}
                    bg={highlightedIndex === index ? 'gray.200' : undefined}
                    _hover={{
                      bg: 'gray.200',
                    }}
                    p="2"
                    borderRadius="xl"
                    cursor="pointer"
                    {...getItemProps({ item: user.id, index })}
                  >
                    <Stack direction="row" alignItems="center">
                      <Avatar name={user.name} src={user.image} size="sm" />
                      <Text>{user.name}</Text>
                    </Stack>
                  </ListItem>
                ))}
              </List>
            </PopoverBody>
          </PopoverContent>
        </FormControl>
      </Popover>
    </Box>
  );
};

export const SearchableUserSelect: React.FC<
  SearchableUserSelectProps & { exclude: string[] }
> = ({ exclude, ...props }) => {
  const { canAccessCommunityAndAuthenticated } = usePermissions();

  const { loading, indexName, searchClient } = useCommunityUsers(
    !canAccessCommunityAndAuthenticated
  );

  if (loading) {
    return null;
  }

  if (!indexName) {
    throw new Error('No index name.');
  }

  if (!searchClient) {
    throw new Error('No search client.');
  }

  const filters = exclude.length
    ? exclude.map((id) => 'NOT userId: ' + id).join(' AND ')
    : undefined;

  return (
    <InstantSearchWithErrorLogging
      searchClient={searchClient}
      indexName={indexName}
    >
      <SearchProvider>
        <_SearchableUserSelect {...props} />
      </SearchProvider>
      <Configure
        hitsPerPage={5}
        restrictSearchableAttributes={['displayName']}
        filters={filters}
      />
    </InstantSearchWithErrorLogging>
  );
};
