import type amplitude_Type from 'amplitude-js';
import { useRouter } from 'next/router';
import * as React from 'react';

import { useAmplitudeClient } from '@/hooks/useAmplitudeClient';
import { useCurrent } from '@/hooks/useCurrent';

type Analytics = {
  amplitudeClient: amplitude_Type.AmplitudeClient | null;
  currentUserId: string | null;
  referral: string | string[] | null | undefined;
} & Record<string, any>;

export const AnalyticsContext = React.createContext<Analytics>({
  amplitudeClient: null,
  currentUserId: null,
  referral: null,
});

export const useAnalytics = () => React.useContext(AnalyticsContext);

export const AnalyticsProvider: React.FC = ({ children }) => {
  const current = useCurrent();
  const router = useRouter();
  const { amplitudeClient } = useAmplitudeClient();

  const referral = router.query.referral;

  return (
    <AnalyticsContext.Provider
      value={{
        referral,
        amplitudeClient,
        currentUserId: current.user?._id || null,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
