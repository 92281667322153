import { gql } from '@apollo/client';
import * as React from 'react';
import yn from 'yn';

import { EUserRole } from '@/graphql-types/globalTypes';
import { MEMBERSHIP_STATUS } from '@/utilities';

import { UsePendo_Tenant } from './graphql-types/UsePendo_Tenant';
import { UsePendo_User } from './graphql-types/UsePendo_User';

const NEXT_PUBLIC_PENDO_ENABLED = yn(process.env.NEXT_PUBLIC_PENDO_ENABLED);

export const usePendo = (
  currentUser: UsePendo_User | undefined | null,
  tenant: UsePendo_Tenant | undefined | null
) => {
  const currentUserId = currentUser?._id || null;
  const associationCode = tenant?.associationCode;
  // doesn't count as community access if the user is an organization account owner or organization page admin
  const communityAccess =
    currentUser?.role === EUserRole.ORGANIZATION_ACCOUNT_OWNER ||
    currentUser?.role === EUserRole.ORGANIZATION_PAGE_ADMIN
      ? false
      : currentUser?.canAccessCommunity;
  const userType = currentUser?.analyticsUserType;
  const email = currentUser?.email || undefined;
  const name = `${currentUser?.firstName} ${currentUser?.lastName}`;
  const tier =
    currentUser?.rosterOrganization?.sponsorship?.currentTier.analyticsTierType;
  const role = currentUser?.analyticsRoleType;
  const membershipStatus = !!currentUser?.integrationIsMember
    ? MEMBERSHIP_STATUS.MEMBER
    : MEMBERSHIP_STATUS.NONMEMBER;

  React.useEffect(() => {
    if (!!currentUserId && !!associationCode && NEXT_PUBLIC_PENDO_ENABLED) {
      // Call this whenever information about your visitors becomes available
      // Please use Strings, Numbers, or Bools for value types.
      //@ts-ignore
      if (!!window.pendo && !!window.pendo.initialize) {
        //@ts-ignore
        window.pendo.initialize({
          visitor: {
            id: currentUserId, // Required if user is logged in
            email: email, // Recommended if using Pendo Feedback, or NPS Email
            userType: userType,
            hasCommunityAccess: communityAccess,
            full_name: name,
            tier,
            role: role,
            membershipStatus: membershipStatus,
          },

          account: {
            id: associationCode, // Highly recommended
          },
        });
      }
    }
  }, [
    associationCode,
    currentUserId,
    email,
    userType,
    communityAccess,
    name,
    tier,
    role,
    membershipStatus,
  ]);
};

usePendo.userFragment = gql`
  fragment UsePendo_User on User {
    _id
    canAccessCommunity
    integrationIsMember
    analyticsRoleType
    analyticsUserType
    email
    firstName
    lastName
    rosterOrganization {
      _id
      sponsorship {
        currentTier {
          analyticsTierType
        }
      }
    }
    role
  }
`;

usePendo.tenantFragment = gql`
  fragment UsePendo_Tenant on Tenant {
    _id
    associationCode
  }
`;
