import { DocumentNode, gql } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import jwt_decode from 'jwt-decode';
import * as React from 'react';

import { logError } from '@/imports/logging/ClientLogger';

import { ImpersonationProvider_User } from './graphql-types/ImpersonationProvider_User';

import { accountsClient } from '../accounts-client';

type IImpersonationState = {
  startImpersonation: (impersonatedUserId: string) => Promise<void>;
  stopImpersonation: () => Promise<void>;
  impersonationActive: boolean;
  impersonatedUser?: {
    _id: string;
    displayName: string;
  };
  loading: boolean;
};

const ImpersonationContext = React.createContext<IImpersonationState>({
  startImpersonation: async () => undefined,
  stopImpersonation: async () => undefined,
  impersonationActive: false,
  impersonatedUser: undefined,
  loading: false,
});

export const useImpersonation = (): IImpersonationState =>
  React.useContext(ImpersonationContext);

export const ImpersonationProvider: React.FC<{
  currentUser: ImpersonationProvider_User | null | undefined;
}> & { userFragment: DocumentNode } = ({ currentUser, children }) => {
  const [isActive, setIsActive] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const toast = useToast();

  React.useEffect(() => {
    const initImpersonation = async (): Promise<void> => {
      const tokens = await accountsClient.getTokens();
      const decodedToken =
        tokens?.accessToken &&
        jwt_decode<{ data: { isImpersonated: boolean } }>(tokens.accessToken);

      setIsActive(!!decodedToken && decodedToken.data.isImpersonated);
    };

    initImpersonation().catch((err) => logError(err));
  }, []);

  const startImpersonation = React.useCallback(
    async (impersonatedUserId: string) => {
      setLoading(true);
      try {
        const { user } = await accountsClient.impersonate({
          userId: impersonatedUserId,
        });
        if (!user) {
          toast({
            description: `Failed to impersonate user`,
            status: 'error',
          });
          return;
        }

        window.location.href = '/';
      } catch (err) {
        toast({
          description: `Failed to impersonate user`,
          status: 'error',
        });
        logError(err);
        setLoading(false);
      }
    },
    [toast]
  );

  const stopImpersonation = React.useCallback(async () => {
    setLoading(true);
    await accountsClient.stopImpersonation();

    const redirDest = !!currentUser?._id
      ? `/admin/membership/people/${currentUser._id}`
      : '/admin/membership/people';

    window.location.href = redirDest;
  }, [currentUser?._id]);

  return (
    <ImpersonationContext.Provider
      value={{
        impersonationActive: isActive,
        startImpersonation,
        stopImpersonation,
        loading,
        impersonatedUser: (isActive && currentUser) || undefined,
      }}
    >
      {children}
    </ImpersonationContext.Provider>
  );
};

ImpersonationProvider.userFragment = gql`
  fragment ImpersonationProvider_User on User {
    _id
    displayName
  }
`;
