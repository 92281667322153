import * as React from 'react';

import { logInfo, logWarning } from '@/imports/logging/ClientLogger';
import { accountsClient } from '@/modules/accounts/accounts-client';

const LEGACY_METEOR_LOGIN_TOKEN_KEY = 'Meteor.loginToken';

const getAccessToken = async (): Promise<string | null> => {
  const tokens = await accountsClient.getTokens();

  const legacyMeteorToken = localStorage?.getItem(
    LEGACY_METEOR_LOGIN_TOKEN_KEY
  );

  return tokens?.accessToken || legacyMeteorToken || null;
};

export const useLogoutAcrossTabs = () => {
  const previousAccessTokenRef = React.useRef<string | null>();

  React.useEffect(() => {
    const handler = async (): Promise<void> => {
      const newAccessToken = await getAccessToken();
      const previousAccessToken = previousAccessTokenRef.current;
      const hadPreviousAccessToken = !!previousAccessToken;

      const transitioningToLoggedOut =
        hadPreviousAccessToken && !newAccessToken;
      previousAccessTokenRef.current = newAccessToken;
      if (
        transitioningToLoggedOut &&
        !document.hasFocus() /* Safari fires event in same tab, do not reload active tab */
      ) {
        logInfo(`Refreshing page due to logout`);
        window.location.reload();
      }
    };

    (async () => {
      try {
        const accessToken = await getAccessToken();
        previousAccessTokenRef.current = accessToken;
      } catch (err) {
        logWarning(
          'Failed to set initial access token. Disabling logout across tabs'
        );
        window.removeEventListener('storage', handler);
      }
    })();

    window.addEventListener('storage', handler);

    return () => {
      window.removeEventListener('storage', handler);
    };
  }, []);
};
