import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
  VisuallyHidden,
} from '@chakra-ui/react';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useChatContext } from 'stream-chat-react';
import { v4 } from 'uuid';

import { useCurrent } from '@/hooks/useCurrent';

import { useInitChatUsers } from '../hooks/useInitChatUsers';
import { SearchableUserSelect } from './SearchableUserSelect';

export const MessagingCreateChannel: React.FC<Omit<ModalProps, 'children'>> = (
  props
) => {
  const current = useCurrent();
  const { client, setActiveChannel } = useChatContext();
  const { initChatUsers } = useInitChatUsers();

  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting },
  } = useForm<{
    userIds: string[];
  }>({
    defaultValues: {
      userIds: [],
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    if (!data.userIds.length || !client.userID) {
      return;
    }

    if (data.userIds.length > 1) {
      // if the desired new channel will have multiple members we first check if there is an existing channel with those members

      const potentialExistingChannels = await client.queryChannels(
        {
          type: 'messaging',
          members: [...data.userIds, client.userID],
        },
        { last_message_at: -1 }
      );

      if (potentialExistingChannels.length) {
        // if a channel already exists with those exact members we just set the
        // active channel to that channel

        setActiveChannel(potentialExistingChannels[0]);
      } else {
        // if there isn't an existing channel with those members, we create a
        // new one and give it a UUID so that it is considered a "group" chat
        // and the members of the channel can be modified (new members added,
        // existing members removed, etc)

        await initChatUsers({ variables: { memberIds: data.userIds } });
        const channelId = v4();
        const channel = client.channel('messaging', channelId, {
          members: [...data.userIds, client.userID],
          team: current.tenant?._id,
          isGroupChat: true,
        });
        await channel.watch();
        setActiveChannel(channel);
      }
    } else {
      // if the desired channel is with just a single member we assume the
      // current user is wanting to start a private channel so we create a new
      // channel without a UUID so that the channel identifier is a function of
      // the two user ids. this channel cannot have its members modified (no
      // one can join or leave this channel)

      await initChatUsers({
        variables: {
          memberIds: [...data.userIds, client.userID],
        },
      });
      const channel = client.channel('messaging', {
        members: [...data.userIds, client.userID],
        team: current.tenant?._id,
      });
      await channel.watch();
      setActiveChannel(channel);
    }

    reset({ userIds: [] });
    props.onClose();
  });

  React.useEffect(() => {
    if (!props.isOpen) {
      reset({ userIds: [] });
    }
  }, [props.isOpen, reset]);

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      size={{ base: 'full', md: 'xl' }}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create a New Channel</ModalHeader>
        <ModalCloseButton />

        <ModalBody as={Stack}>
          <Controller
            control={control}
            name="userIds"
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!fieldState.error}>
                <VisuallyHidden>
                  <FormLabel>Users</FormLabel>
                </VisuallyHidden>
                <SearchableUserSelect
                  value={field.value}
                  onChange={field.onChange}
                  exclude={field.value}
                />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
            rules={{
              validate: {
                atLeastOne: (value) => {
                  if (value.length > 0) {
                    return true;
                  }

                  return 'You must select at least one user';
                },
              },
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            colorScheme="gray"
            mr="2"
            isDisabled={isSubmitting}
            onClick={props.onClose}
          >
            Cancel
          </Button>
          <Button onClick={onSubmit} isLoading={isSubmitting}>
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
