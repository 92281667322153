import { ApolloLink } from '@apollo/client';
import omitDeep from 'omit-deep-lodash';

const stripTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = omitDeep(operation.variables, '__typename');
  }
  return forward(operation);
});

export default stripTypenameLink;
