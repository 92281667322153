import { InMemoryCache } from '@apollo/client';

import { relayStylePagination } from './pagination';
import possibleTypes from './possibleTypes.json';
import typesToMerge from './typesToMerge.json';

const inMemoryCache = new InMemoryCache({
  typePolicies: {
    ...Object.fromEntries(
      typesToMerge.map((typeName) => [typeName, { merge: true }])
    ),
    Tenant: {
      fields: {
        currentUserPermissions: {
          merge: true,
        },
      },
    },
    NavigationV2: {
      keyFields: (navigation) => {
        if (
          navigation &&
          navigation.metadata &&
          navigation.metadata.version &&
          navigation.metadata.publishedVersion &&
          navigation.metadata.version === navigation.metadata.publishedVersion
        ) {
          if (navigation.metadata.isRestrictionIgnored) {
            return `x_navigation_x/unrestricted`;
          }

          return `x_navigation_x`;
        }

        if (navigation.metadata && navigation.metadata.isRestrictionIgnored) {
          return `x_navigation_x/unrestricted/draft`;
        }

        return `x_navigation_x/draft`;
      },
      items: {
        merge(_, incoming) {
          return incoming;
        },
      },
    },
    Page: {
      keyFields: (page) => {
        if (
          page &&
          page.metadata &&
          page.metadata.version &&
          page.metadata.publishedVersion &&
          page.metadata.version === page.metadata.publishedVersion
        ) {
          return `${page._id}`;
        }

        return `${page._id}/draft`;
      },
      content: {
        merge(_, incoming) {
          return incoming;
        },
      },
    },
    SimpleEvent_Speaker: {
      keyFields: ['user', ['_id']],
    },
    SimplePost: {
      fields: {
        tags: {
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
    ConferenceEventV2: {
      fields: {
        visibility: {
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
    ConferenceSession: {
      fields: {
        speakers: {
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
    ConferenceBooth: {
      fields: {
        moderators: {
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
    SimpleEventV2: {
      fields: {
        visibility: {
          merge(_, incoming) {
            return incoming;
          },
        },
        speakers: {
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
    User: {
      fields: {
        visibleGroupLinks: {
          merge(_, incoming) {
            return incoming;
          },
        },
        userHistoryConnection: relayStylePagination(['input', ['filters']]),
      },
    },
    Organization: {
      fields: {
        rosterV2: relayStylePagination(['input', ['query']]),
      },
    },
    MembershipData: {
      merge: true,
      fields: {
        relevantMembershipTerms: {
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
    PollOption: {
      fields: {
        responses: {
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
    CustomUserAttribute: {
      fields: {
        fieldMetadataData: {
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
    IndividualMembershipType: {
      fields: {
        userAttributeConfiguration: {
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
    MembershipTermV2: {
      fields: {
        importantDates: {
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
    Query: {
      fields: {
        activityByAuthorId: {
          keyArgs: ['input', ['authorId']],
          merge(
            existing,
            incoming,
            {
              args: {
                input: { offset = 0 },
              },
            }
          ) {
            const mergedPosts =
              !!existing && !!existing.posts ? existing.posts.slice(0) : [];
            if (!!incoming.posts) {
              for (let i = 0; i < incoming.posts.length; ++i) {
                mergedPosts[offset + i] = incoming.posts[i];
              }
            }
            return { posts: mergedPosts, hasMore: incoming.hasMore };
          },
        },
        activityByAuthorIdV2: {
          keyArgs: ['input', ['authorId']],
          merge(
            existing,
            incoming,
            {
              args: {
                input: { offset = 0 },
              },
            }
          ) {
            const mergedPosts =
              !!existing && !!existing.posts ? existing.posts.slice(0) : [];
            if (!!incoming.posts) {
              for (let i = 0; i < incoming.posts.length; ++i) {
                mergedPosts[offset + i] = incoming.posts[i];
              }
            }
            return { posts: mergedPosts, hasMore: incoming.hasMore };
          },
        },
        users: {
          keyArgs: ['input', ['sort', 'filter']],
          merge(
            existing,
            incoming,
            {
              args: {
                input: { offset = 0 },
              },
            }
          ) {
            const mergedResults =
              !!existing && !!existing.results ? existing.results.slice(0) : [];
            if (!!incoming.results) {
              for (let i = 0; i < incoming.results.length; ++i) {
                mergedResults[offset + i] = incoming.results[i];
              }
            }
            const updatedResults =
              !!incoming.results &&
                mergedResults.length > offset + incoming.results.length
                ? mergedResults.slice(0, offset + incoming.results.length)
                : mergedResults;

            return { results: updatedResults, totalCount: incoming.totalCount };
          },
        },
        postFeedV2: {
          keyArgs: [
            'input',
            ['groupId', 'tagId', 'excludePinned', 'organizationId'],
          ],
          merge(existing, incoming) {
            const existingPosts =
              !!existing && !!existing.posts ? existing.posts.slice(0) : [];
            const incomingPosts =
              !!incoming && !!incoming.posts ? incoming.posts.slice(0) : [];
            if (
              !!existing &&
              !!incoming &&
              new Date(incoming.cursor) >= new Date(existing.cursor)
            ) {
              return {
                posts: incomingPosts,
                hasMore: incoming.hasMore,
                cursor: incoming.cursor,
              };
            }
            const mergedPosts = [...existingPosts, ...incomingPosts];
            return {
              posts: mergedPosts,
              hasMore: incoming.hasMore,
              cursor: incoming.cursor,
            };
          },
        },
        myDraftPosts: {
          keyArgs: false,
          merge(existing, incoming, { args }) {
            const mergedPosts =
              !!existing && !!existing.posts ? existing.posts.slice(0) : [];
            const incomingPosts =
              !!incoming && !!incoming.posts ? incoming.posts.slice(0) : [];

            if (incomingPosts) {
              mergedPosts.push(...incomingPosts);
            }
            return {
              posts: mergedPosts,
              hasMore: incoming.hasMore,
              totalCount: incoming.totalCount,
            };
          },
        },
        postFeed: {
          keyArgs: [
            'input',
            ['groupId', 'organizationId', 'tagId', 'excludePinned'],
          ],
          merge(existing, incoming) {
            const existingPosts =
              !!existing && !!existing.posts ? existing.posts.slice(0) : [];
            const incomingPosts =
              !!incoming && !!incoming.posts ? incoming.posts.slice(0) : [];
            if (
              !!existing &&
              !!incoming &&
              new Date(incoming.cursor) >= new Date(existing.cursor)
            ) {
              return {
                posts: incomingPosts,
                hasMore: incoming.hasMore,
                cursor: incoming.cursor,
              };
            }
            const mergedPosts = [...existingPosts, ...incomingPosts];
            return {
              posts: mergedPosts,
              hasMore: incoming.hasMore,
              cursor: incoming.cursor,
            };
          },
        },
        communityListeningPostFeed: {
          keyArgs: false,
          merge(existing, incoming) {
            if (
              !!existing?.results &&
              !!incoming?.results &&
              !existing?.results?.cursor &&
              !incoming?.results?.cursor
            ) {
              // fetching first page twice [first page could have no cursor]
              return incoming;
            }

            // old page coming in late, we skip it (we must already have it)
            // we could theoretically detect duplicates and sort it into place but we should already have the page given our design (see below)
            // (this is expected not to occur ever that we receive missing pages out of order, only potentially stale pages already replaced, this is given each page must load before the ability to request the next is available)
            if (
              !!existing?.results?.cursor &&
              !!incoming?.results?.cursor &&
              // reverse chronological feed, incoming cursor should be further in past or it is a stale page
              new Date(existing.results.cursor) <=
              new Date(incoming.results.cursor)
            ) {
              return incoming;
            }

            const existingPosts = !!existing?.results?.posts
              ? existing.results.posts
              : [];
            const incomingPosts = !!incoming?.results?.posts
              ? incoming.results.posts
              : [];

            const mergedPosts = [...existingPosts, ...incomingPosts];
            return {
              results: {
                posts: mergedPosts,
                hasMore: incoming?.results?.hasMore,
                cursor: incoming?.results?.cursor,
              },
            };
          },
        },
        communityListeningPostFeedV2: {
          keyArgs: false,
          merge(existing, incoming) {
            const existingPosts =
              !!existing?.results && !!existing.results.posts
                ? existing.results.posts.slice(0)
                : [];
            const incomingPosts =
              !!incoming?.results && !!incoming.results.posts
                ? incoming.results.posts.slice(0)
                : [];
            if (
              !!existing?.results &&
              !!incoming?.results &&
              new Date(incoming.results.cursor) >=
              new Date(existing.results.cursor)
            ) {
              return {
                posts: incomingPosts,
                hasMore: incoming.results.hasMore,
                cursor: incoming.results.cursor,
              };
            }
            const mergedPosts = [...existingPosts, ...incomingPosts];

            return {
              results: {
                posts: mergedPosts,
                hasMore: incoming.results.hasMore,
                cursor: incoming.results.cursor,
              },
            };
          },
        },
        groups: {
          keyArgs: [
            'input',
            ['sort', 'filter', ['_withTextSearch', ['searchText']]],
          ],
          merge(
            existing,
            incoming,
            {
              args: {
                input: { offset = 0 },
              },
            }
          ) {
            const mergedResults =
              !!existing && !!existing.results ? existing.results.slice(0) : [];
            if (!!incoming.results) {
              for (let i = 0; i < incoming.results.length; ++i) {
                mergedResults[offset + i] = incoming.results[i];
              }
            }
            return { results: mergedResults, totalCount: incoming.totalCount };
          },
        },
        topLevelGroups: {
          keyArgs: ['input', ['data', ['filter', ['searchTerm']]]],
          merge(
            existing,
            incoming,
            {
              args: {
                input: {
                  data: { offset = 0 },
                },
              },
            }
          ) {
            const mergedResults =
              !!existing && !!existing.data ? existing.data.slice(0) : [];
            if (!!incoming.data) {
              for (let i = 0; i < incoming.data.length; ++i) {
                mergedResults[offset + i] = incoming.data[i];
              }
            }
            return { data: mergedResults, totalCount: incoming.totalCount };
          },
        },
        announcementFeed: {
          keyArgs: ['input', ['groupId']],
          merge(existing, incoming) {
            const existingPosts =
              !!existing && !!existing.posts ? existing.posts.slice(0) : [];
            const incomingPosts =
              !!incoming && !!incoming.posts ? incoming.posts.slice(0) : [];
            if (
              !!existing &&
              !!incoming &&
              (incoming.cursor === null ||
                new Date(incoming.cursor) >= new Date(existing.cursor))
            ) {
              return {
                posts: incomingPosts,
                hasMore: incoming.hasMore,
                cursor: incoming.cursor,
              };
            }
            const mergedPosts = [...existingPosts, ...incomingPosts];
            return {
              posts: mergedPosts,
              hasMore: incoming.hasMore,
              cursor: incoming.cursor,
            };
          },
        },
        announcementFeedV2: {
          keyArgs: ['input', ['groupId']],
          merge(existing, incoming) {
            const existingPosts =
              !!existing && !!existing.posts ? existing.posts.slice(0) : [];
            const incomingPosts =
              !!incoming && !!incoming.posts ? incoming.posts.slice(0) : [];
            if (
              !!existing &&
              !!incoming &&
              (incoming.cursor === null ||
                new Date(incoming.cursor) >= new Date(existing.cursor))
            ) {
              return {
                posts: incomingPosts,
                hasMore: incoming.hasMore,
                cursor: incoming.cursor,
              };
            }
            const mergedPosts = [...existingPosts, ...incomingPosts];
            return {
              posts: mergedPosts,
              hasMore: incoming.hasMore,
              cursor: incoming.cursor,
            };
          },
        },
        tagsFollowedByUser: {
          keyArgs: false,
          merge(
            existing,
            incoming,
            {
              args: {
                input: { offset = 0 },
              },
            }
          ) {
            const mergedTags =
              !!existing && !!existing.tags
                ? existing.tags.slice(0, offset + incoming.tags.length)
                : [];
            if (!!incoming.tags) {
              for (let i = 0; i < incoming.tags.length; ++i) {
                mergedTags[offset + i] = incoming.tags[i];
              }
            }
            return { tags: mergedTags, totalCount: incoming.totalCount };
          },
        },
        tagsNotFollowedByUser: {
          keyArgs: false,
          merge(
            existing,
            incoming,
            {
              args: {
                input: { offset = 0 },
              },
            }
          ) {
            const mergedTags =
              !!existing && !!existing.tags
                ? existing.tags.slice(0, offset + incoming.tags.length)
                : [];
            if (!!incoming.tags) {
              for (let i = 0; i < incoming.tags.length; ++i) {
                mergedTags[offset + i] = incoming.tags[i];
              }
            }
            return { tags: mergedTags, totalCount: incoming.totalCount };
          },
        },
        events: {
          keyArgs: ['input', ['filter']],
          merge(
            existing,
            incoming,
            {
              args: {
                input: { offset = 0 },
              },
            }
          ) {
            const mergedResults =
              !!existing && !!existing.results ? existing.results.slice(0) : [];
            if (!!incoming.results) {
              for (let i = 0; i < incoming.results.length; ++i) {
                mergedResults[offset + i] = incoming.results[i];
              }
            }
            return { results: mergedResults, hasMore: incoming.hasMore };
          },
        },
        groupEventDraftFeed: {
          keyArgs: ['input', ['filter']],
          merge(
            existing,
            incoming,
            {
              args: {
                input: { offset = 0 },
              },
            }
          ) {
            const mergedResults =
              !!existing && !!existing.results ? existing.results.slice(0) : [];
            if (!!incoming.results) {
              for (let i = 0; i < incoming.results.length; ++i) {
                mergedResults[offset + i] = incoming.results[i];
              }
            }
            return { results: mergedResults, hasMore: incoming.hasMore };
          },
        },
        organizationEventDraftFeed: {
          keyArgs: ['input', ['filter', 'organizationId']],
          merge(existing, incoming) {
            return {
              ...incoming,
              events: [...(existing?.events || []), ...incoming.events],
            };
          },
        },
      },
    },
    Organization_DescriptiveResource_Image: {
      keyFields: ['imageId'],
    },
    TenantStripeCustomer: {
      keyFields: ['stripeCustomerId'],
    },
  },
  possibleTypes,
});

const cache =
  typeof window !== 'undefined'
    ? inMemoryCache.restore(window.__APOLLO_STATE__)
    : inMemoryCache;
export default cache;
