import { Image, Text, VStack } from '@chakra-ui/react';
import * as React from 'react';

export const NoTenantFound: React.FC = () => {
  return (
    <VStack p="12" spacing="4" textAlign="center">
      <Image
        src="https://tradewing-public.s3.us-east-2.amazonaws.com/tradewing-logo.png"
        alt="Tradewing"
        width="50px"
        height="50px"
      />
      <Text textStyle="xlBodyStrong">Community Not Found</Text>
      <Text>No Tradewing community exists with this name.</Text>
      <Text>
        Please check your web address and try again. If you need help locating
        your community, please contact support@tradewing.com.
      </Text>
    </VStack>
  );
};
