import { Icon, IconProps } from '@chakra-ui/react';
import * as React from 'react';

export const Navigation2Icon: React.FC<IconProps> = (props) => (
  <Icon
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <polygon points="12 2 19 21 12 17 5 21 12 2"></polygon>
  </Icon>
);
