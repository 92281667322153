import { gql } from '@apollo/client';
import { HandlerType, datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import LogRocket from 'logrocket';
import * as React from 'react';
import yn from 'yn';

import { UseDatadog_Tenant } from './graphql-types/UseDatadog_Tenant';
import { UseDatadog_User } from './graphql-types/UseDatadog_User';

const DATADOG_HTTP_LOGGING_ENABLED = yn(
  process.env.NEXT_PUBLIC_DATADOG_HTTP_LOGGING_ENABLED
);

const DATADOG_RUM_ENABLED = yn(process.env.NEXT_PUBLIC_DATADOG_RUM_ENABLED, {
  default: false,
});

const DATADOG_RUM_SESSION_REPLAY_ENABLED = yn(
  process.env.NEXT_PUBLIC_DATADOG_RUM_SESSION_REPLAY_ENABLED,
  {
    default: false,
  }
);

const DATADOG_RUM_PREMIUM_SAMPLING_RATE = process.env
  .NEXT_PUBLIC_DATADOG_RUM_PREMIUM_SAMPLING_RATE
  ? parseInt(process.env.NEXT_PUBLIC_DATADOG_RUM_PREMIUM_SAMPLING_RATE)
  : 0;

const DATADOG_RUM_APPLICATION_ID =
  process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID;
const DATADOG_RUM_CLIENT_TOKEN =
  process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN;
const DATADOG_RUM_ENV = process.env.NEXT_PUBLIC_DATADOG_RUM_ENV;
const DATADOG_RUM_SERVICE = process.env.NEXT_PUBLIC_DATADOG_RUM_SERVICE;

if (DATADOG_RUM_ENABLED) {
  if (
    !(
      DATADOG_RUM_APPLICATION_ID &&
      DATADOG_RUM_CLIENT_TOKEN &&
      DATADOG_RUM_ENV &&
      DATADOG_RUM_SERVICE
    )
  ) {
    throw new Error(
      `NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID, NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN, NEXT_PUBLIC_DATADOG_RUM_ENV, and NEXT_PUBLIC_DATADOG_RUM_SERVICE must be defined if datadog rum is enabled`
    );
  }
}

export const useDatadog = (
  currentUser: UseDatadog_User | undefined | null,
  tenant: UseDatadog_Tenant | undefined | null
) => {
  const associationCode = tenant?.associationCode;

  React.useEffect(() => {
    datadogLogs.init({
      clientToken: 'pub2961fa05ab7f48d13bc901125e2850ce',
      forwardErrorsToLogs: true,
      sampleRate: 100,
    });

    datadogLogs.logger.setHandler(
      DATADOG_HTTP_LOGGING_ENABLED ? HandlerType.http : HandlerType.console
    );
  }, []);

  React.useEffect(() => {
    if (DATADOG_RUM_ENABLED) {
      datadogRum.init({
        applicationId: DATADOG_RUM_APPLICATION_ID!,
        clientToken: DATADOG_RUM_CLIENT_TOKEN!,
        site: 'datadoghq.com',
        service: DATADOG_RUM_SERVICE,
        env: DATADOG_RUM_ENV,
        // Specify a version number to identify the deployed version of your application in Datadog
        version: process.env.NEXT_PUBLIC_CLIENT_BUNDLE_VERSION,
        sampleRate: 100,
        premiumSampleRate: DATADOG_RUM_PREMIUM_SAMPLING_RATE,
        trackInteractions: true,
      });
      LogRocket.getSessionURL((sessionUrl) =>
        datadogRum.setGlobalContextProperty('logrocket.session_url', sessionUrl)
      );
      DATADOG_RUM_SESSION_REPLAY_ENABLED &&
        datadogRum.startSessionReplayRecording();
    }
  }, []);

  React.useEffect(() => {
    datadogLogs.setLoggerGlobalContext({
      userId: currentUser?._id || undefined,
      tenant: associationCode || undefined,
      service: 'browser',
    });

    currentUser &&
      datadogRum.setUser({
        id: currentUser._id,
        name: currentUser.displayName,
        email: currentUser.email || undefined,
      });
  }, [currentUser, associationCode]);
};

useDatadog.userFragment = gql`
  fragment UseDatadog_User on User {
    _id
    displayName
    email
  }
`;

useDatadog.tenantFragment = gql`
  fragment UseDatadog_Tenant on Tenant {
    _id
    associationCode
  }
`;
