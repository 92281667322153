import { Icon, IconProps } from '@chakra-ui/react';
import * as React from 'react';

export const BookmarkIcon: React.FC<IconProps> = (props) => (
  <Icon
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
  </Icon>
);
