import { ApolloLink } from '@apollo/client';
import * as queryString from 'query-string';

const MAGIC_TICKET_LINK = 'ticketLinkId';

const magicTicketLinkLink = new ApolloLink((operation, forward) => {
  const queryParams = queryString.parse(window.location.search);
  const magicTicketLink = queryParams[MAGIC_TICKET_LINK];

  if (magicTicketLink) {
    operation.setContext(() => ({
      headers: {
        magic_ticket_link: magicTicketLink,
      },
    }));
  }

  return forward(operation);
});

export default magicTicketLinkLink;
