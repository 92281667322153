import {
  Avatar,
  Box,
  Button,
  Divider,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  StackDivider,
  Text,
  useBoolean,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import * as React from 'react';
import { UserResponse } from 'stream-chat';
import { useChannelStateContext, useChatContext } from 'stream-chat-react';

import { LogOutIcon } from '@/imports/ui/chakra/feather';
import { useChannelDetail } from '@/modules/messaging/hooks/useChannelDetail';

import { AddPeople } from './components/AddPeople';
import { ChannelNameEditor } from './components/ChannelNameEditor';

export const MessagingEditChannel: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const { channel } = useChannelStateContext();
  const { client, setActiveChannel } = useChatContext();
  const [userBeingRemoved, setUserBeingRemoved] = React.useState('');
  const channelDetail = useChannelDetail(channel);

  const remove = async (user: UserResponse) => {
    setUserBeingRemoved(user.id);

    await channel.removeMembers([user.id], {
      text: `${client.user?.name} removed ${user.name}`,
    });

    setUserBeingRemoved('');
  };

  const leave = async () => {
    if (client.user) {
      setUserBeingRemoved(client.user.id);
      await channel.removeMembers([client.user.id], {
        text: `${client.user.name} left`,
      });
      setUserBeingRemoved('');
      setActiveChannel();
    }
  };

  const members = Object.values(channel.state.members || {});

  const canEditChannelName =
    !channelDetail.isSponsorChat &&
    channelDetail.isGroupChat &&
    channelDetail.canCurrentUserAccessCommunity;

  const canRemoveOrLeave = channelDetail.isGroupChat && members.length > 2;

  const canAddNewUsers = channelDetail.isGroupChat;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Details</ModalHeader>

        <Divider borderColor="gray.300" />

        <ModalCloseButton />

        <ModalBody p="0" as={Stack} spacing="0" divider={<StackDivider />}>
          {canEditChannelName && <ChannelNameEditor />}

          <Stack spacing="4" py="4" px="6">
            <Text textStyle="bodyStrong">Members</Text>

            <Stack spacing="4" divider={<StackDivider />}>
              {members.map((member, index) => (
                <Stack key={index} direction="row" alignItems="center">
                  <Avatar
                    name={member.user?.name}
                    src={member.user?.image}
                    size="sm"
                  />
                  <Link as={NextLink} href={`/members/${member.user?.id}`}>
                    {member.user?.name}
                  </Link>
                  <Spacer />
                  {canRemoveOrLeave &&
                    (member.user?.id === client.user?.id ? (
                      <Button
                        size="sm"
                        colorScheme="gray"
                        variant="ghost"
                        onClick={leave}
                        isLoading={userBeingRemoved === client.user?.id}
                        leftIcon={<LogOutIcon />}
                      >
                        Leave
                      </Button>
                    ) : (
                      <Button
                        size="sm"
                        variant="outline"
                        colorScheme="gray"
                        onClick={() =>
                          member.user ? remove(member.user) : null
                        }
                        isLoading={userBeingRemoved === member.user?.id}
                      >
                        Remove
                      </Button>
                    ))}
                </Stack>
              ))}
            </Stack>
          </Stack>

          {canAddNewUsers && (
            <Box py="4" px="6">
              <AddPeople />
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
