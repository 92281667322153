import { gql, useQuery } from '@apollo/client';
import * as React from 'react';
import { Channel } from 'stream-chat';
import { useChatContext } from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';

import {
  ChannelHeader_Query,
  ChannelHeader_QueryVariables,
} from './graphql-types/ChannelHeader_Query';

const QUERY = gql`
  query ChannelHeader_Query($input: ChatDetail_Input!) {
    currentUser {
      _id
      canAccessCommunity
    }
    getChatDetail(input: $input) {
      sponsorChat {
        sponsorId
        sponsor {
          _id
          displayName
          admins {
            _id
            displayName
          }
          profilePicture {
            _id
            imageProxy(
              input: {
                w: 64
                h: 64
                fit: "fill"
                fill: "solid"
                fillColor: "white"
              }
            )
          }
        }
        targetUserId
        targetUser {
          _id
          displayName
          proxyProfilePictureUrl(
            input: { w: 64, h: 64, fit: "facearea", facepad: 10.0 }
          )
        }
      }
    }
  }
`;

export const useChannelDetail = (
  channel: Channel<DefaultStreamChatGenerics>
) => {
  const { client } = useChatContext();

  const query = useQuery<ChannelHeader_Query, ChannelHeader_QueryVariables>(
    QUERY,
    {
      variables: {
        input: {
          streamChannelId: channel.cid,
        },
      },
    }
  );

  const targetUser = query.data?.getChatDetail.sponsorChat?.targetUser;
  const sponsor = query.data?.getChatDetail.sponsorChat?.sponsor;

  const isCurrentUserSponsorAdmin = sponsor?.admins?.some(
    (admin) => admin._id === client.userID
  );

  const isCurrentUserTargetUser = client.userID === targetUser?._id;

  const otherAdmins = sponsor?.admins?.filter(
    (admin) => admin._id !== client.userID
  );

  const otherMembers = Object.values(channel.state.members || {}).filter(
    (member) => member.user?.id !== client.userID
  );

  const otherMembersNames = otherMembers
    .map((member) => member.user?.name || member.user?.id || 'Unnamed User')
    .join(', ');

  const name = React.useMemo(() => {
    if (isCurrentUserSponsorAdmin && targetUser) {
      return targetUser.displayName;
    }

    if (isCurrentUserTargetUser && sponsor) {
      return sponsor.displayName;
    }

    if (channel.data?.name) {
      return channel.data.name;
    }

    return otherMembersNames;
  }, [
    channel.data?.name,
    isCurrentUserSponsorAdmin,
    isCurrentUserTargetUser,
    otherMembersNames,
    sponsor,
    targetUser,
  ]);

  return {
    isLoading: query.loading,
    isCurrentUserSponsorAdmin,
    isCurrentUserTargetUser,
    canCurrentUserAccessCommunity: query.data?.currentUser?.canAccessCommunity,
    otherMembers,
    sponsor,
    targetUser,
    name,
    otherAdmins,
    isSponsorChat: !!query.data?.getChatDetail.sponsorChat,
    otherMembersNames,
    isGroupChat: !(channel.data?.id as string | undefined)?.includes(
      '!members'
    ),
  };
};
