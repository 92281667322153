import { ApolloLink } from '@apollo/client';
import * as queryString from 'query-string';

const ACTIVATION_TOKEN = 'activationToken';

const userActivationLink = new ApolloLink((operation, forward) => {
  const queryParams = queryString.parse(window.location.search);
  const activationToken = queryParams[ACTIVATION_TOKEN];

  if (activationToken) {
    operation.setContext(() => ({
      headers: {
        activation_token: activationToken,
      },
    }));
  }

  return forward(operation);
});

export default userActivationLink;
