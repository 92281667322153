import { Avatar } from '@chakra-ui/react';
import * as React from 'react';
import { AvatarProps } from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';

export const MessagingMessageListAvatar: React.FC<
  AvatarProps<DefaultStreamChatGenerics>
> = (props) => {
  return (
    <Avatar
      name={props.name}
      src={props.image ?? undefined}
      sx={{
        '.str-chat__li--middle .str-chat__message > &, .str-chat__li--top .str-chat__message > &':
          {
            visibility: 'hidden',
          },
        '.str-chat__message.str-chat__message--me > &': {
          display: 'none',
        },
      }}
      size={props.size && props.size < 32 ? 'xs' : 'sm'}
    />
  );
};
