import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Stack,
  useBoolean,
} from '@chakra-ui/react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useChannelStateContext, useChatContext } from 'stream-chat-react';

import { ReadOnly, ReadOnlyLabel, ReadOnlyValue } from '@/components/ReadOnly';
import { EditIcon } from '@/imports/ui/chakra/feather';
import { useChannelDetail } from '@/modules/messaging/hooks/useChannelDetail';

export const ChannelNameEditor: React.FC = () => {
  const { client } = useChatContext();
  const { channel } = useChannelStateContext();
  const channelDetail = useChannelDetail(channel);
  const [isEditing, setIsEditing] = useBoolean();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<{ channelName: string }>({
    defaultValues: { channelName: channelDetail.name ?? '' },
  });

  const onSubmit = handleSubmit(async (data) => {
    await channel.update(
      {
        name: data.channelName,
        team: channel.data?.team,
      },
      {
        text: `${client.user?.name} changed channel name to ${data.channelName}`,
      }
    );

    setIsEditing.off();
  });

  return (
    <Box px="6" py="4">
      {isEditing ? (
        <Stack>
          <FormControl isDisabled={isSubmitting}>
            <FormLabel>Channel Name</FormLabel>
            <Input
              autoFocus
              placeholder={channelDetail.otherMembersNames}
              {...register('channelName')}
            />
          </FormControl>
          <Stack direction="row" justifyContent="flex-end">
            <Button
              variant="outline"
              colorScheme="gray"
              size="sm"
              onClick={setIsEditing.off}
              isDisabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button size="sm" onClick={onSubmit} isLoading={isSubmitting}>
              Save
            </Button>
          </Stack>
        </Stack>
      ) : (
        <ReadOnly>
          <ReadOnlyLabel>Channel Name</ReadOnlyLabel>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <ReadOnlyValue>{channelDetail.name}</ReadOnlyValue>
            <IconButton
              size="sm"
              variant="outline"
              colorScheme="gray"
              aria-label="edit channel name"
              icon={<EditIcon />}
              onClick={setIsEditing.on}
            />
          </Stack>
        </ReadOnly>
      )}
    </Box>
  );
};
