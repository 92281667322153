import { DocumentNode, gql } from '@apollo/client';
import { Wrap } from '@chakra-ui/react';
import * as React from 'react';

import { FollowedTagsList_TagsFragment } from './graphql-types/FollowedTagsList_TagsFragment';

import { TagsListItem } from './TagsListItem';

const TAGS_FRAGMENT = gql`
  fragment FollowedTagsList_TagsFragment on Tag {
    _id
    ...TagsListItem_TagsFragment
  }
  ${TagsListItem.tagsFragment}
`;

export const FollowedTagsList: React.FC<{
  selectedTagIds: string[];
  setSelectedTagIds: React.Dispatch<React.SetStateAction<string[]>>;
  tags: FollowedTagsList_TagsFragment[];
}> & { tagsFragment: DocumentNode } = (props) => {
  const { setSelectedTagIds } = props;

  const addTag = (tag: FollowedTagsList_TagsFragment): void =>
    setSelectedTagIds((prevState) => [...prevState, tag._id]);
  const removeTag = (tag: FollowedTagsList_TagsFragment): void =>
    setSelectedTagIds((prevState) => prevState.filter((id) => id !== tag._id));
  const handleOnChange = (
    isSelected: boolean,
    tag: FollowedTagsList_TagsFragment
  ): void => (isSelected ? addTag(tag) : removeTag(tag));

  return (
    <Wrap spacing="4" height="full" overflow="scroll">
      {props.tags.map((tag: FollowedTagsList_TagsFragment) => (
        <TagsListItem
          key={tag._id}
          selected={props.selectedTagIds.includes(tag._id)}
          onChange={(isSelected) => handleOnChange(isSelected, tag)}
          tag={tag}
        />
      ))}
    </Wrap>
  );
};

FollowedTagsList.tagsFragment = TAGS_FRAGMENT;
