import { DocumentNode, gql } from '@apollo/client';
import { Box, BoxProps, Image } from '@chakra-ui/react';
import * as React from 'react';

import { ImageFallback } from '@/imports/ui/chakra/components/ImageFallback';

import { OrganizationProfileImage_OrganizationFragment } from './graphql-types/OrganizationProfileImage_OrganizationFragment';

const ORGANIZATION_FRAGMENT = gql`
  fragment OrganizationProfileImage_OrganizationFragment on Organization {
    _id
    profilePicture {
      _id
      imageProxy(
        input: {
          w: 288
          h: 288
          fit: "fill"
          fill: "solid"
          fillColor: "white"
        }
      )
    }
  }
`;

interface OrganizationProfileImageWithOrganizationFragment<T>
  extends React.ForwardRefExoticComponent<T> {
  organizationFragment: DocumentNode;
}

type OrganizationProfileImageProps = {
  organization: OrganizationProfileImage_OrganizationFragment;
} & BoxProps;

export const OrganizationProfileImage = React.forwardRef<
  HTMLDivElement,
  OrganizationProfileImageProps
>(({ organization, children, ...boxProps }, ref) => {
  return (
    <Box
      boxSize={36}
      p="2"
      boxShadow="base"
      borderRadius="lg"
      position="relative"
      bg="white"
      flexShrink="0"
      {...boxProps}
      ref={ref}
    >
      {organization.profilePicture?.imageProxy ? (
        <Image
          src={organization.profilePicture?.imageProxy || undefined}
          alt="organization"
        />
      ) : (
        <ImageFallback aspectRatio="1" borderRadius="lg" bg="gray.200" />
      )}
      {children}
    </Box>
  );
}) as OrganizationProfileImageWithOrganizationFragment<
  React.PropsWithoutRef<OrganizationProfileImageProps>
> &
  React.RefAttributes<HTMLDivElement>;

OrganizationProfileImage.displayName = 'OrganizationProfileImage';

OrganizationProfileImage.organizationFragment = ORGANIZATION_FRAGMENT;
