import { PaymentInterval } from '@/graphql-types/globalTypes';

export enum EAmplitudeEventTypes {
  pageload = 'pageload',
  login_view_loginUi = 'login view login-ui',
  login_click_continue = 'login click continue',
  login_click_resetPw = 'login click reset-pw',
  login_click_signUp = 'login click sign-up',
  login_click_login = 'login click login',
  login_outcome = 'login outcome',
  comm_view_home = 'comm view home',
  comm_click_message = 'comm click message',
  comm_click_post = 'comm click post',
  comm_click_question = 'comm click question',
  comm_click_ask = 'comm click ask',
  comm_click_answer = 'comm click answer',
  comm_click_article = 'comm click article',
  comm_click_publish = 'comm click publish',
  comm_click_poll = 'comm click poll',
  comm_click_create = 'comm click create',
  comm_click_like = 'comm click like',
  comm_click_comment = 'comm click comment',
  comm_click_launch_lead_form = 'comm click launch-lead-form',
  comm_click_submit_lead_form = 'comm click submit-lead-form',
  events_view_conference = 'events view conference',
  events_click_register = 'events click register',
  events_click_checkout = 'events click checkout',
  events_click_event = 'events click event',
  events_click_subevent = 'events click subevent',
  events_click_speakerJoin = 'events click speaker-join',
  events_click_participantJoin = 'events click participant-join',
  events_click_listenerJoin = 'events click listener-join',
  events_click_chatTab = 'events click chat-tab',
  events_click_sendChat = 'events click send-chat',
  events_click_sponsorCta = 'events click sponsor-cta',
  ams_click_pay = 'ams click pay',
}

export type ILoginOutcome =
  | 'tw-login-success'
  | 'tw-activate-success'
  | 'tw-activate-error'
  | 'sso-login-success'
  | 'sso-activate-success'
  | 'tw-login-error-missing-input'
  | 'tw-login-error-wrong-pw'
  | 'tw-login-error-wrong-email'
  | 'sso-login-token-error'
  | 'sso-activate-error';

export type ILoginView = 'wall' | 'modal' | 'activationModal';

export type IEditLocation = 'home' | 'drafts' | 'group' | 'organization';

export type IPostType =
  | 'message'
  | 'article'
  | 'poll'
  | 'question'
  | 'answer'
  | 'eventPublished';

export type IFeedLocation = 'home' | 'group' | 'organization' | 'post';

export type IPostLocation = 'general' | 'group' | 'organization' | 'event';

export type IEventType = 'conference' | 'simple';

export type IEventRole = 'speaker' | 'attendee' | 'not-registered';

export type ISubEventRole = 'speaker' | 'attendee';

export type IEventsTabView =
  | 'all-events'
  | 'upcoming-events'
  | 'past-events'
  | 'draft-events';

export type IRequestDemoView =
  | 'sponsorPage'
  | 'eventBooth'
  | 'eventSession'
  | 'eventSimpleEvent'
  | 'sponsorPageEdit'
  | 'eventAdHocMeetingRoom';

export type IReactionType = 'like' | 'upvote' | 'downvote';

export type IAnalyticsLogger = {
  logEvent_pageload: () => void;
  logEvent_login_view_loginUi: (properties: {
    view: ILoginView;
    'sso enabled': boolean;
    'signup enabled': boolean;
    'is public': boolean;
  }) => void;
  logEvent_login_click_continue: (properties: {
    view: ILoginView;
    'sso provider': string;
    'signup enabled': boolean;
  }) => void;
  logEvent_login_click_resetPw: (properties: {
    view: ILoginView;
    'sso enabled': boolean;
    'signup enabled': boolean;
  }) => void;
  logEvent_login_click_signUp: (properties: {
    view: ILoginView;
    'sso enabled': boolean;
  }) => void;
  logEvent_login_click_login: (properties: {
    view: ILoginView;
    'sso enabled': boolean;
    'signup enabled': boolean;
  }) => void;
  logEvent_login_outcome: (properties: {
    outcome: ILoginOutcome;
    view?: ILoginView;
    'sso enabled': boolean;
    'signup enabled'?: boolean;
  }) => void;
  logEvent_comm_view_home: () => void;
  logEvent_comm_click_message: () => void;
  logEvent_comm_click_post: (properties: {
    'posted in': IPostLocation;
    'added tags': boolean;
    announcement: boolean;
    'is hosted by sponsor': boolean;
  }) => void;
  logEvent_comm_click_question: () => void;
  logEvent_comm_click_ask: (properties: {
    'posted in': IPostLocation;
    'added tags': boolean;
    announcement: boolean;
    'is hosted by sponsor': boolean;
  }) => void;
  logEvent_comm_click_answer: (properties: {
    'posted in': IPostLocation;
    'added tags': boolean;
    announcement: boolean;
    'is hosted by sponsor': boolean;
  }) => void;
  logEvent_comm_click_article: () => void;
  logEvent_comm_click_publish: (properties: {
    'banner image': boolean;
    'posted in': IPostLocation;
    'added tags': boolean;
    announcement: boolean;
    'is hosted by sponsor': boolean;
  }) => void;
  logEvent_comm_click_poll: () => void;
  logEvent_comm_click_create: (properties: {
    'posted in': IPostLocation;
    'added tags': boolean;
    announcement: boolean;
    'is hosted by sponsor': boolean;
  }) => void;
  logEvent_comm_click_like: (properties: {
    'post type': IPostType;
    'posted in': IPostLocation;
    'added tags': boolean;
    announcement: boolean;
    'on post': boolean;
    'is hosted by sponsor': boolean;
    'reaction type': IReactionType;
  }) => void;
  logEvent_comm_click_comment: (properties: {
    'post type': IPostType;
    'posted in': IPostLocation;
    'added tags': boolean;
    announcement: boolean;
    'on post': boolean;
  }) => void;
  logEvent_comm_click_submit_lead_form: (properties: {
    view: IRequestDemoView;
    'sponsor id': string;
    'button text': string;
    tier?: string;
  }) => void;
  logEvent_comm_click_launch_lead_form: (properties: {
    view: IRequestDemoView;
    'sponsor id': string;
    'button text': string;
    tier?: string;
  }) => void;
  logEvent_events_view_conference: (properties: {
    'logged in': boolean;
    'event id': string;
    'register capable': boolean;
    'event start date': string;
    'sponsor number': number;
    'session number': number;
    'speaker number': number;
  }) => void;
  logEvent_events_click_register: (properties: {
    'logged in': boolean;
    'event id': string;
    'ticket options': number;
    'event start date': string;
  }) => void;
  logEvent_events_click_checkout: (properties: {
    'event id': string;
    'ticket options': number;
    'ticket price': number;
    'ticket id': string;
  }) => void;
  logEvent_events_click_event: (properties: {
    'event type': IEventType;
    'event id'?: string;
    'simpleEvent id'?: string;
    'event start date': string;
    'event role'?: IEventRole;
    'tab view': IEventsTabView;
    'is hosted by sponsor': boolean;
  }) => void;
  logEvent_events_click_subevent: () => void;
  logEvent_events_click_speakerJoin: () => void;
  logEvent_events_click_participantJoin: () => void;
  logEvent_events_click_listenerJoin: () => void;
  logEvent_events_click_chatTab: () => void;
  logEvent_events_click_sendChat: (properties: {
    'file attached': boolean;
  }) => void;
  logEvent_ams_click_pay: (properties: {
    amount: string;
    screen:
      | 'adminSiteNewMembershipCheckout'
      | 'adminSiteMembershipOrderCheckout'
      | 'memberSiteNewMembershipCheckout'
      | 'memberSiteMembershipOrderCheckout';
    paymentType:
      | 'creditCard'
      | 'other'
      | 'free'
      | 'credit'
      | 'none'
      | 'achTransfer'
      | 'invoice'
      | undefined;
    membershipType: 'individual' | 'corporate';
    shouldSavePaymentInformation: boolean;
    autoPay: boolean;
    paymentInterval: PaymentInterval;
    invoicing: 'generateInvoice' | 'payForInvoice' | undefined;
  }) => void;
  setUserId: (userId: string | null) => void;
  setUserProperties: (properties: {
    'community access'?: boolean;
    'user type'?: string;
    'vendor directory'?: boolean;
    'tenant name'?: string;
    email?: string;
    tier?: string;
    role?: string;
    membershipStatus?: string;
  }) => void;
  logUserJoinedGroup: () => void;
  logUserLeftGroup: () => void;
  logUserRegisteredForConference: () => void;
  logUserFollowedSponsor: () => void;
  logUserUnfollowedSponsor: () => void;
};
