import { gql, useMutation } from '@apollo/client';

import {
  InitChatUsers,
  InitChatUsersVariables,
} from './graphql-types/InitChatUsers';

const INIT_CHAT_USERS = gql`
  mutation InitChatUsers($memberIds: [String]!) {
    initChatUsers(memberIds: $memberIds)
  }
`;

export const useInitChatUsers = () => {
  const [initChatUsers, initChatUsersResult] = useMutation<
    InitChatUsers,
    InitChatUsersVariables
  >(INIT_CHAT_USERS);

  return { initChatUsers, initChatUsersResult };
};
