import { Icon, IconProps } from '@chakra-ui/react';
import * as React from 'react';

export const CornerRightDownIcon: React.FC<IconProps> = (props) => (
  <Icon
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <polyline points="10 15 15 20 20 15"></polyline>
    <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
  </Icon>
);
