import { gql, useQuery } from '@apollo/client';
import algoliasearch, { SearchClient } from 'algoliasearch';
import * as React from 'react';

import { useCurrent } from '@/hooks/useCurrent';

import { UseCommunityUsers_GetCommunityUsersSearchKeys } from './graphql-types/UseCommunityUsers_GetCommunityUsersSearchKeys';

export const GET_SEARCH_KEYS = gql`
  query UseCommunityUsers_GetCommunityUsersSearchKeys {
    getCommunityUsersSearchKeys {
      applicationId
      searchApiKey
      indexData {
        memberIndexName
        searchableAttributesField
        viewableAttributesField
      }
    }
  }
`;

export const useCommunityUsers = (
  skip?: boolean
): {
  loading: boolean;
  indexName?: string;
  searchClient: SearchClient | null;
  viewableAttributesField?: string;
  searchableAttributesField?: string;
} => {
  const { loading: searchKeysLoading, data } =
    useQuery<UseCommunityUsers_GetCommunityUsersSearchKeys>(GET_SEARCH_KEYS, {
      skip,
    });
  const current = useCurrent();

  const [searchClient, setSearchClient] = React.useState<SearchClient | null>(
    null
  );

  React.useEffect(() => {
    const currentApplicationId =
      data?.getCommunityUsersSearchKeys.applicationId;
    const currentSearchApiKey = data?.getCommunityUsersSearchKeys.searchApiKey;

    if (currentApplicationId && currentSearchApiKey) {
      setSearchClient(algoliasearch(currentApplicationId, currentSearchApiKey));
    }
  }, [data]);

  if (!!skip) {
    return {
      loading: false,
      indexName: undefined,
      searchClient: null,
    };
  }
  if (searchKeysLoading || current.loading || !searchClient) {
    return {
      loading: true,
      indexName: undefined,
      searchClient: null,
    };
  }

  if (!current.tenant?.associationCode) {
    throw new Error('No association code.');
  }

  if (!data) {
    throw new Error('No search keys.');
  }

  return {
    loading: false,
    indexName: data.getCommunityUsersSearchKeys.indexData.memberIndexName,
    searchClient,
    viewableAttributesField:
      data.getCommunityUsersSearchKeys.indexData.viewableAttributesField,
    searchableAttributesField:
      data.getCommunityUsersSearchKeys.indexData.searchableAttributesField,
  };
};
