import { Image, Text, VStack } from '@chakra-ui/react';
import * as React from 'react';

export const ErrorPage: React.FC = () => {
  return (
    <VStack p="12" spacing="4" textAlign="center">
      <Image
        src="https://tradewing-public.s3.us-east-2.amazonaws.com/tradewing-logo.png"
        alt="Tradewing"
        width="50px"
        height="50px"
      />
      <Text textStyle="xlBodyStrong">Unexpected Error</Text>
      <Text>We are working on a fix</Text>
    </VStack>
  );
};
