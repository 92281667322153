import { menuAnatomy } from '@chakra-ui/anatomy';
import {
  ChakraTheme,
  ThemeComponentProps,
  createMultiStyleConfigHelpers,
} from '@chakra-ui/react';

const menuHelpers = createMultiStyleConfigHelpers(menuAnatomy.keys);

export const theme = {
  colors: {
    primary: {
      50: 'blue.50',
      100: 'blue.100',
      200: 'blue.200',
      300: 'blue.300',
      400: 'blue.400',
      500: 'blue.500',
      600: 'blue.600',
      700: 'blue.700',
      800: 'blue.800',
      900: 'blue.900',
    },
  },
  textStyles: {
    xlBody: { fontSize: 'lg', lineHeight: '26px' },
    body: { fontSize: 'md', lineHeight: '6' },
    compact: { fontSize: 'sm', lineHeight: '5' },
    detail: { fontSize: 'xs', lineHeight: '5' },
    xlBodyStrong: {
      fontSize: 'lg',
      lineHeight: '26px',
      fontWeight: 'semibold',
    },
    bodyStrong: { fontSize: 'md', lineHeight: '6', fontWeight: 'semibold' },
    compactStrong: { fontSize: 'sm', lineHeight: '5', fontWeight: 'semibold' },
    detailStrong: { fontSize: 'xs', lineHeight: '5', fontWeight: 'semibold' },
  },
  fonts: {
    heading: `"Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    body: `"Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    mono: 'monospace',
  },
  styles: {
    global: {
      body: {
        background: 'gray.50',
      },
    },
  },
  components: {
    Textarea: {
      variants: {
        outline: {
          backgroundColor: 'white',
        },
      },
    },
    Input: {
      variants: {
        outline: {
          field: {
            backgroundColor: 'white',
          },
        },
      },
    },
    Tooltip: {
      baseStyle: {
        borderRadius: 'base',
      },
    },
    FormLabel: {
      baseStyle: {
        fontSize: 'sm',
        lineHeight: '5',
        fontWeight: 'semibold',
      },
    },
    Link: {
      baseStyle: {
        fontWeight: 'semibold',
        color: 'gray.800',
      },
    },
    Heading: {
      baseStyle: {
        fontWeight: 'semibold',
      },
    },
    Button: {
      baseStyle: {
        _hover: {
          textDecoration: 'none', // prevent underline when button is an "a" tag
        },
      },
      variants: {
        outline: {
          backgroundColor: 'white',
        },
      },
    },
    Radio: {
      variants: {
        attached: {
          label: {
            w: 'full',
            ml: 4,
          },
          container: {
            bg: 'white',
            padding: '4',
            flex: '1',
            ':first-of-type': {
              borderTopRadius: 'xl',
            },
            ':last-child': {
              borderBottomRadius: 'xl',
            },
          },
        },
        spaced: {
          label: {
            w: 'full',
            ml: 4,
          },
          container: {
            borderWidth: '1px',
            borderColor: 'gray.200',
            borderRadius: 'xl',
            bg: 'white',
            padding: '17px',
            flex: '1',
            _checked: {
              padding: '16px',
              borderWidth: '2px',
              borderColor: 'primary.500',
              _disabled: {
                borderColor: 'gray.200',
              },
            },
          },
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          bg: 'white',
        },
      },
      variants: {
        attached: {
          label: {
            w: 'full',
            ml: 4,
          },
          container: {
            bg: 'white',
            padding: '4',
            flex: '1',
            ':first-of-type': {
              borderTopRadius: 'xl',
            },
            ':last-child': {
              borderBottomRadius: 'xl',
            },
          },
        },
        spaced: {
          label: {
            w: 'full',
            ml: 4,
          },
          container: {
            borderWidth: '1px',
            borderColor: 'gray.200',
            borderRadius: 'xl',
            bg: 'white',
            padding: '17px',
            flex: '1',
            _checked: {
              padding: '16px',
              borderWidth: '2px',
              borderColor: 'primary.500',
              _disabled: {
                borderColor: 'gray.200',
              },
            },
          },
        },
        circle: {
          control: {
            borderRadius: 'full',
            _checked: {
              bg: 'green.300',
              borderColor: 'green.300',
              _hover: {
                borderColor: 'green.300',
              },
            },
          },
        },
      },
    },
    Alert: {
      baseStyle: {
        container: {
          alignItems: 'flex-start',
        },
      },
      variants: {
        outline: (props: ThemeComponentProps<ChakraTheme>) => ({
          container: {
            bg: `${props.colorScheme}.50`,
            border: '1px',
            borderRadius: 'md',
            borderColor: `${props.colorScheme}.500`,
          },
          icon: { color: `${props.colorScheme}.500` },
        }),
        subtle: {
          container: { borderRadius: 'md' },
        },
        solid: {
          container: { borderRadius: 'md' },
        },
      },
      defaultProps: {
        variant: 'outline',
      },
    },
    Tabs: {
      baseStyle: {
        tabpanel: {
          p: 0,
        },
      },
      variants: {
        line: (props: ThemeComponentProps<ChakraTheme>) => ({
          tablist: {
            borderBottomColor: 'transparent',
          },
          tab: {
            py: 4,
            color: 'gray.600',
            _selected: {
              fontWeight: 'semibold',
              color: 'gray.800',
              borderColor: `${props.colorScheme}.500`,
            },
          },
        }),
      },
    },
    Table: {
      sizes: {
        sm: {
          th: {
            px: 2,
            py: 2.5,
            '&:first-of-type': {
              pl: 4,
            },
            '&:last-of-type': {
              pr: 4,
            },
            verticalAlign: 'bottom',
          },
          td: {
            px: 2,
            py: 2.5,
            '&:first-of-type': {
              pl: 4,
            },
            '&:last-of-type': {
              pr: 4,
            },
          },
        },
      },
      variants: {
        simple: {
          tr: {
            ':last-child': {
              td: {
                borderBottomColor: 'transparent',
              },
            },
          },
        },
        standard: {
          thead: { bg: 'gray.100' },
          th: {
            fontSize: 'xs',
            verticalAlign: 'bottom',
          },
          tr: {
            ':not(:last-child)': {
              borderBottom: 'solid',
              borderBottomColor: 'gray.100',
              borderBottomWidth: '1px',
            },
          },
          td: {
            fontSize: 'sm',
            lineHeight: '5',
          },
        },
        ghost: {
          thead: {
            borderBottom: 'solid',
            borderBottomColor: 'gray.100',
            borderBottomWidth: '1px',
          },
          th: {
            fontSize: 'xs',
            py: 4,
            verticalAlign: 'bottom',
          },
          tr: {
            ':not(:last-child)': {
              borderBottom: 'solid',
              borderBottomColor: 'gray.100',
              borderBottomWidth: '1px',
            },
          },
          td: {
            fontSize: 'sm',
            lineHeight: '5',
          },
        },
      },
      defaultProps: {
        variant: 'standard',
      },
    },
    Menu: menuHelpers.defineMultiStyleConfig({
      baseStyle: {
        list: {
          p: 2,
          borderRadius: 'xl',
        },
        item: {
          borderRadius: 'xl',
          textStyle: 'bodyStrong',
          borderColor: 'white',
          borderWidth: '1px',
          py: 2,
          px: 4,
        },
        groupTitle: {
          color: 'gray.500',
          fontSize: 'sm',
          fontWeight: 'semibold',
          mx: 2,
          textTransform: 'uppercase',
        },
      },
      sizes: {
        xs: {
          item: {
            py: 0,
            px: 2,
            borderRadius: 'lg',
            fontSize: 'xs',
          },
        },
      },
    }),
    Accordion: {
      variants: {
        baguette: {
          container: {
            borderTopWidth: 0,
            _last: {
              borderBottomWidth: 0,
            },
          },
          button: {
            px: 0,
            py: 0,
            _hover: {
              bg: 'none',
            },
          },
          panel: {
            px: 0,
          },
        },
      },
    },
    Popover: {
      baseStyle: {
        popper: {
          /** Will always be in front of modal overlays */
          zIndex: 1500,
        },
      },
    },
  },
};
