import { HandlerType, datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import yn from 'yn';

/**
 * Note that this probably isn't the best way to do this, but
 * I'm not going to spend the time figuring it out right now.
 */

const DATADOG_HTTP_LOGGING_ENABLED = yn(
  process.env.NEXT_PUBLIC_DATADOG_HTTP_LOGGING_ENABLED
);

export const logErrorAndAddToRum = (
  error: Error,
  context?: Record<string, any>
) => {
  datadogRum.addError(error);
  logError(error, context);
};

export const logError = (error: Error, context?: Record<string, any>) => {
  datadogLogs.logger.log(
    error.message,
    {
      error: {
        stack: error.stack,
      },
      ...context,
    },
    'error'
  );
};

export const logInfo = (message: string, context?: Record<string, any>) =>
  datadogLogs.logger.log(message, context, 'info');

export const logWarning = (message: string) =>
  datadogLogs.logger.log(message, undefined, 'warn');

export const logNotificationError = (error: Error): void => {
  const notificationsLogger = datadogLogs.createLogger('notificationsLogger', {
    handler: DATADOG_HTTP_LOGGING_ENABLED
      ? HandlerType.http
      : HandlerType.console,
  });
  notificationsLogger.error(error.message, {
    error: {
      stack: error.stack,
    },
    module: 'notifications',
  });
};

export const logNotificationInfo = (message: string) => {
  const notificationsLogger = datadogLogs.createLogger('notificationsLogger', {
    handler: DATADOG_HTTP_LOGGING_ENABLED
      ? HandlerType.http
      : HandlerType.console,
  });
  notificationsLogger.info(message, { module: 'notifications' });
};

export const logNotificationWarning = (message: string) => {
  const notificationsLogger = datadogLogs.createLogger('notificationsLogger', {
    handler: DATADOG_HTTP_LOGGING_ENABLED
      ? HandlerType.http
      : HandlerType.console,
  });
  notificationsLogger.warn(message, { module: 'notifications' });
};
