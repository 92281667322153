import { DocumentNode, gql, useMutation } from '@apollo/client';
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import * as React from 'react';

import { logError } from '@/imports/logging/ClientLogger';

import { ActivationModal_AllGroupsFragment } from './graphql-types/ActivationModal_AllGroupsFragment';
import { ActivationModal_AllTagsFragment } from './graphql-types/ActivationModal_AllTagsFragment';
import { ActivationModal_CurrentUserFragment } from './graphql-types/ActivationModal_CurrentUserFragment';
import { ActivationModal_FollowedTagsFragment } from './graphql-types/ActivationModal_FollowedTagsFragment';
import { ActivationModal_Skip } from './graphql-types/ActivationModal_Skip';
import { ActivationModal_SponsorsFragment } from './graphql-types/ActivationModal_SponsorsFragment';
import { ActivationModal_TenantFragment } from './graphql-types/ActivationModal_TenantFragment';

import { CurrentStep } from './components/CurrentStep';
import { Title } from './components/Title';

const CURRENT_USER_FRAGMENT = gql`
  fragment ActivationModal_CurrentUserFragment on User {
    _id
    firstName
    membershipData {
      currentMembershipTerm {
        _id
        membershipType {
          _id
          name
        }
      }
    }
    ...CurrentStep_CurrentUserFragment
  }
  ${CurrentStep.currentUserFragment}
`;

const ALL_GROUPS_FRAGMENT = gql`
  fragment ActivationModal_AllGroupsFragment on Group {
    _id
    ...CurrentStep_AllGroupsFragment
  }
  ${CurrentStep.allGroupsFragment}
`;

const FOLLOWED_TAGS_FRAGMENT = gql`
  fragment ActivationModal_FollowedTagsFragment on Tag {
    _id
    ...CurrentStep_FollowedTagsFragment
  }
  ${CurrentStep.followedTagsFragment}
`;

const ALL_TAGS_FRAGMENT = gql`
  fragment ActivationModal_AllTagsFragment on Tag {
    _id
    ...CurrentStep_AllTagsFragment
  }
  ${CurrentStep.allTagsFragment}
`;

const TENANT_FRAGMENT = gql`
  fragment ActivationModal_TenantFragment on Tenant {
    _id
    acronym
    displayName
    ...CurrentStep_TenantFragment
  }
  ${CurrentStep.tenantFragment}
`;

const SPONSORS_FRAGMENT = gql`
  fragment ActivationModal_SponsorsFragment on Organization {
    _id
    ...CurrentStep_SponsorsFragment
  }
  ${CurrentStep.allSponsorsFragment}
`;

const SKIP = gql`
  mutation ActivationModal_Skip {
    skipOnboardingFlow {
      user {
        _id
      }
    }
  }
`;

export const ActivationModal: React.FC<{
  isCloseDisabled?: boolean;
  /* only show the purple box on phones on the first
     step of the activation modal flow */
  isFirstStepOfFlow: boolean;
  currentUser: ActivationModal_CurrentUserFragment;
  allGroups: ActivationModal_AllGroupsFragment[];
  followedTags: ActivationModal_FollowedTagsFragment[];
  allTags: ActivationModal_AllTagsFragment[];
  tenant: ActivationModal_TenantFragment;
  allSponsors: ActivationModal_SponsorsFragment[];
}> & {
  currentUserFragment: DocumentNode;
  allGroupsFragment: DocumentNode;
  followedTagsFragment: DocumentNode;
  allTagsFragment: DocumentNode;
  tenantFragment: DocumentNode;
  allSponsorsFragment: DocumentNode;
} = ({
  currentUser,
  allGroups,
  isFirstStepOfFlow,
  allTags,
  followedTags,
  tenant,
  allSponsors,
  isCloseDisabled,
}) => {
  const toast = useToast();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const [skipFlowFunc] = useMutation<ActivationModal_Skip>(SKIP);

  /**
   * View Event Handlers
   */

  const handleOnClose = async (): Promise<void> => {
    try {
      const { data } = await skipFlowFunc();

      if (!data?.skipOnboardingFlow?.user) {
        throw Error(
          'Failed to return user from skip onboarding flow mutation.'
        );
      }

      onClose();
    } catch (err) {
      toast({
        title: 'Failed to skip onboarding flow.',
        status: 'error',
      });
      logError(err);
    }
  };

  /* Change the modal X color when it's against the branded color */
  const closeModalButtonColorForBrandedBackground = isFirstStepOfFlow
    ? ['white', 'white', 'black']
    : ['black'];

  const displaySidebarAsModalTopInSmallScreens = isFirstStepOfFlow
    ? ['flex']
    : ['none', 'none', 'flex'];

  return (
    <Modal
      isOpen={isOpen}
      size={{ base: 'full', md: '3xl' }}
      isCentered
      onClose={handleOnClose}
      scrollBehavior="inside"
      closeOnOverlayClick={!isCloseDisabled}
      // Exception where we prevent focus to be inside the modal on
      // the TruncatedRichText See more button
      trapFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody
          display="flex"
          flexDirection={['column', null, 'row']}
          p="0"
          maxH={[window.innerHeight, null, 'xl']}
          bg="white"
          borderRadius={['none', null, 'md']}
        >
          <Box
            maxW={['container.full', null, 'xs']}
            minW={['container.full', null, 'xs']}
            px="6"
            py="8"
            background="primary.500"
            display={displaySidebarAsModalTopInSmallScreens}
            flexDirection="column"
          >
            <Title
              userName={currentUser.firstName}
              tenantName={tenant.acronym || tenant.displayName}
            />
          </Box>
          {!isCloseDisabled && (
            <ModalCloseButton
              color={closeModalButtonColorForBrandedBackground}
            />
          )}
          <CurrentStep
            currentUser={currentUser}
            allGroups={allGroups}
            followedTags={followedTags}
            allTags={allTags}
            tenant={tenant}
            allSponsors={allSponsors}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

ActivationModal.currentUserFragment = CURRENT_USER_FRAGMENT;
ActivationModal.allGroupsFragment = ALL_GROUPS_FRAGMENT;
ActivationModal.followedTagsFragment = FOLLOWED_TAGS_FRAGMENT;
ActivationModal.allTagsFragment = ALL_TAGS_FRAGMENT;
ActivationModal.tenantFragment = TENANT_FRAGMENT;
ActivationModal.allSponsorsFragment = SPONSORS_FRAGMENT;

export default ActivationModal;
