import { MenuItem, MenuList } from '@chakra-ui/react';
import React from 'react';
import {
  CustomMessageActions,
  MESSAGE_ACTIONS,
  MessageContextValue,
  StreamMessage,
  useChannelActionContext,
  useMessageContext,
  useTranslationContext,
} from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';

export type CustomMessageActionsType<
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
> = {
  customMessageActions: CustomMessageActions<StreamChatGenerics>;
  message: StreamMessage<StreamChatGenerics>;
};

// const CustomMessageActionsList = <
//   StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
// >(
//   props: CustomMessageActionsType<StreamChatGenerics>
// ) => {
//   const { customMessageActions, message } = props;
//   const customActionsArray = Object.keys(customMessageActions);

//   return (
//     <>
//       {customActionsArray.map((customAction) => {
//         const customHandler = customMessageActions[customAction];

//         return (
//           <button
//             aria-selected="false"
//             className="str-chat__message-actions-list-item str-chat__message-actions-list-item-button"
//             key={customAction}
//             onClick={(event) => customHandler(message, event)}
//             role="option"
//           >
//             {customAction}
//           </button>
//         );
//       })}
//     </>
//   );
// };

type PropsDrilledToMessageActionsBox =
  | 'getMessageActions'
  | 'handleDelete'
  | 'handleEdit'
  | 'handleFlag'
  | 'handleMute'
  | 'handlePin';

export type MessageActionsBoxProps<
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
> = Pick<
  MessageContextValue<StreamChatGenerics>,
  PropsDrilledToMessageActionsBox
> & {
  isUserMuted: () => boolean;
  mine: boolean;
  open: boolean;
};

const UnMemoizedMessageActionsBox = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(
  props: MessageActionsBoxProps<StreamChatGenerics>
) => {
  const {
    getMessageActions,
    handleDelete,
    handleEdit,
    handleFlag,
    handleMute,
    handlePin,
    isUserMuted,
  } = props;

  const { setQuotedMessage } =
    useChannelActionContext<StreamChatGenerics>('MessageActionsBox');
  const { message } =
    useMessageContext<StreamChatGenerics>('MessageActionsBox');

  const { t } = useTranslationContext('MessageActionsBox');

  const messageActions = getMessageActions();

  const handleQuote = () => {
    setQuotedMessage(message);

    const elements = message.parent_id
      ? document.querySelectorAll(
          '.str-chat__thread .str-chat__textarea__textarea'
        )
      : document.getElementsByClassName('str-chat__textarea__textarea');
    const textarea = elements.item(0);

    if (textarea instanceof HTMLTextAreaElement) {
      textarea.focus();
    }
  };

  return (
    <MenuList>
      {messageActions.indexOf(MESSAGE_ACTIONS.quote) > -1 && (
        <MenuItem onClick={handleQuote}>{t<string>('Reply')}</MenuItem>
      )}
      {messageActions.indexOf(MESSAGE_ACTIONS.pin) > -1 &&
        !message.parent_id && (
          <MenuItem onClick={handlePin}>
            {!message.pinned ? t<string>('Pin') : t<string>('Unpin')}
          </MenuItem>
        )}
      {messageActions.indexOf(MESSAGE_ACTIONS.flag) > -1 && (
        <MenuItem onClick={handleFlag}>{t<string>('Flag')}</MenuItem>
      )}
      {messageActions.indexOf(MESSAGE_ACTIONS.mute) > -1 && (
        <MenuItem onClick={handleMute}>
          {isUserMuted() ? t<string>('Unmute') : t<string>('Mute')}
        </MenuItem>
      )}
      {messageActions.indexOf(MESSAGE_ACTIONS.edit) > -1 && (
        <MenuItem onClick={handleEdit}>{t<string>('Edit Message')}</MenuItem>
      )}
      {messageActions.indexOf(MESSAGE_ACTIONS.delete) > -1 && (
        <MenuItem onClick={handleDelete}>{t<string>('Delete')}</MenuItem>
      )}
    </MenuList>
  );
};

/**
 * A popup box that displays the available actions on a message, such as edit, delete, pin, etc.
 */
export const TradewingMessageActionsBox = React.memo(
  UnMemoizedMessageActionsBox
) as typeof UnMemoizedMessageActionsBox;
