import { onError } from '@apollo/client/link/error';
import Cookies from 'universal-cookie';

import { logWarning } from '@/imports/logging/ClientLogger';
import { accountsClient } from '@/modules/accounts/accounts-client';
import {
  ACCESS_TOKEN_COOKIE_KEY,
  REFRESH_TOKEN_COOKIE_KEY,
} from '@/modules/accounts/tokenStorage';

const clearAuthLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message }) => {
      checkForInvalidSessionAndLogout(message); // intentionally not awaited
    });
  }
  if (networkError) {
    checkForInvalidSessionAndLogout(networkError.message); // intentionally not awaited
  }
});

// error message substrings
/**
I would like to be able to handle AccountsJsErrors (from `@accounts/server/lib/utils/accounts-error.d.ts`) and match the code to the codes defined in `@accounts/server/lib/errors.d.ts` . This would be cleaner and safer.
The client can import those, they are available in the client -- however, the AccountsJsError gets transformed to a normal INTERNAL_SERVER_ERROR with the message wrapped.

If the error code were preserved, one could do something like
```
ResumeSessionErrors.InvalidSession.toString() === code ||
    ResumeSessionErrors.InvalidToken.toString() === code ||
    ResumeSessionErrors.SessionNotFound.toString() === code ||
    RefreshTokensErrors.InvalidSession.toString() === code ||
    RefreshTokensErrors.InvalidTokens.toString() === code ||
    RefreshTokensErrors.SessionNotFound.toString() === code
```
as the check.

But it isn't. The messages do make it as substrings, from `@accounts/server/src/accounts-server.ts` .
*/
// common
const INVALID_SESSION = 'Invalid Session';
const SESSION_NO_LONGER_VALID = 'Session is no longer valid';
const SESSION_NOT_FOUND = 'Session not found';

// not produced yet but expected to possibly occur.
const TOKENS_INVALID = 'Tokens are not valid';
const IMPERSONATE_FAIL = 'Session is not valid for user';
const USER_NOT_FOUND = 'User not found'; // this will occur
const IMPERSONATED_USER_NOT_FOUND = 'Impersonated user not found';
const TOKENS_INVALID_2 = 'An accessToken and refreshToken are required';
const INVALID_ACCESS_TOKEN = 'An accessToken is required';

const checkForInvalidSessionAndLogout = async function (
  message: string
): Promise<void> {
  if (
    message.indexOf(INVALID_SESSION) !== -1 ||
    message.indexOf(SESSION_NO_LONGER_VALID) !== -1 ||
    message.indexOf(SESSION_NOT_FOUND) !== -1 ||
    message.indexOf(TOKENS_INVALID) !== -1 ||
    message.indexOf(IMPERSONATE_FAIL) !== -1 ||
    message.indexOf(USER_NOT_FOUND) !== -1 ||
    message.indexOf(IMPERSONATED_USER_NOT_FOUND) !== -1 ||
    message.indexOf(TOKENS_INVALID_2) !== -1 ||
    message.indexOf(INVALID_ACCESS_TOKEN) !== -1 // ||
    //message.indexOf('No current user') !== -1 // overkill and client bugs can cause logouts if we trigger on this.
  ) {
    // we remove these before calling `accountsClient.logout()` because currently that call makes a logout mutation call which fails when there is an invalid session and that is not handled.
    // see https://github.com/TradeWing/tradewing-client/pull/415#discussion_r734879578 for more details
    const cookie = new Cookies();
    cookie.remove(ACCESS_TOKEN_COOKIE_KEY);
    cookie.remove(REFRESH_TOKEN_COOKIE_KEY);
    localStorage && localStorage.removeItem(ACCESS_TOKEN_COOKIE_KEY); // local storage key and cookie key are kept same
    localStorage && localStorage.removeItem(REFRESH_TOKEN_COOKIE_KEY);

    const currentLoggedInUser = await accountsClient.getUser();
    logWarning(
      `Received invalid session error '${message}' for user ${currentLoggedInUser.id} - logging out`
    );
    if (currentLoggedInUser) {
      await accountsClient.logout();
      window.location.reload();
    }
  }
};

export default clearAuthLink;
