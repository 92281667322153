import { Box, BoxProps } from '@chakra-ui/react';
import { defineStyle } from '@chakra-ui/styled-system';
import * as React from 'react';

// TODO figure out if we can get our chakra heading styles from the theme
export const prose = {
  ul: {
    my: 4,
    ml: '1em',
  },
  ol: {
    my: 4,
    ml: '1em',
  },
  // matches chakra heading lg
  h1: defineStyle({
    fontSize: ['2xl', null, '3xl'],
    lineHeight: [1.33, null, 1.2],
    fontWeight: 'semibold',
  }),
  // matches chakra heading md
  h2: defineStyle({
    fontSize: 'xl',
    lineHeight: 1.2,
    fontWeight: 'semibold',
  }),
  // matches chakra heading sm
  h3: defineStyle({
    fontSize: 'md',
    lineHeight: 1.2,
    fontWeight: 'semibold',
  }),
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'black',
  },
  blockquote: {
    color: 'gray.700',
    borderLeft: '3px solid',
    fontStyle: 'italic',
    paddingLeft: '16px',
    fontSize: '20px',
    lineHeight: '28px',
  },
};

export const Prose = React.forwardRef<HTMLDivElement, BoxProps>(
  ({ children, ...rest }, ref) => {
    return (
      <Box color="inherit" sx={prose} {...rest} ref={ref}>
        {children}
      </Box>
    );
  }
);

Prose.displayName = 'Prose';
