import { gql, useQuery } from '@apollo/client';
import * as React from 'react';

import { MembershipSuccessModal } from '@/components/CommunityFeed/components/MembershipSuccessModal';
import { CurrentProvider } from '@/hooks/useCurrent/useCurrent';
import { ActivationModalManager } from '@/imports/ui/components/ActivationModal/ActivationModalManager';
import { SearchProvider } from '@/imports/ui/components/search/SearchProvider';
import { StreamFeedProvider } from '@/imports/ui/notifications';
import { ImpersonationProvider } from '@/modules/accounts/impersonation';
import { MessagingProvider } from '@/modules/messaging';
import { AnalyticsSetup } from '@/providers/AnalyticsSetup';
import { DynamicThemeProvider } from '@/providers/DynamicThemeProvider';
import { AnalyticsProvider } from '@/providers/contexts/AnalyticsContext';

import { Tradewing_Query } from './graphql-types/Tradewing_Query';

import { useDatadog } from './hooks/useDatadog';
import { useLogRocket } from './hooks/useLogRocket';
import { useLogoutAcrossTabs } from './hooks/useLogoutAcrossTabs';
import { usePendo } from './hooks/usePendo';
import { useSetPasswordRedirect } from './hooks/useSetPasswordRedirect';

const QUERY = gql`
  query Tradewing_Query {
    # this "getStripeKeys" needs to go here in order for <StripeElements/> to work properly
    getStripeKeys {
      result {
        publicKey
        connectedAccountId
      }
    }
    getLogRocketApiKey {
      key
    }
    getUserStreamNotificationConfig {
      config {
        notificationReadToken
        notificationFeedGroupName
        notificationFeedName
        notificationFeedApiKey
        notificationFeedAppId
        notificationFeedStartTime
      }
    }
    currentUser {
      _id
      ...UseDatadog_User
      ...UsePendo_User
      ...UseLogRocket_User
      ...UseSetPasswordRedirect_User
      ...MessagingProvider_User
      ...ImpersonationProvider_User
      ...CurrentProvider_UserFragment
    }
    tenant {
      result {
        _id
        ...UseDatadog_Tenant
        ...UsePendo_Tenant
        ...UseLogRocket_Tenant
        ...CurrentProvider_TenantFragment
      }
    }
  }
  ${useDatadog.userFragment}
  ${usePendo.userFragment}
  ${useLogRocket.userFragment}
  ${useSetPasswordRedirect.userFragment}
  ${MessagingProvider.userFragment}
  ${ImpersonationProvider.userFragment}
  ${useDatadog.tenantFragment}
  ${usePendo.tenantFragment}
  ${useLogRocket.tenantFragment}
  ${CurrentProvider.userFragment}
  ${CurrentProvider.tenantFragment}
`;

export const Tradewing: React.FC<{
  primaryColor: string | null | undefined;
}> = (props) => {
  const query = useQuery<Tradewing_Query>(QUERY, {
    notifyOnNetworkStatusChange: true,
  });

  useDatadog(query.data?.currentUser, query.data?.tenant.result);
  usePendo(query.data?.currentUser, query.data?.tenant.result);
  useLogoutAcrossTabs();
  useLogRocket(
    query.data?.getLogRocketApiKey?.key,
    query.data?.currentUser,
    query.data?.tenant.result
  );
  useSetPasswordRedirect(query.data?.currentUser);

  return (
    <CurrentProvider
      loading={query.loading}
      user={query.data?.currentUser}
      tenant={query.data?.tenant.result}
    >
      <AnalyticsProvider>
        <AnalyticsSetup>
          <DynamicThemeProvider primaryColor={props.primaryColor}>
            <MessagingProvider currentUser={query.data?.currentUser}>
              <StreamFeedProvider
                config={query.data?.getUserStreamNotificationConfig?.config}
              >
                <SearchProvider isAuthenticated={!!query.data?.currentUser}>
                  <ImpersonationProvider currentUser={query.data?.currentUser}>
                    <ActivationModalManager />
                    <MembershipSuccessModal />
                    {props.children}
                  </ImpersonationProvider>
                </SearchProvider>
              </StreamFeedProvider>
            </MessagingProvider>
          </DynamicThemeProvider>
        </AnalyticsSetup>
      </AnalyticsProvider>
    </CurrentProvider>
  );
};
