import { IconButton } from '@chakra-ui/react';
import React from 'react';
import {
  ActionsIcon as DefaultActionsIcon,
  ReactionIcon as DefaultReactionIcon,
  ThreadIcon as DefaultThreadIcon,
  MESSAGE_ACTIONS,
  MessageContextValue,
  showMessageActionsBox,
  useMessageContext,
} from 'stream-chat-react';
import {
  DefaultStreamChatGenerics,
  IconProps,
} from 'stream-chat-react/dist/types/types';

import { CornerUpLeftIcon, SmileIcon } from '@/imports/ui/chakra/feather';

import { TradewingMessageActions } from './TradewingMessageActions';

export type MessageOptionsProps<
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
> = Partial<
  Pick<MessageContextValue<StreamChatGenerics>, 'handleOpenThread'>
> & {
  /* Custom component rendering the icon used in message actions button. This button invokes the message actions menu. */
  ActionsIcon?: React.ComponentType<IconProps>;
  /* If true, show the `ThreadIcon` and enable navigation into a `Thread` component. */
  displayReplies?: boolean;
  /* React mutable ref that can be placed on the message root `div` of MessageActions component */
  messageWrapperRef?: React.RefObject<HTMLDivElement>;
  /* Custom component rendering the icon used in a button invoking reactions selector for a given message. */
  ReactionIcon?: React.ComponentType<IconProps>;
  /* Theme string to be added to CSS class names. */
  theme?: string;
  /* Custom component rendering the icon used in a message options button opening thread */
  ThreadIcon?: React.ComponentType<IconProps>;
};

const UnMemoizedMessageOptions = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(
  props: MessageOptionsProps<StreamChatGenerics>
) => {
  const {
    ActionsIcon = DefaultActionsIcon,
    displayReplies = true,
    handleOpenThread: propHandleOpenThread,
    messageWrapperRef,
    ReactionIcon = DefaultReactionIcon,
    theme = 'simple',
    ThreadIcon = DefaultThreadIcon,
  } = props;

  const {
    customMessageActions,
    getMessageActions,
    handleOpenThread: contextHandleOpenThread,
    initialMessage,
    message,
    onReactionListClick,
    threadList,
  } = useMessageContext<StreamChatGenerics>('MessageOptions');

  const handleOpenThread = propHandleOpenThread || contextHandleOpenThread;

  const messageActions = getMessageActions();
  const showActionsBox =
    showMessageActionsBox(messageActions, threadList) || !!customMessageActions;

  const shouldShowReactions =
    messageActions.indexOf(MESSAGE_ACTIONS.react) > -1;
  const shouldShowReplies =
    messageActions.indexOf(MESSAGE_ACTIONS.reply) > -1 &&
    displayReplies &&
    !threadList;

  if (
    !message.type ||
    message.type === 'error' ||
    message.type === 'system' ||
    message.type === 'ephemeral' ||
    message.status === 'failed' ||
    message.status === 'sending' ||
    initialMessage
  ) {
    return null;
  }

  const rootClassName = `str-chat__message-${theme}__actions str-chat__message-options`;

  return (
    <div className={rootClassName} data-testid="message-options">
      {showActionsBox && (
        <TradewingMessageActions
          ActionsIcon={ActionsIcon}
          messageWrapperRef={messageWrapperRef}
        />
      )}
      {shouldShowReplies && (
        <IconButton
          aria-label="Open Thread"
          icon={<CornerUpLeftIcon />}
          onClick={handleOpenThread}
          size="xs"
          variant="ghost"
          colorScheme="gray"
        />
      )}
      {shouldShowReactions && (
        <IconButton
          aria-label="Open Reaction Selector"
          icon={<SmileIcon />}
          onClick={onReactionListClick}
          size="xs"
          variant="ghost"
          colorScheme="gray"
        />
      )}
    </div>
  );
};

export const TradewingMessageOptions = React.memo(
  UnMemoizedMessageOptions
) as typeof UnMemoizedMessageOptions;
