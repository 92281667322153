import qs from 'qs';
import * as React from 'react';

import { logError } from '@/imports/logging/ClientLogger';

type IPreviewData = {
  type: string;
  url: string;
  provider_name: string;
  provider_url: string;
  title: string;
  description: string;
  author_name: string;
  author_url: string;
  html: string;
  width: number;
  height: number;
  thumbnail_url: string;
  thumbnail_width: number;
  thumbnail_height: number;
  version: string;
  error_code: number;
  error_message: string;
};

const EMBEDLY_PUBLIC_API_KEY = 'a18d84092e6a46f19ba0b9f31e76b604';

export const useVideoPreview = (src?: string): IPreviewData | null => {
  const [previewData, setPreviewData] = React.useState<IPreviewData>();
  const url = src;

  React.useEffect(() => {
    // https://dmitripavlutin.com/javascript-fetch-async-await/#4-canceling-a-fetch-request
    const controller = new AbortController();

    if (url) {
      const getData = async (): Promise<void> => {
        const queryParams = qs.stringify({
          url,
          key: EMBEDLY_PUBLIC_API_KEY,
        });
        const body = await fetch(
          `https://api.embedly.com/1/oembed?${queryParams}`,
          {
            signal: controller.signal,
          }
        );
        const data = await body.json();

        setPreviewData(data);
      };
      getData().catch((err) => logError(err));
    }

    return () => controller?.abort();
  }, [url]);

  if (!src || !previewData || previewData.error_code) {
    return null;
  }

  return previewData;
};
