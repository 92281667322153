import { WebSocketLink } from '@apollo/client/link/ws';

import { accountsClient } from '@/modules/accounts/accounts-client';

import { TENANT_DOMAIN_HEADER_KEY } from './tenant-header-link';

if (!process.env.NEXT_PUBLIC_APOLLO_WS_URI) {
  throw new Error('process.env.NEXT_PUBLIC_APOLLO_WS_URI is required.');
}
const wsLink2 =
  typeof window !== 'undefined'
    ? new WebSocketLink({
        uri: process.env.NEXT_PUBLIC_APOLLO_WS_URI,
        options: {
          lazy: true,
          minTimeout: 5000,
          timeout: 60000,
          reconnect: true,
          connectionParams: async () => {
            const tenantDomain = window.location.hostname;
            const tokens = await accountsClient.refreshSession();
            return {
              [TENANT_DOMAIN_HEADER_KEY]: tenantDomain,
              accountsLoginToken: !!tokens?.accessToken
                ? tokens.accessToken
                : undefined,
            };
          },
        },
      })
    : null;

export default wsLink2;
