import { ECommunityAccessConfig, EUserRole } from '@/graphql-types/globalTypes';

import { useCurrent } from '../useCurrent';

export const usePermissions = (): {
  isLoading: boolean;
  isAuthenticated: boolean;
  canDoAdminStuffAndAuthenticated: boolean;
  canDoMorgothStuffAndAuthenticated: boolean;
  canAccessCommunityAndGroupFeed: boolean;
  canAccessDmPageAndAuthenticated: boolean;
  canAccessEventsFeed: boolean;
  canManageOrganizationProfileAndAuthenticated: boolean;
  canAccessCommunityAndAuthenticated: boolean;
} => {
  const current = useCurrent();

  return {
    isLoading: current.loading,
    isAuthenticated: !!current.user?._id,
    canDoAdminStuffAndAuthenticated:
      current.user?.role === EUserRole.ADMIN ||
      current.user?.role === EUserRole.MORGOTH,
    canDoMorgothStuffAndAuthenticated: current.user?.role === EUserRole.MORGOTH,
    canAccessCommunityAndGroupFeed: current.tenant?.communityAccessConfig
      ? {
          [ECommunityAccessConfig.PUBLIC]:
            current.user?.canAccessCommunity ?? true,
          [ECommunityAccessConfig.LOGGED_IN_USERS]:
            current.user?.canAccessCommunity ?? false,
          [ECommunityAccessConfig.MEMBERS_ONLY]:
            current.user?.canAccessCommunity ?? false,
        }[current.tenant.communityAccessConfig]
      : false,
    canAccessDmPageAndAuthenticated: current.user?.canAccessDMPage ?? false,
    canAccessEventsFeed:
      current.tenant?.currentUserPermissions.canAccessEventsFeed ?? false,
    canManageOrganizationProfileAndAuthenticated:
      current.user?.rosterOrganization?.currentUserOrganizationPermission
        .canManageOrganizationProfile ?? false,
    canAccessCommunityAndAuthenticated:
      current.user?.canAccessCommunity ?? false,
  };
};
