import { darken, lighten } from 'polished';

// Given a hex color this will try to mathematically generate a color scale
// suitable for use in Chakra. It is worth noting that if a color is used that
// is sufficently light already, the lighter colors that are generated like
// 50 and 100 could end up just being white.

export const createColorScale = (
  color: string
): {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
} => {
  return {
    50: lighten(0.45, color),
    100: lighten(0.4, color),
    200: lighten(0.3, color),
    300: lighten(0.2, color),
    400: lighten(0.1, color),
    500: color,
    600: darken(0.1, color),
    700: darken(0.2, color),
    800: darken(0.3, color),
    900: darken(0.4, color),
  };
};
