import { DocumentNode, gql, useMutation } from '@apollo/client';
import { Box, Button, useToast } from '@chakra-ui/react';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { logError } from '@/imports/logging/ClientLogger';

import { NewUserFollowTags_AllTagsFragment } from './graphql-types/NewUserFollowTags_AllTagsFragment';
import {
  NewUserFollowTags_FollowTags,
  NewUserFollowTags_FollowTagsVariables,
} from './graphql-types/NewUserFollowTags_FollowTags';
import { NewUserFollowTags_FollowedTagsFragment } from './graphql-types/NewUserFollowTags_FollowedTagsFragment';

import StepProgress from '../../../StepProgress';
import { ActivationModalBody } from '../ActivationModalBody';
import { ActivationModalFooter } from '../ActivationModalFooter';
import { ActivationModalHeader } from '../ActivationModalHeader';
import { FollowedTagsList } from './FollowedTagsList';

const FOLLOWED_TAGS_FRAGMENT = gql`
  fragment NewUserFollowTags_FollowedTagsFragment on Tag {
    _id
    ...FollowedTagsList_TagsFragment
  }
  ${FollowedTagsList.tagsFragment}
`;

const ALL_TAGS_FRAGMENT = gql`
  fragment NewUserFollowTags_AllTagsFragment on Tag {
    _id
    ...FollowedTagsList_TagsFragment
  }
  ${FollowedTagsList.tagsFragment}
`;

const FOLLOW_TAGS = gql`
  mutation NewUserFollowTags_FollowTags($input: FollowTagsInput!) {
    followTags(input: $input) {
      user {
        _id
        onboardingFlowState {
          isSkippedOrCompleted
          currentFlowState {
            currentStateIndex
            validOnboardingFlowStates
          }
        }
        userOnboardingChecklistState {
          userHasFollowedTags
        }
      }
    }
  }
`;

interface INewUserFollowTagsProps {
  followedTags: NewUserFollowTags_FollowedTagsFragment[];
  allTags: NewUserFollowTags_AllTagsFragment[];
  isLastStep: boolean;
}

export const NewUserFollowTags: React.FC<INewUserFollowTagsProps> & {
  followedTagsFragment: DocumentNode;
  allTagsFragment: DocumentNode;
} = (props) => {
  const { followedTags, allTags } = props;
  const [followTags, { loading: followTagsMutationLoading }] = useMutation<
    NewUserFollowTags_FollowTags,
    NewUserFollowTags_FollowTagsVariables
  >(FOLLOW_TAGS);
  const { handleSubmit } = useForm();
  const toast = useToast();

  /* For filtering out the already-following tags
     It shouldn't be possible foa user to be already
     following a tag at time of activation now, but
     as a precaution it was decided we should filter
     them out if the user is somehow set to be
     following a tag */
  const tagsToFilterOut = followedTags || [];

  const tags =
    allTags.filter((t) => !tagsToFilterOut.map((f) => f._id).includes(t._id)) ||
    [];

  // the array of tag IDs the user is-currently/wants-to follow
  const [following, setFollowing] = React.useState<Array<string>>([]);

  // fire the mutation followTags
  const saveTags = async (): Promise<void> => {
    try {
      await followTags({
        variables: {
          input: {
            data: {
              tagIds: following,
            },
          },
        },
      });
    } catch (err) {
      toast({
        title: 'Follow Tags failed',
        status: 'error',
      });
      logError(err);
    }
  };

  return (
    <Box
      as="form"
      display="flex"
      flexDirection="column"
      overflow="auto"
      flexGrow="1"
      noValidate
      onSubmit={handleSubmit(saveTags)}
    >
      <ActivationModalHeader
        title={`Follow Tags you're interested in`}
        subtitle="Tags help you get notified when other members of the community post about topics you care about"
      />
      <ActivationModalBody>
        <FollowedTagsList
          selectedTagIds={following}
          setSelectedTagIds={setFollowing}
          tags={tags}
        />
      </ActivationModalBody>
      <ActivationModalFooter>
        <StepProgress />
        <Button type="submit" isLoading={followTagsMutationLoading}>
          {props.isLastStep ? 'Finish' : 'Next'}
        </Button>
      </ActivationModalFooter>
    </Box>
  );
};

NewUserFollowTags.followedTagsFragment = FOLLOWED_TAGS_FRAGMENT;
NewUserFollowTags.allTagsFragment = ALL_TAGS_FRAGMENT;
