import { RetryLink } from '@apollo/client/link/retry';

const nonRetryQueries = [
  'adminSessionsPage_sessionsCsvImport',
  'adminSessionsPage_sessionsAttendeeCsvImport',
  'adminMembersPage_usersCsvImport',
  'csvUpdateManualPermissionGroup', //I don't think this works, this needs to be the fragment name (i.e. PermissionGroupMemberCsvImportModal_csvUpdateManualPermissionGroup)
  'csvCancelRegistrationsForEvent', //I don't think this works
  'csvCreateRegistrationsForEvent', //I don't think this works
  'CreatePostModal_CreatePost',
  'CreateQuestionModal_createQuestion',
  'BlogEdit_createBlogWithPost',
  'createPollWithPostAndOptions',
  'userGroupLinkCsvImport',
  'morgoth_userGroupLinkCsvImport',
  'EventRegistrationModal_csvCreateRegistrationsForEvent',
  'EventRegistrationModal_csvCancelRegistrationsForEvent',
];

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, operation) => {
      return !!error && !nonRetryQueries.includes(operation.operationName);
    },
  },
});

export default retryLink;
