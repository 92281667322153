import {
  Flex,
  IconButton,
  Skeleton,
  SkeletonCircle,
  Spacer,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';
import { useChannelStateContext } from 'stream-chat-react';

import { ChevronLeftIcon, InfoIcon } from '@/imports/ui/chakra/feather';

import { useChannelDetail } from '../hooks/useChannelDetail';
import { MessagingAvatarGroup } from './MessagingAvatarGroup';
import { MessagingEditChannel } from './MessagingEditChannel';

export const MessagingChannelHeader: React.FC<{
  onOpen: () => void;
}> = ({ onOpen }) => {
  const { channel } = useChannelStateContext();
  const channelDetail = useChannelDetail(channel);
  const messagingEditChannel = useDisclosure();

  if (channelDetail.isLoading) {
    return null;
  }

  const members = Object.values(channel.state.members || {});

  const canEditChannel =
    !channelDetail.isCurrentUserSponsorAdmin &&
    !channelDetail.isCurrentUserTargetUser &&
    channelDetail.canCurrentUserAccessCommunity;

  return (
    <Flex
      direction="row"
      alignItems="center"
      p="4"
      gap="4"
      borderBottomWidth="1px"
      borderBottomColor="gray.200"
    >
      <IconButton
        display={{ base: 'block', md: 'none' }}
        icon={<ChevronLeftIcon />}
        aria-label="show channel list"
        size="sm"
        variant="outline"
        colorScheme="gray"
        onClick={onOpen}
      />

      <MessagingAvatarGroup
        channel={channel}
        unread={undefined}
        isLinkEnabled={true}
      />
      <Text textStyle="bodyStrong" noOfLines={1} wordBreak="break-all">
        {channelDetail.name}
      </Text>
      <Spacer />
      {channelDetail.isCurrentUserSponsorAdmin &&
        !!channelDetail.otherAdmins?.length && (
          <Tooltip
            label={channelDetail.otherAdmins
              .map((admin) => admin.displayName)
              .join(', ')}
          >
            <Text>
              + {channelDetail.otherAdmins.length} other{' '}
              {channelDetail.sponsor?.displayName} Admin
              {channelDetail.otherAdmins.length === 1 ? '' : 's'}
            </Text>
          </Tooltip>
        )}

      {canEditChannel && (
        <>
          <IconButton
            variant="ghost"
            colorScheme="gray"
            aria-label="edit channel members"
            icon={<InfoIcon />}
            onClick={messagingEditChannel.onOpen}
            size="sm"
          />
          <MessagingEditChannel
            isOpen={messagingEditChannel.isOpen}
            onClose={messagingEditChannel.onClose}
          />
        </>
      )}
    </Flex>
  );
};
