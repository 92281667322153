import { Icon, IconProps } from '@chakra-ui/react';
import * as React from 'react';

export const CornerRightUpIcon: React.FC<IconProps> = (props) => (
  <Icon
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <polyline points="10 9 15 4 20 9"></polyline>
    <path d="M4 20h7a4 4 0 0 0 4-4V4"></path>
  </Icon>
);
