import { Center, CenterProps, Spinner } from '@chakra-ui/react';
import * as React from 'react';

interface LoadingProps extends CenterProps {
  aspectRatio?: string;
}

export const Loading: React.FC<LoadingProps> = ({ aspectRatio, ...props }) => {
  return (
    <Center p="4" sx={{ aspectRatio }} {...props}>
      <Spinner color="primary.500" />
    </Center>
  );
};
