import { Icon, IconProps } from '@chakra-ui/react';
import * as React from 'react';

export const GitMergeIcon: React.FC<IconProps> = (props) => (
  <Icon
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <circle cx="18" cy="18" r="3"></circle>
    <circle cx="6" cy="6" r="3"></circle>
    <path d="M6 21V9a9 9 0 0 0 9 9"></path>
  </Icon>
);
