import { DocumentNode, gql, useQuery } from '@apollo/client';
import { useBoolean } from '@chakra-ui/react';
import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import * as React from 'react';
import { StreamChat } from 'stream-chat';

import { logWarning } from '@/imports/logging/ClientLogger';

import { InitializeChat } from './graphql-types/InitializeChat';
import { MessagingProvider_User } from './graphql-types/MessagingProvider_User';

const MessagesContext = React.createContext<{
  isLoading: boolean;
  client: StreamChat | null;
  totalUnreadCount: number;
  setForceConnect?: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  isLoading: true,
  client: null,
  totalUnreadCount: 0,
  setForceConnect: undefined,
});

export const useMessages = () => React.useContext(MessagesContext);

const INITIALIZE_CHAT = gql`
  query InitializeChat($userId: String!) {
    initializeChat(userId: $userId) {
      token
      chatKey
    }
  }
`;

export const MessagingProvider: React.FC<{
  currentUser: MessagingProvider_User | null | undefined;
}> & {
  userFragment: DocumentNode;
} = ({ currentUser, children }) => {
  const [isLoading, setIsLoading] = useBoolean(true);
  const [client, setClient] = React.useState<StreamChat | null>(null);

  const [totalUnreadCount, setTotalUnreadCount] = React.useState(0);

  const [forceConnect, setForceConnect] = React.useState(false);

  const thirtyDaysAgo = dayjs().subtract(30, 'days');

  const query = useQuery<InitializeChat>(INITIALIZE_CHAT, {
    variables: { userId: currentUser?._id },
    skip:
      !forceConnect &&
      (!currentUser ||
        !currentUser.lastMessagesPageViewDate ||
        dayjs(currentUser.lastMessagesPageViewDate).isBefore(thirtyDaysAgo)),
  });

  const token = query.data?.initializeChat?.token;
  const chatKey = query.data?.initializeChat?.chatKey;

  React.useEffect(() => {
    if (currentUser && token && chatKey) {
      const chatClient = new StreamChat(chatKey, {
        timeout: 6000,
      });

      const decodedUserToken = jwtDecode<{ user_id: string }>(token);

      if (currentUser._id !== decodedUserToken.user_id) {
        setClient(null);
        setIsLoading.off();
        return;
      }

      // if we can't connect to the stream websocket, set the chat client to null
      chatClient
        .connectUser(
          {
            id: currentUser._id,
            name: currentUser.displayName,
            image: currentUser.proxyProfilePictureUrl || undefined,
          },
          token
        )
        .then((response) => {
          setTotalUnreadCount(response?.me?.total_unread_count ?? 0);
        })
        .catch(() => {
          logWarning(
            `user ${currentUser._id} could not connect to streamchat websocket`
          );
          setClient(null);
        })
        .finally(() => setIsLoading.off());

      setClient(chatClient);
    }
  }, [chatKey, currentUser, token, setIsLoading]);

  React.useEffect(
    () =>
      client?.on((event) => {
        if (event.total_unread_count !== undefined) {
          setTotalUnreadCount(event.total_unread_count);
        }
      }).unsubscribe,
    [client]
  );

  return (
    <MessagesContext.Provider
      value={{
        isLoading,
        client,
        totalUnreadCount,
        setForceConnect,
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};

MessagingProvider.userFragment = gql`
  fragment MessagingProvider_User on User {
    _id
    displayName
    proxyProfilePictureUrl(
      input: { w: 64, h: 64, fit: "facearea", facepad: 10.0 }
    )
    lastMessagesPageViewDate
  }
`;
