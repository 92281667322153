import { Icon, IconProps } from '@chakra-ui/react';
import * as React from 'react';

export const CornerLeftDownIcon: React.FC<IconProps> = (props) => (
  <Icon
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <polyline points="14 15 9 20 4 15"></polyline>
    <path d="M20 4h-7a4 4 0 0 0-4 4v12"></path>
  </Icon>
);
