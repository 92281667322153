import { IconButton, Stack, Text } from '@chakra-ui/react';
import type { ThreadHeaderProps } from 'stream-chat-react';

import { XIcon } from '@/imports/ui/chakra/feather';

export const MessagingThreadHeader: React.FC<ThreadHeaderProps> = ({
  closeThread,
}) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      px="6"
      py="4"
      borderBottomWidth="1px"
      borderBottomColor="gray.200"
    >
      <Text textStyle="bodyStrong">Thread</Text>
      <IconButton
        icon={<XIcon />}
        aria-label="close thread"
        onClick={closeThread}
        size="sm"
        variant="outline"
        colorScheme="gray"
      />
    </Stack>
  );
};
