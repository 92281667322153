import { gql, useQuery } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import * as React from 'react';

import { MembershipOrderVariant } from '@/graphql-types/globalTypes';

import {
  MembershipSuccessModal_Query,
  MembershipSuccessModal_QueryVariables,
} from './graphql-types/MembershipSuccessModal_Query';

const QUERY = gql`
  query MembershipSuccessModal_Query($input: MembershipOrderInput!) {
    membershipOrder(input: $input) {
      membershipOrder {
        _id
        variant
        options {
          membershipType {
            _id
            name
          }
        }
        owner {
          ... on User {
            _id
            membershipData {
              currentMembershipTerm {
                _id
                membershipType {
                  _id
                }
              }
            }
          }
          ... on Organization {
            _id
            membershipData {
              currentMembershipTerm {
                _id
                membershipType {
                  _id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const MembershipSuccessModal: React.FC = () => {
  const router = useRouter();
  const successfulMembershipOrderId =
    router.query.successfulMembershipOrderId?.toString();

  const query = useQuery<
    MembershipSuccessModal_Query,
    MembershipSuccessModal_QueryVariables
  >(QUERY, {
    variables: {
      input: {
        membershipOrderId: successfulMembershipOrderId ?? '',
      },
    },
    skip: !successfulMembershipOrderId,
  });

  const toast = useToast();

  React.useEffect(() => {
    const membershipOrder = query.data?.membershipOrder.membershipOrder;

    if (!membershipOrder) {
      return;
    }

    const title = {
      [MembershipOrderVariant.RECURRING]: `Congrats! Your ${membershipOrder.options.membershipType.name} renewal is complete`,
      [MembershipOrderVariant.FIRST]:
        membershipOrder.owner.__typename !==
          'MembershipOrderDraftOrganization' &&
        membershipOrder.owner.membershipData?.currentMembershipTerm &&
        membershipOrder.owner.membershipData.currentMembershipTerm
          .membershipType._id === membershipOrder.options.membershipType._id
          ? `Congrats! Your ${membershipOrder.options.membershipType.name} is active`
          : `Congrats! Your ${membershipOrder.options.membershipType.name} is paid for`,
      [MembershipOrderVariant.MONTHLY_DUES]: `Congrats! Your monthly payment for ${membershipOrder.options.membershipType.name} has been processed successfully`,
    }[membershipOrder.variant];

    toast({
      status: 'success',
      title,
      description:
        'Check your inbox for a confirmation email where you can view your receipt',
      duration: null,
      isClosable: true,
    });
  }, [toast, query]);

  return null;
};
