import { Box } from '@chakra-ui/react';
import * as React from 'react';
import { Chat } from 'stream-chat-react';

import { Loading } from '@/imports/ui/chakra/components/Loading';
import { UnexpectedError } from '@/imports/ui/chakra/components/UnexpectedError';

import { useMessages } from './MessagingProvider';

export const MessagingChat: React.FC = ({ children }) => {
  const { client, isLoading, setForceConnect } = useMessages();

  if (!client && !!setForceConnect) {
    setForceConnect(true);
  }

  if (isLoading) return <Loading />;

  if (client) {
    return <Chat client={client}>{children}</Chat>;
  }

  return (
    <Box mt="4" mx="2">
      <UnexpectedError message="Cannot connect to chat right now" />
    </Box>
  );
};
