import { Circle, Stack, Text } from '@chakra-ui/react';

import { useCurrent } from '@/hooks/useCurrent';
import { Semibold } from '@/imports/ui/chakra/components/Semibold';
import {
  BookOpenIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CopyIcon,
  PlusSquareIcon,
  ShareIcon,
} from '@/imports/ui/chakra/feather';

export const AddAsBookmarkSteps: React.FC = () => {
  const current = useCurrent();
  const acronym = current.tenant?.acronym
    ? current.tenant.acronym
    : 'association';

  return (
    <Stack spacing="10">
      <Stack>
        <Text textStyle="xlBody">
          1. Tap the <Semibold>Share</Semibold> button on the bottom or top of
          the screen.
        </Text>
        <Stack
          spacing="0"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          bg="gray.100"
          borderRadius="6"
        >
          <Circle size="10">
            <ChevronLeftIcon />
          </Circle>
          <Circle size="10">
            <ChevronRightIcon />
          </Circle>
          <Circle size="10" borderColor="primary.500" borderWidth="2px">
            <ShareIcon />
          </Circle>
          <Circle size="10">
            <BookOpenIcon />
          </Circle>
          <Circle size="10">
            <CopyIcon />
          </Circle>
        </Stack>
      </Stack>

      <Stack>
        <Text textStyle="xlBody">
          2. In the menu, select <Semibold>Add to Home Screen</Semibold>.
        </Text>
        <Stack
          spacing="0"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          bg="gray.100"
          borderRadius="6"
        >
          <Text textStyle="xlBody" px="4">
            Add to Home Screen
          </Text>
          <Circle size="10">
            <PlusSquareIcon />
          </Circle>
        </Stack>
      </Stack>

      <Stack>
        <Text textStyle="xlBody">
          3. <Semibold>You&apos;re all set!</Semibold> You can now tap the icon
          on your home screen to launch the {acronym} community.
        </Text>
      </Stack>
    </Stack>
  );
};
