import {
  ChakraProvider,
  theme as baseTheme,
  extendTheme,
  withDefaultColorScheme,
} from '@chakra-ui/react';
import '@fontsource/source-sans-pro/400.css';
import '@fontsource/source-sans-pro/600.css';
import 'focus-visible/dist/focus-visible';
import { useRouter } from 'next/router';
import * as React from 'react';

import { theme } from '@/imports/ui/chakra/theme';
import { createColorScale } from '@/imports/ui/chakra/utilities/create-color-scale';

type IDynamicThemeProvider = {
  children: React.ReactNode;
  primaryColor: string | null | undefined;
};

const tradewingPurple = '#7166e9';

export const DynamicThemeProvider: React.FC<IDynamicThemeProvider> = (
  props
) => {
  const router = useRouter();

  const primaryColor = router.asPath.startsWith('/admin')
    ? tradewingPurple
    : props.primaryColor;

  const brandedChakraTheme = extendTheme(
    {
      ...theme,
      colors: {
        primary: primaryColor
          ? createColorScale(primaryColor)
          : baseTheme.colors.blue,
        purple: createColorScale(tradewingPurple),
      },
    },
    withDefaultColorScheme({ colorScheme: 'primary' })
  );

  return (
    <ChakraProvider theme={brandedChakraTheme}>{props.children}</ChakraProvider>
  );
};
