import { gql, useMutation, useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import * as React from 'react';

import { EUserAccountStatus } from '@/client/legacy-shared-modules/user/userEnums';
import { useCurrent } from '@/hooks/useCurrent';

import { useActivtionModal_ResetOnboardingState } from './graphql-types/useActivtionModal_ResetOnboardingState';

const RESET_ONBOARDING_STATE = gql`
  mutation useActivtionModal_ResetOnboardingState {
    resetOnboardingState {
      currentUser {
        _id
        accountStatus
        onboardingFlowState {
          isSkippedOrCompleted
          currentFlowState {
            currentStateIndex
            validOnboardingFlowStates
          }
        }
        acceptedCodeOfConduct
      }
    }
  }
`;

export const useActivationModal = ():
  | { ready: false; showModal: undefined }
  | { ready: true; showModal: boolean } => {
  const router = useRouter();

  const rModalOpen = router.query.rModalOpen
    ? router.query.rModalOpen.toString()
    : undefined;

  const ticketLinkId = router.query.ticketLinkId
    ? router.query.ticketLinkId.toString()
    : undefined;

  const preSelectedTicketId = router.query.preSelectedTicketId
    ? router.query.preSelectedTicketId.toString()
    : undefined;

  const isEventTicketPurchaseInProgress =
    !!rModalOpen || !!ticketLinkId || !!preSelectedTicketId;

  const [resetOnboardingState, { called }] =
    useMutation<useActivtionModal_ResetOnboardingState>(RESET_ONBOARDING_STATE);

  const current = useCurrent();

  React.useEffect(() => {
    if (router.isReady && router.query.reonboard === 'true' && !called) {
      resetOnboardingState();
    }
  }, [router, resetOnboardingState, called]);

  if (current.loading || !router.isReady) {
    return { ready: false, showModal: undefined };
  }

  return {
    ready: true,
    showModal:
      !isEventTicketPurchaseInProgress &&
      !current.user?.onboardingFlowState.isSkippedOrCompleted &&
      // to not show modal on top of the set a password page
      current.user?.accountStatus === EUserAccountStatus.ACTIVE &&
      router.pathname !== '/activate' &&
      router.pathname !== '/membership-orders/[membershipOrderId]/pay' &&
      router.pathname !== '/new-membership/[membershipTypeId]' &&
      router.pathname !== '/membership-orders/[membershipOrderId]/success' &&
      router.pathname !== '/events/[eventId]/tickets/[ticketId]/buy' &&
      router.pathname !== '/events/[eventId]/registration',
  };
};
