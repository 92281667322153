import { ApolloLink } from '@apollo/client';

const LEGACY_METEOR_LOGIN_TOKEN_KEY = 'Meteor.loginToken';

const meteorAccountsLink = new ApolloLink((operation, forward) => {
  const token = localStorage?.getItem(LEGACY_METEOR_LOGIN_TOKEN_KEY);

  if (token) {
    operation.setContext(() => ({
      headers: {
        authorization_meteor: token,
      },
    }));
  }

  return forward(operation);
});

export default meteorAccountsLink;
