import type amplitude_Type from 'amplitude-js';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

import { PaymentInterval } from '@/graphql-types/globalTypes';

import {
  EAmplitudeEventTypes,
  IAnalyticsLogger,
  IEditLocation,
  IFeedLocation,
  ILoginOutcome,
  ILoginView,
  IPostLocation,
  IPostType,
} from './types';

const pathToFeedLocation = (path: string): IFeedLocation => {
  return path.startsWith('/community')
    ? 'home'
    : path.startsWith('/group')
    ? 'group'
    : path.startsWith('/companies')
    ? 'organization'
    : 'post';
};

const pathToEditLocation = (path: string): IEditLocation => {
  return path.startsWith('/community')
    ? 'home'
    : path.startsWith('/group')
    ? 'group'
    : path.startsWith('/drafts')
    ? 'drafts'
    : 'organization';
};

export const AnalyticsLogger = (
  location: { pathname: string; search?: string } | null,
  analyticsContext: {
    amplitudeClient: amplitude_Type.AmplitudeClient | null;
    currentUserId: string | null;
    referral: string | string[] | null | undefined;
  } & Record<string, any>,
  tenantCode: string | null,
  userProps: {
    vendorDirectory?: boolean;
    communityAccess?: boolean;
    userType?: string;
    email?: string;
    tier?: string;
    role?: string;
    membershipStatus?: string;
  }
): IAnalyticsLogger => {
  const amplitudeClient = analyticsContext.amplitudeClient;
  const logEventWithLocation = (
    amplitudeClient: amplitude.AmplitudeClient,
    location: { pathname: string; search?: string },
    eventName: string,
    properties?: any
  ): void => {
    if (!!analyticsContext['currentUserId']) {
      amplitudeClient.setUserId(analyticsContext['currentUserId']);
    }
    if (tenantCode) {
      amplitudeClient.setUserProperties({ 'tenant name': tenantCode });
      amplitudeClient.setGroup('tenant', tenantCode);
    }
    AnalyticsLogger(
      location,
      analyticsContext,
      tenantCode,
      userProps
    ).setUserProperties({
      'community access': userProps.communityAccess,
      'user type': userProps.userType,
      'vendor directory': userProps.vendorDirectory,
      email: userProps.email,
      tier: userProps.tier,
      role: userProps.role,
      membershipStatus: userProps.membershipStatus,
    });
    const tenant = tenantCode ? { tenant: tenantCode } : {};
    const referral = !!analyticsContext['referral']
      ? { referral: analyticsContext['referral'] }
      : {};
    amplitudeClient.logEvent(eventName, {
      ...properties,
      route: `${location.pathname}${location.search}`,
      ...tenant,
      ...referral,
    });
  };

  const getDaysBeforeTime = (dateString: string): number => {
    const date = new Date(dateString);
    const currentDate = Date.now();
    const diff = date.getTime() - currentDate;
    const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    return diffDays;
  };

  return {
    logEvent_pageload: (): void => {
      if (!!amplitudeClient && !!location) {
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.pageload
        );
      }
    },
    logEvent_login_view_loginUi: (properties: {
      view: ILoginView;
      'sso enabled': boolean;
      'signup enabled': boolean;
      'is public': boolean;
    }): void => {
      if (!!amplitudeClient && !!location) {
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.login_view_loginUi,
          properties
        );
      }
    },
    logEvent_login_click_continue: (properties: {
      view: ILoginView;
      'sso provider': string;
      'signup enabled': boolean;
    }): void => {
      if (!!amplitudeClient && !!location) {
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.login_click_continue,
          properties
        );
      }
    },
    logEvent_login_click_resetPw: (properties: {
      view: ILoginView;
      'sso enabled': boolean;
      'signup enabled': boolean;
    }): void => {
      if (!!amplitudeClient && !!location) {
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.login_click_resetPw,
          properties
        );
      }
    },
    logEvent_login_click_signUp: (properties: {
      view: ILoginView;
      'sso enabled': boolean;
    }): void => {
      if (!!amplitudeClient && !!location) {
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.login_click_signUp,
          properties
        );
      }
    },
    logEvent_login_click_login: (properties: {
      view: ILoginView;
      'sso enabled': boolean;
      'signup enabled': boolean;
    }): void => {
      if (!!amplitudeClient && !!location) {
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.login_click_login,
          properties
        );
      }
    },
    logEvent_login_outcome: (properties: {
      outcome: ILoginOutcome;
      view?: ILoginView;
      'sso enabled': boolean;
      'signup enabled'?: boolean;
    }): void => {
      if (!!amplitudeClient && !!location) {
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.login_outcome,
          properties
        );
      }
    },
    logEvent_comm_view_home: (): void => {
      if (!!amplitudeClient && !!location) {
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.comm_view_home
        );
      }
    },
    logEvent_comm_click_message: (): void => {
      if (!!amplitudeClient && !!location) {
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.comm_click_message,
          {
            feed: pathToFeedLocation(location.pathname),
          }
        );
      }
    },
    logEvent_comm_click_post: (properties): void => {
      if (!!amplitudeClient && !!location) {
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.comm_click_post,
          {
            ...properties,
            feed: pathToFeedLocation(location.pathname),
          }
        );
        const identify = new amplitudeClient.Identify().add(
          'number of contributions',
          1
        );
        amplitudeClient.identify(identify);
      }
    },
    logEvent_comm_click_question: (): void => {
      if (!!amplitudeClient && !!location) {
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.comm_click_question,
          {
            feed: pathToFeedLocation(location.pathname),
          }
        );
      }
    },
    logEvent_comm_click_ask: (properties): void => {
      if (!!amplitudeClient && !!location) {
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.comm_click_ask,
          {
            ...properties,
            feed: pathToFeedLocation(location.pathname),
          }
        );
        const identify = new amplitudeClient.Identify().add(
          'number of contributions',
          1
        );
        amplitudeClient.identify(identify);
      }
    },
    logEvent_comm_click_answer: (properties): void => {
      if (!!amplitudeClient && !!location) {
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.comm_click_answer,
          properties
        );
        const identify = new amplitudeClient.Identify().add(
          'number of contributions',
          1
        );
        amplitudeClient.identify(identify);
      }
    },
    logEvent_comm_click_article: (): void => {
      if (!!amplitudeClient && !!location) {
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.comm_click_article,
          {
            feed: pathToFeedLocation(location.pathname),
          }
        );
      }
    },
    logEvent_comm_click_publish: (properties): void => {
      if (!!amplitudeClient && !!location) {
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.comm_click_publish,
          {
            ...properties,
            'edit location': pathToEditLocation(location.pathname),
          }
        );
        const identify = new amplitudeClient.Identify().add(
          'number of contributions',
          1
        );
        amplitudeClient.identify(identify);
      }
    },
    logEvent_comm_click_poll: (): void => {
      if (!!amplitudeClient && !!location) {
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.comm_click_poll,
          {
            feed: pathToFeedLocation(location.pathname),
          }
        );
      }
    },
    logEvent_comm_click_create: (properties): void => {
      if (!!amplitudeClient && !!location) {
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.comm_click_create,
          {
            ...properties,
            feed: pathToFeedLocation(location.pathname),
          }
        );
        const identify = new amplitudeClient.Identify().add(
          'number of contributions',
          1
        );
        amplitudeClient.identify(identify);
      }
    },
    logEvent_comm_click_like: (properties): void => {
      if (!!amplitudeClient && !!location) {
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.comm_click_like,
          {
            ...properties,
            feed: pathToFeedLocation(location.pathname),
          }
        );
      }
    },
    logEvent_comm_click_comment: (properties: {
      'post type': IPostType;
      'posted in': IPostLocation;
      'added tags': boolean;
      announcement: boolean;
      'on post': boolean;
    }): void => {
      if (!!amplitudeClient && !!location) {
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.comm_click_comment,
          {
            ...properties,
            feed: pathToFeedLocation(location.pathname),
          }
        );
        const identify = new amplitudeClient.Identify().add(
          'number of contributions',
          1
        );
        amplitudeClient.identify(identify);
      }
    },
    logEvent_comm_click_submit_lead_form: (properties): void => {
      if (!!amplitudeClient && !!location) {
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.comm_click_submit_lead_form,
          properties
        );
      }
    },
    logEvent_comm_click_launch_lead_form: (properties): void => {
      if (!!amplitudeClient && !!location) {
        const propertiesFromContext = analyticsContext['simpleEventId']
          ? {
              'simpleEvent id': analyticsContext['simpleEventId'],
              'is hosted by sponsor':
                analyticsContext['isHostedBySponsor'] || false,
            }
          : {
              'event id': analyticsContext['eventId'],
              'additional sponsors': analyticsContext['additionalSponsors'],
              'subevent type': analyticsContext['subeventType'],
              'subevent id': analyticsContext['subeventId'],
              'is hosted by sponsor':
                analyticsContext['isHostedBySponsor'] || false,
            };
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.comm_click_launch_lead_form,
          { ...properties, ...propertiesFromContext }
        );
      }
    },
    logEvent_events_view_conference: (properties: {
      'logged in': boolean;
      'event id': string;
      'register capable': boolean;
      'event start date': string;
      'sponsor number': number;
      'session number': number;
      'speaker number': number;
    }): void => {
      if (!!amplitudeClient && !!location) {
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.events_view_conference,
          {
            ...properties,
            'days until event': getDaysBeforeTime(
              properties['event start date']
            ),
          }
        );
      }
    },
    logEvent_events_click_register: (properties: {
      'logged in': boolean;
      'event id': string;
      'ticket options': number;
      'event start date': string;
    }): void => {
      if (!!amplitudeClient && !!location) {
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.events_click_register,
          {
            ...properties,
            'days until event': getDaysBeforeTime(
              properties['event start date']
            ),
          }
        );
      }
    },
    logEvent_events_click_checkout: (properties: {
      'event id': string;
      'ticket options': number;
      'ticket price': number;
      'ticket id': string;
    }): void => {
      if (!!amplitudeClient && !!location) {
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.events_click_checkout,
          properties
        );
      }
    },
    logEvent_events_click_event: (properties): void => {
      if (!!amplitudeClient && !!location) {
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.events_click_event,
          {
            ...properties,
            'days until event': getDaysBeforeTime(
              properties['event start date']
            ),
          }
        );
      }
    },
    logEvent_events_click_subevent: (): void => {
      if (!!amplitudeClient && !!location) {
        const properties = {
          'event id': analyticsContext['eventId'],
          'has sponsor': analyticsContext['hasSponsor'],
          'subevent type': analyticsContext['subeventType'],
          'subevent id': analyticsContext['subeventId'],
          'subevent role': analyticsContext['subeventRole'],
          'happening now': analyticsContext['happeningNow'] || false, //not implemented
          'schedule page': analyticsContext['schedulePage'] || false,
          'sessions page': analyticsContext['sessionsPage'] || false,
          'expo page': analyticsContext['expoPage'] || false,
          'sessions live tab': analyticsContext['sessionsLiveTab'] || false,
          'sessions all tab': analyticsContext['sessionsAllTab'] || false,
          'expo live tab': analyticsContext['expoLiveTab'] || false,
          'expo all tab': analyticsContext['expoAllTab'] || false,
          'days until event': !!analyticsContext['eventStartDate']
            ? getDaysBeforeTime(analyticsContext['eventStartDate'])
            : undefined,
          'days until subevent': !!analyticsContext['subeventStartDate']
            ? getDaysBeforeTime(analyticsContext['subeventStartDate'])
            : undefined,
        };
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.events_click_subevent,
          properties
        );
      }
    },
    logEvent_events_click_speakerJoin: (): void => {
      if (!!amplitudeClient && !!location) {
        const properties = analyticsContext['simpleEventId']
          ? {
              'simpleEvent id': analyticsContext['simpleEventId'],
              'has sponsor': analyticsContext['hasSponsor'],
              'is hosted by sponsor':
                analyticsContext['isHostedBySponsor'] || false,
            }
          : {
              'event id': analyticsContext['eventId'],
              'has sponsor': analyticsContext['hasSponsor'],
              'subevent type': analyticsContext['subeventType'],
              'subevent id': analyticsContext['subeventId'],
              'is hosted by sponsor':
                analyticsContext['isHostedBySponsor'] || false,
            };
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.events_click_speakerJoin,
          properties
        );
      }
    },
    logEvent_events_click_participantJoin: (): void => {
      if (!!amplitudeClient && !!location) {
        const properties = analyticsContext['simpleEventId']
          ? {
              'simpleEvent id': analyticsContext['simpleEventId'],
              'has sponsor': analyticsContext['hasSponsor'],
              'is hosted by sponsor':
                analyticsContext['isHostedBySponsor'] || false,
            }
          : {
              'event id': analyticsContext['eventId'],
              'has sponsor': analyticsContext['hasSponsor'],
              'subevent type': analyticsContext['subeventType'],
              'subevent id': analyticsContext['subeventId'],
              'is hosted by sponsor':
                analyticsContext['isHostedBySponsor'] || false,
            };
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.events_click_participantJoin,
          properties
        );
      }
    },
    logEvent_events_click_listenerJoin: (): void => {
      if (!!amplitudeClient && !!location) {
        const properties = analyticsContext['simpleEventId']
          ? {
              'simpleEvent id': analyticsContext['simpleEventId'],
              'has sponsor': analyticsContext['hasSponsor'],
              'is hosted by sponsor':
                analyticsContext['isHostedBySponsor'] || false,
            }
          : {
              'event id': analyticsContext['eventId'],
              'has sponsor': analyticsContext['hasSponsor'],
              'subevent type': analyticsContext['subeventType'],
              'subevent id': analyticsContext['subeventId'],
              'is hosted by sponsor':
                analyticsContext['isHostedBySponsor'] || false,
            };
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.events_click_listenerJoin,
          properties
        );
      }
    },
    logEvent_events_click_chatTab: (): void => {
      if (!!amplitudeClient && !!location) {
        const properties = analyticsContext['simpleEventId']
          ? {
              'simpleEvent id': analyticsContext['simpleEventId'],
              'has sponsor': analyticsContext['hasSponsor'],
              'is hosted by sponsor':
                analyticsContext['isHostedBySponsor'] || false,
            }
          : {
              'event id': analyticsContext['eventId'],
              'has sponsor': analyticsContext['hasSponsor'],
              'subevent type': analyticsContext['subeventType'],
              'subevent id': analyticsContext['subeventId'],
              'is hosted by sponsor':
                analyticsContext['isHostedBySponsor'] || false,
            };
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.events_click_chatTab,
          properties
        );
      }
    },
    logEvent_events_click_sendChat: (properties: {
      'file attached': boolean;
    }): void => {
      if (!!amplitudeClient && !!location) {
        const contextProperties = analyticsContext['simpleEventId']
          ? {
              'simpleEvent id': analyticsContext['simpleEventId'],
              'has sponsor': analyticsContext['hasSponsor'],
              'is hosted by sponsor':
                analyticsContext['isHostedBySponsor'] || false,
            }
          : {
              'event id': analyticsContext['eventId'],
              'has sponsor': analyticsContext['hasSponsor'],
              'subevent type': analyticsContext['subeventType'],
              'subevent id': analyticsContext['subeventId'],
              'is hosted by sponsor':
                analyticsContext['isHostedBySponsor'] || false,
            };
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.events_click_sendChat,
          { ...properties, ...contextProperties }
        );
      }
    },
    logEvent_ams_click_pay: (properties: {
      amount: string;
      screen:
        | 'adminSiteNewMembershipCheckout'
        | 'adminSiteMembershipOrderCheckout'
        | 'memberSiteNewMembershipCheckout'
        | 'memberSiteMembershipOrderCheckout';
      paymentType:
        | 'creditCard'
        | 'other'
        | 'free'
        | 'credit'
        | 'none'
        | 'achTransfer'
        | 'invoice'
        | undefined;
      membershipType: 'individual' | 'corporate';
      shouldSavePaymentInformation: boolean;
      autoPay: boolean;
      paymentInterval: PaymentInterval;
      invoicing: 'generateInvoice' | 'payForInvoice' | undefined;
    }): void => {
      if (!!amplitudeClient && !!location) {
        logEventWithLocation(
          amplitudeClient,
          location,
          EAmplitudeEventTypes.ams_click_pay,
          properties
        );
      }
    },
    setUserId: (userId: string | null): void => {
      if (!!amplitudeClient && !!userId) {
        amplitudeClient.setUserId(userId);
      }
    },
    setUserProperties: (properties: {
      'community access'?: boolean;
      'user type'?: string;
      'vendor directory'?: boolean;
      'tenant name'?: string;
      email?: string;
      tier?: string;
    }): void => {
      const definedProps = omitBy(properties, isNil);
      if (!!amplitudeClient) {
        amplitudeClient.setUserProperties(definedProps);
      }
    },
    logUserJoinedGroup: (): void => {
      if (!!amplitudeClient) {
        const identify = new amplitudeClient.Identify().add(
          'number of groups',
          1
        );
        amplitudeClient.identify(identify);
      }
    },
    logUserLeftGroup: (): void => {
      if (!!amplitudeClient) {
        const identify = new amplitudeClient.Identify().add(
          'number of groups',
          -1
        );
        amplitudeClient.identify(identify);
      }
    },
    logUserRegisteredForConference: (): void => {
      if (!!amplitudeClient) {
        const identify = new amplitudeClient.Identify().add(
          'number of events',
          1
        );
        amplitudeClient.identify(identify);
      }
    },
    logUserFollowedSponsor: (): void => {
      if (!!amplitudeClient) {
        const identify = new amplitudeClient.Identify().add(
          'number of sponsor follows',
          1
        );
        amplitudeClient.identify(identify);
      }
    },
    logUserUnfollowedSponsor: (): void => {
      if (!!amplitudeClient) {
        const identify = new amplitudeClient.Identify().add(
          'number of sponsor follows',
          -1
        );
        amplitudeClient.identify(identify);
      }
    },
  };
};
