import { MutationTuple, gql, useMutation } from '@apollo/client';

import {
  useAcceptCodeOfConduct_AcceptCodeOfConduct,
  useAcceptCodeOfConduct_AcceptCodeOfConductVariables,
} from './graphql-types/useAcceptCodeOfConduct_AcceptCodeOfConduct';

const ACCEPT_CODE_OF_CONDUCT = gql`
  mutation useAcceptCodeOfConduct_AcceptCodeOfConduct(
    $input: AcceptCodeOfConductInput!
  ) {
    acceptCodeOfConduct(input: $input) {
      user {
        _id
        acceptedCodeOfConduct
        onboardingFlowState {
          isSkippedOrCompleted
          currentFlowState {
            currentStateIndex
            validOnboardingFlowStates
          }
        }
      }
    }
  }
`;

export const useAcceptCodeOfConduct = (
  isPartOfOnboardingFlow: boolean
): MutationTuple<useAcceptCodeOfConduct_AcceptCodeOfConduct, {}> => {
  const result = useMutation<
    useAcceptCodeOfConduct_AcceptCodeOfConduct,
    useAcceptCodeOfConduct_AcceptCodeOfConductVariables
  >(ACCEPT_CODE_OF_CONDUCT, {
    variables: { input: { isPartOfOnboardingFlow } },
  });
  return result;
};
