import { CookieStorage } from 'cookie-storage';
import { CookieOptions } from 'cookie-storage/lib/cookie-options';

export const ACCESS_TOKEN_COOKIE_KEY = 'accounts:accessToken';
export const REFRESH_TOKEN_COOKIE_KEY = 'accounts:refreshToken';

const cookieStorage = new CookieStorage();

export const getCookieOptions = (): CookieOptions => {
  const inThirtyDays = new Date();
  inThirtyDays.setDate(inThirtyDays.getDate() + 30);
  return {
    secure: true,
    sameSite: 'None', // Can be 'Strict' or 'Lax' or 'None'.
    expires: inThirtyDays,
    path: '/',
  };
};

export const tokenStorage = {
  setItem(key: string, value: string): void {
    cookieStorage.setItem(key, value, getCookieOptions());
  },

  getItem(key: string): string | null {
    return cookieStorage.getItem(key);
  },

  removeItem(key: string): void {
    cookieStorage.removeItem(key, { path: '/' });
  },
};
