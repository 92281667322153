import { Icon, IconProps } from '@chakra-ui/react';
import * as React from 'react';

export const ThumbsUpIcon: React.FC<IconProps & { isLiked?: boolean }> = ({
  isLiked,
  ...props
}) => {
  if (isLiked) {
    return (
      <Icon width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
        <path
          d="M4.66732 14.6668H2.66732C2.3137 14.6668 1.97456 14.5264 1.72451 14.2763C1.47446 14.0263 1.33398 13.6871 1.33398 13.3335V8.66683C1.33398 8.31321 1.47446 7.97407 1.72451 7.72402C1.97456 7.47397 2.3137 7.3335 2.66732 7.3335H4.66732"
          fill="currentColor"
        />
        <path
          d="M9.33398 6.00016V3.3335C9.33398 2.80306 9.12327 2.29436 8.7482 1.91928C8.37313 1.54421 7.86442 1.3335 7.33398 1.3335L4.66732 7.3335H2.66732C2.3137 7.3335 1.97456 7.47397 1.72451 7.72402C1.47446 7.97407 1.33398 8.31321 1.33398 8.66683V13.3335C1.33398 13.6871 1.47446 14.0263 1.72451 14.2763C1.97456 14.5264 2.3137 14.6668 2.66732 14.6668H4.66732H12.1873C12.5089 14.6705 12.8209 14.5578 13.0659 14.3495C13.3109 14.1412 13.4724 13.8514 13.5207 13.5335L14.4407 7.5335C14.4697 7.3424 14.4568 7.14728 14.4029 6.96166C14.349 6.77604 14.2554 6.60436 14.1286 6.45851C14.0017 6.31265 13.8447 6.19612 13.6684 6.11697C13.492 6.03783 13.3006 5.99797 13.1073 6.00016H11.2207H9.33398Z"
          stroke="currentColor"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Icon>
    );
  }
  return (
    <Icon width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M4.66683 14.6667H2.66683C2.31321 14.6667 1.97407 14.5262 1.72402 14.2762C1.47397 14.0261 1.3335 13.687 1.3335 13.3334V8.66671C1.3335 8.31309 1.47397 7.97395 1.72402 7.7239C1.97407 7.47385 2.31321 7.33337 2.66683 7.33337H4.66683M9.3335 6.00004V3.33337C9.3335 2.80294 9.12278 2.29423 8.74771 1.91916C8.37264 1.54409 7.86393 1.33337 7.3335 1.33337L4.66683 7.33337V14.6667H12.1868C12.5084 14.6703 12.8204 14.5576 13.0654 14.3494C13.3105 14.1411 13.4719 13.8513 13.5202 13.5334L14.4402 7.53337C14.4692 7.34228 14.4563 7.14716 14.4024 6.96154C14.3485 6.77592 14.2549 6.60424 14.1281 6.45838C14.0012 6.31253 13.8442 6.196 13.6679 6.11685C13.4915 6.03771 13.3001 5.99785 13.1068 6.00004H9.3335Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
