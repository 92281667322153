import { ApolloLink } from '@apollo/client';

export const TENANT_DOMAIN_HEADER_KEY = 'tenant_domain';

const tenantHeaderLink = new ApolloLink((operation, forward) => {
  const tenantDomain = window.location.hostname;

  operation.setContext(({ headers }) => ({
    headers: {
      [TENANT_DOMAIN_HEADER_KEY]: tenantDomain,
      ...headers,
    },
  }));

  return forward(operation);
});

export default tenantHeaderLink;
