import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  VisuallyHidden,
} from '@chakra-ui/react';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useChannelStateContext, useChatContext } from 'stream-chat-react';

import { useInitChatUsers } from '@/modules/messaging';

import { SearchableUserSelect } from '../../SearchableUserSelect';

export const AddPeople: React.FC = () => {
  const { client } = useChatContext();
  const { channel } = useChannelStateContext();
  const { initChatUsers } = useInitChatUsers();

  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting },
    watch,
  } = useForm<{
    userIds: string[];
  }>({
    defaultValues: {
      userIds: [],
    },
  });

  const userIds = watch('userIds');

  const onSubmit = handleSubmit(async (data) => {
    await initChatUsers({ variables: { memberIds: data.userIds } });

    const response = await channel.addMembers(data.userIds);

    reset();

    channel.sendMessage({
      message: `${client.user?.name} added ${response.members
        .filter((member) =>
          member.user ? data.userIds.includes(member.user.id) : false
        )
        .map((member) => member.user?.name)
        .join(', ')}`,
    });
  });

  const members = Object.values(channel.state.members || {});

  return (
    <Stack direction="row" alignItems="flex-end">
      <Controller
        control={control}
        name="userIds"
        render={({ field, fieldState }) => (
          <FormControl isInvalid={!!fieldState.error}>
            <VisuallyHidden>
              <FormLabel>Users</FormLabel>
            </VisuallyHidden>
            <SearchableUserSelect
              placeholder="Add users"
              value={field.value}
              onChange={field.onChange}
              exclude={[
                ...field.value,
                ...members
                  .filter((member) => !!member.user)
                  .map((member) => member.user?.id ?? ''),
              ]}
            />
            <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
          </FormControl>
        )}
        rules={{
          validate: {
            atLeastOne: (value) => {
              if (value.length > 0) {
                return true;
              }

              return 'You must select at least one user';
            },
          },
        }}
      />
      <Button onClick={onSubmit} isLoading={isSubmitting} flexShrink="0">
        {userIds.length ? `Add ${userIds.length} People` : 'Add'}
      </Button>
    </Stack>
  );
};
