import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  AlertTitle,
} from '@chakra-ui/react';
import * as React from 'react';

export const UnexpectedError: React.FC<AlertProps & { message?: string }> = ({
  message,
  ...props
}) => {
  return (
    <Alert status="error" {...props}>
      <AlertIcon />
      <AlertTitle>Unexpected Error</AlertTitle>
      <AlertDescription>
        {message ?? 'We are working on a fix'}
      </AlertDescription>
    </Alert>
  );
};
