import { Icon, IconProps } from '@chakra-ui/react';
import * as React from 'react';

export const AlertTriangleIcon = React.forwardRef<SVGSVGElement, IconProps>(
  (props, ref) => (
    <Icon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      ref={ref}
      {...props}
    >
      <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
      <line x1="12" y1="9" x2="12" y2="13"></line>
      <line x1="12" y1="17" x2="12.01" y2="17"></line>
    </Icon>
  )
);

AlertTriangleIcon.displayName = 'AlertTriangleIcon';
