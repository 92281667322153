import { Avatar, Box, Circle } from '@chakra-ui/react';
import NextLink from 'next/link';
import * as React from 'react';
import type { Channel, ChannelMemberResponse } from 'stream-chat';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';

import { OrganizationProfileImage } from '@/components/OrganizationProfileImage';
import { UsersIcon } from '@/imports/ui/chakra/feather';

import { useChannelDetail } from '../hooks/useChannelDetail';

export const MessagingAvatarGroup: React.FC<{
  channel: Channel<DefaultStreamChatGenerics>;
  unread: number | undefined;
  isLinkEnabled: boolean;
}> = (props) => {
  const channelDetail = useChannelDetail(props.channel);

  if (channelDetail.isLoading) {
    return null;
  }

  if (channelDetail.isCurrentUserTargetUser && channelDetail.sponsor) {
    if (props.isLinkEnabled) {
      return (
        <NextLink href={`/companies/${channelDetail.sponsor._id}`}>
          <OrganizationProfileImage
            boxSize="8"
            p="1"
            organization={channelDetail.sponsor}
            cursor="pointer"
          />
        </NextLink>
      );
    }

    return (
      <OrganizationProfileImage
        boxSize="8"
        p="1"
        organization={channelDetail.sponsor}
      />
    );
  }

  if (channelDetail.isCurrentUserSponsorAdmin && channelDetail.targetUser) {
    if (props.isLinkEnabled) {
      return (
        <NextLink href={`/members/${channelDetail.targetUser._id}`}>
          <Avatar
            size="sm"
            name={channelDetail.targetUser.displayName}
            src={channelDetail.targetUser.proxyProfilePictureUrl ?? undefined}
            cursor="pointer"
          />
        </NextLink>
      );
    }
    return (
      <Avatar
        size="sm"
        name={channelDetail.targetUser.displayName}
        src={channelDetail.targetUser.proxyProfilePictureUrl ?? undefined}
      />
    );
  }

  return (
    <Box position="relative">
      {channelDetail.otherMembers.length === 0 ? (
        <Avatar size="sm" />
      ) : channelDetail.otherMembers.length === 1 ? (
        <Avatar
          size="sm"
          name={channelDetail.otherMembers[0].user?.name}
          src={channelDetail.otherMembers[0].user?.image}
        />
      ) : (
        <Circle size="8" bg="gray.400">
          <UsersIcon color="white" boxSize="4" />
        </Circle>
      )}
      {!!props.unread && (
        <Circle
          bg="red"
          size="4"
          color="white"
          position="absolute"
          top="-1"
          right="-1"
          textStyle="detail"
        >
          {props.unread > 9 ? '9+' : props.unread}
        </Circle>
      )}
    </Box>
  );
};
