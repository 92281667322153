import { gql } from '@apollo/client';
import LogRocketPackage from 'logrocket';
import * as React from 'react';
import yn from 'yn';

import { MEMBERSHIP_STATUS } from '@/utilities';

import { UseLogRocket_Tenant } from './graphql-types/UseLogRocket_Tenant';
import { UseLogRocket_User } from './graphql-types/UseLogRocket_User';

const NEXT_PUBLIC_LOGROCKET_ENABLED = yn(
  process.env.NEXT_PUBLIC_LOGROCKET_ENABLED
);

export const useLogRocket = (
  logRocketApiKey: string | null | undefined,
  currentUser: UseLogRocket_User | null | undefined,
  tenant: UseLogRocket_Tenant | null | undefined
) => {
  const [initialized, setInitialized] = React.useState(false);

  const shouldRecordSession =
    !!currentUser || tenant?.showNonAuthenticatedLogrocketSessions;
  React.useEffect(() => {
    setInitialized((isInititalized) => {
      if (isInititalized) return isInititalized;
      if (
        logRocketApiKey &&
        shouldRecordSession &&
        NEXT_PUBLIC_LOGROCKET_ENABLED
      ) {
        LogRocketPackage.init(logRocketApiKey);
        return true;
      }
      return isInititalized;
    });
  }, [shouldRecordSession, logRocketApiKey]);

  React.useEffect(() => {
    if (shouldRecordSession && !!tenant && initialized) {
      const tierVal: { tier: string } | {} = !!currentUser?.rosterOrganization
        ?.sponsorship?.currentTier.analyticsTierType
        ? {
            tier: currentUser.rosterOrganization.sponsorship.currentTier
              .analyticsTierType,
          }
        : {};
      const emailVal: { email: string } | {} = !!currentUser?.email
        ? { email: currentUser.email }
        : {};
      const userTraits = {
        ...emailVal,
        ...tierVal,
        ...(currentUser ? { role: currentUser.analyticsRoleType } : {}),
        membershipStatus: !!currentUser?.integrationIsMember
          ? MEMBERSHIP_STATUS.MEMBER
          : MEMBERSHIP_STATUS.NONMEMBER,
        tenant: tenant.associationCode,
      };
      if (currentUser?._id) {
        LogRocketPackage.identify(currentUser._id || 'unknown', userTraits);
      } else {
        LogRocketPackage.identify(userTraits);
      }
    }
  }, [shouldRecordSession, currentUser, initialized, tenant]);
};

useLogRocket.userFragment = gql`
  fragment UseLogRocket_User on User {
    _id
    email
    analyticsRoleType
    integrationIsMember
    rosterOrganization {
      _id
      sponsorship {
        currentTier {
          analyticsTierType
        }
      }
    }
  }
`;

useLogRocket.tenantFragment = gql`
  fragment UseLogRocket_Tenant on Tenant {
    _id
    associationCode
    showNonAuthenticatedLogrocketSessions
  }
`;
