import { DocumentNode, gql, useMutation } from '@apollo/client';
import { Box, Button, Center, ModalHeader, Text } from '@chakra-ui/react';
import * as React from 'react';

import { AddAsBookmarkSteps } from '@/components/AddAsBookmarkSteps';
import { QRCode } from '@/components/QRCode';
import { usePermissions } from '@/hooks/usePermissions';

import { DownloadApp_ConfirmDownloadApp } from './graphql-types/DownloadApp_ConfirmDownloadApp';
import { DownloadApp_TenantFragment } from './graphql-types/DownloadApp_TenantFragment';

import StepProgress from '../../../StepProgress';
import { ActivationModalFooter } from '../ActivationModalFooter';

const DOWNLOAD_APP_COMPLETE = gql`
  mutation DownloadApp_ConfirmDownloadApp {
    confirmAppDownload {
      user {
        _id
        onboardingFlowState {
          isSkippedOrCompleted
          currentFlowState {
            currentStateIndex
            validOnboardingFlowStates
          }
        }
      }
    }
  }
`;

export const DownloadApp: React.FC<{
  isLastStep: boolean;
  tenant: DownloadApp_TenantFragment;
}> & {
  tenantFragment: DocumentNode;
} = ({ isLastStep, tenant }) => {
  const { canAccessCommunityAndGroupFeed } = usePermissions();

  const [confirmAppDownload] = useMutation<DownloadApp_ConfirmDownloadApp>(
    DOWNLOAD_APP_COMPLETE
  );

  const submit = async (): Promise<void> => {
    await confirmAppDownload();
  };

  const absoluteUrl = tenant.absoluteUrl;

  const href = absoluteUrl
    ? canAccessCommunityAndGroupFeed
      ? `${absoluteUrl}/community?isBookmarkOnMobileDeviceModalOpen=true`
      : `${absoluteUrl}?isBookmarkOnMobileDeviceModalOpen=true`
    : undefined;

  return (
    <Box display="flex" flexDirection="column" overflow="auto" flexGrow="1">
      <Box
        color="gray.700"
        px="8"
        pt="10"
        pb="3"
        overflow="auto"
        flexShrink={100}
      >
        <ModalHeader py="0" px="0">
          Add the {tenant?.acronym || 'association'} Community to your Mobile
          Home Screen
        </ModalHeader>
        <Text color="gray.600" display={['none', 'none', 'block']}>
          To get started, scan the QR code below and log in.
        </Text>
      </Box>
      <Box overflow="auto" px="8" py="4" flexGrow="1" flexShrink={1}>
        <Center display={['none', 'none', 'flex']}>
          {href && <QRCode value={href} />}
        </Center>
        <Box display={[null, null, 'none']}>
          <AddAsBookmarkSteps />
        </Box>
      </Box>
      <ActivationModalFooter flexShrink="0">
        <StepProgress />
        <Button onClick={submit}>{isLastStep ? 'Finish' : 'Next'}</Button>
      </ActivationModalFooter>
    </Box>
  );
};
DownloadApp.tenantFragment = gql`
  fragment DownloadApp_TenantFragment on Tenant {
    _id
    acronym
    displayName
    absoluteUrl
  }
`;
