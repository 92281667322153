import { IconButton, Stack, Text, useDisclosure } from '@chakra-ui/react';
import * as React from 'react';

import { usePermissions } from '@/hooks/usePermissions';
import { EditIcon } from '@/imports/ui/chakra/feather';

import { MessagingCreateChannel } from './MessagingCreateChannel';

export const MessagingChannelListHeader: React.FC = () => {
  const createChannel = useDisclosure();
  const { canAccessCommunityAndAuthenticated } = usePermissions();

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      p="4"
    >
      <Text textStyle="xlBodyStrong">Messages</Text>

      {canAccessCommunityAndAuthenticated && (
        <>
          <IconButton
            icon={<EditIcon />}
            aria-label="create new chat"
            variant="outline"
            colorScheme="gray"
            size="sm"
            onClick={(event) => {
              event.stopPropagation();
              createChannel.onOpen();
            }}
          />
          <MessagingCreateChannel {...createChannel} />
        </>
      )}
    </Stack>
  );
};
